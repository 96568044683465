import React from "react";

const AgentIcon = (props: {
  id: string;
  name : string;
  onClick?: () => any;
  selectedAgent?: string;
  task_count? :number;
  avatar_url? : string;
}) => {
  const { id, name,  onClick, selectedAgent, task_count, avatar_url } = props;

  return (
    <div onClick={onClick} className={`icon-selectable ${selectedAgent === id ? 'icon-selectable--selected' : ""}`}>
        <div className={`user-image`}>
            {(task_count !== undefined && task_count !== null) &&  <div className={`task-badge ${task_count < 10 ?  "green" : task_count > 15 ? "red" : "orange"}`}>{task_count}</div>}
            {avatar_url ? <img alt="user symbol" src={avatar_url} /> :   <img alt="user symbol" src="/assets/images/user.svg" /> }
        </div>
      <div className="md-con-t icon-selectable__name">{name}</div>
    </div>
  );
};

export default AgentIcon;
