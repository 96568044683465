import React, { useEffect } from 'react';
import CloseIcon from "@material-ui/icons/Close";
import SelectField from 'components/form-fields/select-field-2';
import Button from "components/buttons/button";
import { Formik, FormikProps } from 'formik';
import { IActionState } from 'types/async-types';
import { fetchCompanies } from 'actions/company-teams';
import { useAsyncAction } from 'utils/async-action';
import { renderOpts } from 'utils/helpers';
export interface ICSVFormValues {
    file?: File, company_id?: string
}

interface IProps {
    onSubmit: (values: any) => any
    setFileSiderVisible: (a: boolean) => any
}
export const CSVForm = (props: IProps) => {
    const { onSubmit, setFileSiderVisible } = props;

    // const [companiesState, execFetchCompanies]: readonly [
    //     IActionState<any>,
    //     typeof fetchCompanies
    // ] = useAsyncAction(fetchCompanies);


    // useEffect(() => {
    //     execFetchCompanies();
    // }, [])
    return (
        <div className="sider fixed">
            
            <CloseIcon
                className="sider-close"
                onClick={() => setFileSiderVisible(false)}
            />
            <div className="sider-body">
                <header className="sider-header">
                    <h2 className="primary mb-15">Wczytaj plik z zadaniami</h2>
                </header>
                <Formik initialValues={{}} onSubmit={onSubmit}>
                    {(formikProps: FormikProps<ICSVFormValues>) => {

                        return <>
                                 {/* <SelectField
                                name="company_id"
                                options={renderOpts(companiesState.data)}
                                label="Firma"
                            /> */}
                            <div className='black-color button mb-3 inline '>
                                <a className='flex-center' href='/dane_wejsciowe.xlsx'>Pobierz wzór</a>
                                <br />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Wybierz plik do zaimportowania (CSV, UTF-8)</label>
                                <input type="file" name="file" accept="text/csv" className="form-control-file" id="exampleFormControlFile1"
                                    onChange={(event: any) => {
                                        formikProps.setFieldValue("file", event.currentTarget?.files[0]);
                                    }}

                                />
                            </div>
                            <Button
                                width={150}
                                inline={true}
                                title="Wyślij"
                                color="blue-color"
                                onClick={formikProps.submitForm}
                            />
                        </>

                    }}

                </Formik>
            </div>
        </div>
    )
}