import React, { useState, useEffect } from "react";
import Button from "components/buttons/button";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Pagination } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import MicIcon from "@material-ui/icons/Mic";
import LinkIcon from "@material-ui/icons/Link";


import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import style from "components/left-panel/left-panel.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchContacts, fetchCustomerIssues } from "actions/customer";
import { IReduxActionState, IActionState } from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { IContact } from "types/customer-types";
import { formatDateTime, getTime } from "utils/date";
import {
    IIssueTemplate,
    IIssueLog,
    IIssue,
    IssueLogType,
} from "types/issue-types";
import {
    fetchIssue,
    addIssueToContact,
    syncAttachedIssues,
} from "actions/issues";
import Spinner from "components/common/spinner";
import { startService } from "actions/services";





const IssueItem = (props: { serviceId: string, issue: IIssue, toggleService: (a: string) => any }) => {
    const { issue, serviceId, toggleService } = props;


    const [startedServiceState, execStartService]: readonly [
        IReduxActionState<any>,
        (serviceId: string, issueId?: string) => any
    ] = useReduxAction(startService, "customer-service");


    return (
        <div
            className={`${style["call-item"]}  flex`}
            onClick={() => { 
                
                toggleService(issue.uuid) 
            
            }}
        >

            {issue.status === "closed" && (
                <CheckCircleOutlineIcon className=" green" />
            )}
            {issue.status === "open" && (
                <img src="/assets/images/progress-check.svg" className="mr-10" />
            )}
            {issue.status === "terminated" && (
                <HighlightOffIcon className="red mr-10" />
            )}

            <div className="grow-1">
                <div

                    className={`flex-center ${style["overview"]}`}
                >
                    <div className="grow-1">
                        <div className="md-con-t">
                            {/* <span className="md-con-t mr-5">
                    {formatDateTime(contact.created_at)}
                  </span> */}
                            {issue.number}

                            {/* <span className="green condensed">(trwająca…)</span> */}
                        </div>
                        <div className="md-t">{issue.title}</div>
                    </div>

                    {/* <div className={`${style["case-count"]} md-con-t`}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div> */}
                </div>

            </div>
        </div>
    );
};


const ClosedIssues = (props: { serviceId: string, issues?: IIssue[], toggleService: (a: string) => any }) => {
    const { issues, serviceId, toggleService } = props;

    if (!issues) {
        return <> </>
    }

    const result = issues.map((item, index) => {
        return <IssueItem toggleService={toggleService} serviceId={serviceId} key={item.uuid} issue={item} />
    })


    return <> {result} </>


}

export default ClosedIssues;