import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { ISessionData, ILoginData } from "types/session-types";
import { login, requestHeaders } from "utils/auth";
import { IFetchSessionAction, FETCH_SESSION, SET_BREAK, ISetBreakAction } from "types/redux-types";

const LOGIN_URL = `${API_BASE_URL}/login`;
const LOGOUT_URL = `${API_BASE_URL}/logout`;
const PASSWORD_RESETS_URL = `${API_BASE_URL}/password_resets`;
const USERS_URL = `${API_BASE_URL}/users`;

export const fetchSessionData = (ctx?: any) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/users/me`,
    method: "GET",
    headers: requestHeaders(ctx).headers,
  };
  let res: ActionResponse<ISessionData> = await axios(options);
  if (res) {
    const action: IFetchSessionAction = {
      type: FETCH_SESSION,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const standardSignIn = (
  data: { login: string; password: string },
  redirectUrl?: string | null
) => async (dispatch: any) => {
  const tokenSource = axios.CancelToken.source();
  const options: AxiosRequestConfig = {
    url: `${LOGIN_URL}`,
    method: "POST",
    data,
    cancelToken: tokenSource.token,
    // headers:  requestHeaders().headers ,
  };

  let res: ActionResponse<ILoginData> = await axios(options);
  if (res) {
    const action = {
      type: FETCH_SESSION,
      payload: res.data,
    };
    dispatch(action);
    
    if(data.login === 'manager'){
      login(res.data.api_token, '/admin/tasks')
    }else{  
      login(res.data.api_token, redirectUrl);
    }



    const result = {
      ...res,
      successMessage: "Zalogowano",
      cancelTokenSource: tokenSource,
    };
    return result;
  }
};

export const setBreakTime = (state: "start" | "end") => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/users/break_time`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      state,
    },
  };
  let res: ActionResponse<any> = await axios(options);
  
    const action: ISetBreakAction = {
      type: SET_BREAK,
      payload: res.data,
    };
    dispatch(action);
    return res;
  
};
