import { ICustomer } from "./customer-types";
import { IList } from "./async-types";
import { IIssue } from "./issue-types";

// export type ServiceStatusType = "pending" | "scheduled" | "incoming" | "ended";
export type ServiceStatusType = "pending" | "open" | "closed";
export type ServiceContactType = "meeting" | "mail" | "phone";
export type AgentStatusType = "Available" | "On Break" | "Logged Out";

export const serviceTypeNames = {
  meeting: "spotkanie",
  phone: "",
  mail: "email",
};

export const agentStatusLabels: { [property in AgentStatusType]: string } = {
  "Available": "Pracuje",
  'On Break': "Na przerwie", 
  'Logged Out': "Wylogowany",
};

export interface IAgent {
  name: string;
  cc_status: AgentStatusType;
  color : string;  
  teams: ITeam[];
  task_count: number;
  avatar_url : string;
}

export interface IAgentItem {
  uuid: string;
  name: string;
  id: string;
  color: string;
  task_count: number;
  cc_status: null | "Available" | "On Break" | "Logged Out";
}

export interface IAgentsList extends IList {
  list: IAgentItem;
}

export interface ITeam {
  name: string;
  color: string;
  id: string;
}

export interface ITeamItem{
  color: string
id: string
name: string
removed: boolean
status: string
uuid: string;
}



export interface IAgentStats {
  available: number;
  on_break: number;
  total: number;
}

export interface IService {
  active?: boolean;

  status: ServiceStatusType;
  id: string;
  color: string;
  contact_date_time: string;
  actual_contact_date_time?: string;
  contact_type: ServiceContactType;
  customer_id: string;
  customer_name: string;
  // issue_id: string;
  issue_ids: string[];
  issue_id : string;
  user_name? :string;
  team_id: string;
  team_name: string; 
  title: string;
  team_color: string;
  contact_id : string;
  company_name: string;
  issue_status: string;
  number : string;
  issue_number : string;
  issues? : IIssue[];

  work_time_in_seconds : number;

  // customer: {
  //   name: string;
  //   surname?: string;
  // };
}

export interface IServices extends IList {
  list: IService[]; 
}

export interface IServiceStats {
  closed: 16
  open: 11
  pending: 53
  total: 81
  undelegated: 1
}