import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "components/buttons/button";
import UnassignedTasks from "components/admin/tasks/unassigned-tasks";
import Table from "components/common/table";
import { Column } from "react-table";
import { IUnassignedTask, IAssignedTask } from "types/task-types";
import { Formik, FormikProps } from "formik";
import style from "../../admin.module.scss";  
import { IActionState } from "types/async-types";
import { IServices, IService } from "types/agent-types";
import { useAsyncAction } from "utils/async-action";
import { fetchClosedServices, resetData } from "actions/services";
import { formatDateTime } from "utils/date";
import ActionsDropdown from "components/common/actions-dropdown";
import Spinner from "components/common/spinner";
import TeamIcon from "components/common/team-icon";
import { FiberManualRecord } from "@material-ui/icons";
import { setTaskStatus, setTaskColor } from "utils/task-helper";
import { logout } from "utils/auth";

const Tasks = (props: any) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [closedServices, execFetchClosedServices]: readonly [
    IActionState<IServices>,
    any
  ] = useAsyncAction(fetchClosedServices);

  const [resetDataState, execResetData]: readonly [
    IActionState<any>,
    any
  ] = useAsyncAction(resetData);


  const terminate = async () => {
    const confirmed = window.confirm('Czy na pewno chcesz wyczyścić wszystkie dane?')
    if(confirmed) {
      execResetData();
      logout();
    }
  }

  const assignedCols: Column<IService>[] = React.useMemo(
    () => [
      {
        Header: "Klient",
        accessor:  "customer_name",
      },
      {
        Header: "Numer sprawy",
        accessor:  "issue_number",
        Cell: (props) => (
          <div className="flex-left">
            {props.value}
          </div>
        ),
      },
      // {
      //   Header: "Zadłużenie",
      //   accessor: "negative_ballance",
      //   Cell: (props) => <>{props.value.toFixed(2)} zł</>,
      // },
      {
        Header: "Kategoria",
        accessor: "title",
        Cell: (props) => <div className="md-con-t dark1">{props.value}</div>,
      },
      {
        Header: "Zleceniodawca",
        accessor: "company_name",
      },
      {
        Header: "Zaplanowany kontakt",
        accessor: "contact_date_time",
        Cell: (props) => 
        {
        return(<div className="md-con-t dark1">
          {formatDateTime(props.value)}

          <FiberManualRecord htmlColor= { setTaskColor(props.value, props.row.original.actual_contact_date_time) }/>
          </div>);
        }
      },
      {
        Header: "Status",
        accessor: "issue_status",
        Cell: (props) => 
          <div className="md-con-t dark1">
            { setTaskStatus(props.value) }
          </div>,
      },
      {
        Header: "Agent",
        accessor: "user_name",
        className: "b-left",
        Cell: (props) => {
          return (
            <>
              {" "}
              {props.value || (
                <TeamIcon 
                  name={props.row.original.team_name}
                  hex={props.row.original.team_color}
                  id={props.row.original.team_id}
                />
              )}
            </>
          );
        },
      },
    ],
    []
  );


  return (
    <>
        {(closedServices.loading || resetDataState.loading) && <Spinner />}
            <div className="panel no-padding mb-20">
              <div className="border-b">
                
                
              </div>
              <Table fetchData={execFetchClosedServices} data={closedServices.data} columns={assignedCols} /> 
            </div>
            <div style={{ fontSize: "10px" }} className="pull-right" onClick={ () => {terminate()} }>.</div>

    </>
  );
};

export default Tasks;
