import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CaseButton from "components/buttons/case-button";
import { IInvoiceSearchFormComponent, IIssueStep } from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { IReduxActionState, IActionState } from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { fetchInvoices } from "actions/customer";
import { formatDateTime, formatSubmitDate } from "utils/date";
import EmailIcon from "@material-ui/icons/Email";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckFormField from "components/form-fields/check-form-field";
import { Formik, FormikProps } from "formik";
import { Invoice } from "types/customer-types";
import { useFormikContext } from "formik";
import TextField from "components/form-fields/text-field";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import _ from "lodash";
import pl from "date-fns/locale/pl";
import RadioButton from "components/form-fields/radio-button";

interface IExtendedInvoice extends Invoice {
  test_id: string;
}

const VerificationForm = (
  {component, step} : {
  component: IInvoiceSearchFormComponent,
  step: IIssueStep}
) => {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [dateOrder, setDateOrder] = useState<"asc" | "desc">("desc");
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [invoicesList, setInvoicesList] = useState<IExtendedInvoice[]>([]);

  const [listVisible, setListVisible] = useState(false);
  const customer = useSelector((state: RootState) => state.customer);
  const async = useSelector((state: RootState) => state.async);

  const [invoicesState, execFetchInvoices]: readonly [
    IActionState<{list : Invoice[], total : number}>,
   typeof fetchInvoices
  ] = useAsyncAction(fetchInvoices);

  const formikProps = useFormikContext<any>();
    console.log('formik values: ',formikProps.values);
    

  const compareByNumber = (a: Invoice, b: Invoice) => {
    if (a.number < b.number) {
      return -1;
    }
    if (a.number > b.number) {
      return 1;
    }
    return 0;
  };

  const compareByType = (a: Invoice, b: Invoice) => {
    if (a.paymentType < b.paymentType) {
      return -1;
    }
    if (a.paymentType > b.paymentType) {
      return 1;
    }
    return 0;
  };

  const compareByPaymentDue = (a: Invoice, b: Invoice) => {
    const dateA = new Date(a.drawDate);
    const dateB = new Date(b.drawDate);
    
    return dateA.getTime() - dateB.getTime();
  };

  const orderBy = (
    prop: "number" | "paymentType" | "paymentDate" | "drawDate"
  ) => {
    let sortedList;
    switch (prop) {
      case "number":
        sortedList = invoicesList.sort(compareByNumber);
        break;
      case "paymentType":
        sortedList = invoicesList.sort(compareByType);
        break;
      case "drawDate":
        setDateOrder(dateOrder === "asc" ? "desc" : "asc");
        sortedList = _.orderBy(invoicesList, ["drawDate"], [dateOrder]);

        break;
      default:
        sortedList = invoicesList;
    }

    setInvoicesList(sortedList);
  };

  useEffect(() => {
    if (startDate && endDate) {
      formikProps.setFieldValue(`${component.name}.start_date`, startDate);
      formikProps.setFieldValue(`${component.name}.end_date`, endDate);

      setListVisible(true);
      if (customer?.customer_uuid) {
        execFetchInvoices(
          customer?.customer_uuid,
          formatSubmitDate(startDate.toDateString()),
          formatSubmitDate(endDate.toDateString())
        );
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    console.log('invoicesState', invoicesState)
    if (invoicesState.data) {
      const testInvoices: IExtendedInvoice[] = invoicesState.data.list.map( 
        (item, index) => {
          return { ...item, test_id: index + "_inv" };
        }
      );
      setInvoicesList(testInvoices);
    }
  }, [invoicesState.data]);

  useEffect(() => {
    if (component.value) {
      if (component.value["start_date"]) {
        setStartDate(new Date(component.value["start_date"]));
      }
      if (component.value["end_date"]) {
        setEndDate(new Date(component.value["end_date"]));
      }

      formikProps.setFieldValue(`${component.name}.issue_state`, component.value["issue_state"]);
      // formikProps.setFieldValue(`${component.name}.issued`, component.value["issued"]);
      // formikProps.setFieldValue(
      //   `${component.name}.not_issued`,
      //   component.value["not_issued"]
      // );
      // formikProps.setFieldValue(
      //   `${component.name}.duplicate`,
      //   component.value["duplicate"]
      // );
      // formikProps.setFieldValue(
      //   `${component.name}.original`,
      //   component.value["original"]
      // );
      formikProps.setFieldValue(`${component.name}.note`, component.value["note"]);
      formikProps.setFieldValue(
        `${component.name}.selected_invoice`,
        component.value["selected_invoice"]
      );
      setSelectedInvoice(component.value["selected_invoice"]);
    }
  }, []);

  const selectInvoice = (item: Invoice) => {
    setSelectedInvoice(item);
    formikProps.setFieldValue(`${component.name}.selected_invoice`, item);
  };
  
  const renderInvoices = () => {
    if (selectedInvoice && component.value && component.value["selected_invoice"]) {
      return (
        <tr className={"selected"} key={selectedInvoice.id + "_inv"}>
          <td className="md-l-t">{selectedInvoice.number}</td>
          <td className="md-t black">{selectedInvoice.settlement}</td>
          <td className="md-t black"> {selectedInvoice.drawDate ? (formatDateTime(selectedInvoice.drawDate)  ) : ( <div className="t-center">-</div> )} </td>
          <td className="md-t black"> {selectedInvoice.paymentDate ? (formatDateTime(selectedInvoice.paymentDate)  ) : ( <div className="t-center">-</div> )} </td>
          <td className="md-t black">{selectedInvoice.nettoValue}</td>
          <td>{<MailOutlineIcon />}</td>
          <td><MailOutlineIcon htmlColor="orange"/></td>
        </tr>
      );
    }
    
    if (invoicesList) {
      return invoicesList.map((item, index) => {
        const selected = selectedInvoice === item;
        return (
          <tr
            onClick={() => selectInvoice(item)}
            className={`${selected ? "selected" : ""}`}
            key={item.id + "_inv"}
          >
          <td className="md-l-t">{item.number}</td>
          <td className="md-t black">{item.settlement}</td>
          <td className="md-t black"> {item.drawDate ? (formatDateTime(item.drawDate)  ) : ( <div className="t-center">-</div> )} </td>
          <td className="md-t black"> {item.paymentDate ? (formatDateTime(item.paymentDate)  ) : ( <div className="t-center">-</div> )} </td>
          <td className="md-t black t-right">{parseFloat(item.nettoValue || "0").toFixed(2)} zł</td>
          <td className="t-center">{<MailOutlineIcon htmlColor="green" />}</td>
          <td className="t-center"><MailOutlineIcon htmlColor="orange"/></td>
          </tr>
        );
      });
    }
  };

  return (
    <div>
      <div className="lg-con-t italic mb-20">
        Za jaki okres nie otrzymał Pan/Pani faktury?
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label">Od</label>
            <DatePicker
              placeholderText="Data od"
              className="form-control"
              selected={startDate}
              dateFormat="dd.MM.yyyy"
              locale={pl}
              onChange={(date: any) => setStartDate(date)}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label">Do</label>
            <DatePicker
              placeholderText="Data do"
              className="form-control"
              selected={endDate}
              dateFormat="dd.MM.yyyy"
              locale={pl}
              onChange={(date: any) => setEndDate(date)}
            />
          </div>
        </div>
      </div>
      <div>
        {invoicesList && (
          <div className="invoice-table mb-20">
            {invoicesState.loading ? (
              <div className="spinner-item t-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <table className="default-table">
                <thead>
                  <tr className="md-t t-700 t-left">
                    {/* Data wystawienia {dateOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />} */}
                    <th>Numer dokumentu</th>
                    <th>Podstawa roszczenia</th>
                    <th>Data wystawienia</th>
                    <th>Data płatności</th>
                    <th>Kwota do zapłaty</th>
                    <th>Oryginał</th>
                    <th>Duplikat</th>
                  </tr>
                </thead>
                <tbody>{renderInvoices()}</tbody>
              </table>
            )}
          </div>
        )}
        {invoicesList?.length === 0 && (
          <div className="invoice-table mb-20"> Brak danych dla podanego zakresu </div>
        )}
      </div>
      <div className="mb-20">
        <RadioButton
          name={`${component.name}.issue_state`}
          value="not_issued"
          label="Faktura niewystawiona"
          id={`${component.name}.not_issued`}
        />
        <RadioButton
          name={`${component.name}.issue_state`}
          value="issued"
          label="Faktura wystawiona"
          id={`${component.name}.issued`}
        />
      </div>
      <div className="mb-20 row">
        <div className="form-group">
          <TextField
            name={`${component.name}.note`}
            label="Dodatkowe informacje"
            type="textarea"
          />
        </div>
      </div>
    </div>
  );
};

export default VerificationForm;
