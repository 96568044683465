import React from 'react';

interface IProps {
    message?: string;
}
const ErrorMsg = (props: IProps) => {

    return <div className="error-wrapper">
        <div className="input-error">
            {props.message}
        </div>
    </div>
}

export default ErrorMsg;