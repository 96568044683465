export const formatDateTime = (fullDate?: string | null, separator?: "dot") => {
  if (fullDate === " - ") {
    return fullDate;
  }
  if (!fullDate) {
    return "";
  }
  const date = new Date(fullDate);
  
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let dayStr = "";
  let monthStr = "";
  if (month < 10) {
    monthStr = "0" + month;
  } else {
    monthStr = month.toString();
  }
  if (day < 10) {
    dayStr = "0" + day;
  } else {
    dayStr = day.toString();
  }
  let dateStr = "";
  
    dateStr = dayStr + "." + monthStr + "." + date.getFullYear();
  
    // dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr;
  

  return dateStr;
};

export const formatSubmitDate = (fullDate?: string | null) => {
    if (fullDate === " - ") {
        return fullDate;
      }
      if (!fullDate) {
        return "";
      }
      const date = new Date(fullDate);
      
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let dayStr = "";
      let monthStr = "";
      if (month < 10) {
        monthStr = "0" + month;
      } else {
        monthStr = month.toString();
      }
      if (day < 10) {
        dayStr = "0" + day;
      } else {
        dayStr = day.toString();
      }
      let dateStr = "";
      
        // dateStr = dayStr + "." + monthStr + "." + date.getFullYear();
      
        dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr;
      
    
      return dateStr;
}


export const getTime = (fullDate?: string | null) => {
    if (fullDate === " - ") {
        return fullDate;
      }
      if (!fullDate) {
        return "";
      }
      const date = new Date(fullDate);
      const hours = date.getHours() === 0 ? "00" : date.getHours()
      const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes()  : date.getMinutes()
      return hours + ":" + minutes;

}


