import { useState, useEffect } from "react";
import { isLoading } from "redux/actions/async";
import { useDispatch } from "react-redux";
import { successMessage, IMessageProps, message } from "utils/toaster";
import { handleError } from "utils/http";
import {
  ActionResponse,
  IActionState,
  IReduxActionState,
} from "types/async-types";
import { LoadedPropertyType } from "redux/reducers/async-reducer";
import axios, { CancelTokenSource } from "axios";
import { useHistory } from "react-router-dom";

interface IResponse {
  successMessage?: string;
  messageConfig?: IMessageProps;
  data: any | null;
  error: any;
}

interface IError {
  response: any;
  customMessage?: boolean;
  messageConfig: IMessageProps;
  error: any;
}

//hook for redux action creators (with API calls) - returns asyncAction, loading & error, also sets store-level loaders & error
export const useReduxAction = (
  action: any,
  stateProperty: LoadedPropertyType
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);
  const [response, setResponse] = useState<ActionResponse<any> | null>(null);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource
  >();
  const [data, setData] = useState<any | null>(null);

  const asyncAction = async (...body: any[]) => {
    // async function callback() {
    const loadingData = {
      isLoading: true,
      stateProperty,
      errorCode: 0,
    };
    setLoading(true);
    dispatch(isLoading({ ...loadingData }));

    try {
      const res: ActionResponse<any> = await dispatch(action(...body));

      if (res) {
        dispatch(isLoading({ ...loadingData, isLoading: false }));
        if (res.shouldCancelSetState) {
          return;
        }
        if (res.successMessage) {
          successMessage(res.successMessage);
        }

        setData(res.data);
        setError(0);
        setResponse(res);
        setLoading(false);

        return res;
      }
    } catch (e ) {
      if (!e.response) {
        throw e;
      }

      dispatch(
        isLoading({
          ...loadingData,
          isLoading: false,
          errorCode: e.response.status, 
        })
      );

      setLoading(false);
      setError(e.response.status);
      handleError(e);

      return e;
    }
  };

  return [
    { data, loading, error, cancelTokenSource } as IReduxActionState<any>,
    asyncAction,
  ] as const;
};

// hook for regular (non redux) API calls
export const useAsyncAction = (action: any, customError = null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any | null>(null);
  const [response, setResponse] = useState<ActionResponse<any> | null>(null);
  const [error, setError] = useState<number | null>(null);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource
  >();
  const performAction: () => Promise<ActionResponse<any>> = async (
    ...body: any[]
  ) => {
    try {
      setLoading(true);
      setData(null);
      setError(null);

      const res: ActionResponse<any> = await action(...body);
      if (res.shouldCancelSetState) {
        return;
      }

      if (res) {
        setData(res.data);
        setResponse(res);
        setLoading(false);
        if (res.successMessage) {
          message({ title: res.successMessage, type: "positive" });
        }
        if (res.messageConfig) {
          message({ ...res.messageConfig });
        }
      }

      return res;
    } catch (e) {
      //if error is not https-related
      if (!e.response) {
        throw e;
      }

      const errorRes: IError = e;
      setError(errorRes.response.status);
      setLoading(false);
      setResponse(errorRes.response);
      if (!customError) {
        handleError(errorRes, errorRes.messageConfig);
      }
      return e;
    } finally {
      // setLoading(false);
    }
  };

  return [
    { data, response, loading, error, cancelTokenSource },
    performAction,
  ] as const;
};
//regular wrapper function for redux action creators (API) (sets only store-level loaders, errors etc)
export const reduxAction = (action: any, stateProperty = null) => async (
  dispatch: any
) => {
  const loadingData = {
    isLoading: true,
    stateProperty,
    errorCode: null,
  };

  dispatch(isLoading({ ...loadingData }));

  try {
    const res = await dispatch(action());
    dispatch(isLoading({ ...loadingData, isLoading: false }));
    if (res.successMessage) {
      successMessage(res.successMessage);
    }

    return res;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    dispatch(
      isLoading({
        ...loadingData,
        isLoading: false,
        errorCode: e.response.status,
      })
    );
    handleError(e);
    return e;
  }
};
