import React, { useEffect, useState } from "react";
import Menu from "components/admin/menu";

import style from "./admin.module.scss";
import { Router, Switch, Route } from "react-router-dom";
import Tasks from 'components/admin/tasks/tasks';
import Agents from 'components/admin/agents/agents';
import History from "my-history";
import { PAGE } from "paths";
import { withAuthSync } from "utils/auth";

const Admin = (props: any) => {
  return (
    // <Router history={History}>
      <div className="layout container">
        <div className="row gx-0">
          <div className={`col-2 px-0 ${style["menu-col"]}`}>
            <Menu />
          </div>
          <div className={`col-10 p-4 ${style["main-col"]}`}>
            <Switch>
              <Route path="/admin/tasks" component={Tasks} />
              <Route path={PAGE.ADMIN_AGENTS} component={Agents} />
            </Switch>
          </div>
        </div>
      </div>
    // </Router>
  );
};

export default withAuthSync(Admin);
