export type LoadedPropertyType =
  | null
  | "invoice"
  | "customer"
  | "user"
  | "issues"
  | "contacts"
  | "attached-issues"
  | "customer-service"
  | "issue-action"
  | "create-monit";

export interface IAsyncReduxState {
  isLoading: boolean;
  property: LoadedPropertyType;
  errorCode: number;
  succeeded: "create-monit" | null;
}

const initialState: IAsyncReduxState = {
  isLoading: false,
  property: null,
  errorCode: 0,
  succeeded: null, 
};

export default function Reducer(
  state = initialState,
  action: any
): IAsyncReduxState {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, ...action.payload };
    case "SET_SUCCESS":
      
      return { ...state, succeeded: action.payload.data };
    default:
      return state;
  }
}
