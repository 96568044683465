import React from 'react';
export type BtnColor = "black-color" | "grey-color" | 'transparent' | 'blue-color';
interface IProps {
  color? : BtnColor
  title : string
  className? : string
  half? : string
  disabled? : boolean
  onClick : (e: any) => void
  dataTest?: string,
  width? : number
}


const FormButton = (props: IProps) => {
  const {color, title, half, onClick, className, disabled, dataTest, width} = props;
  return <div>
          <button type="button" data-testid={dataTest} onClick={onClick} disabled={disabled} style={width ? {width: width + "px" } : {} } className={`${color} button ${half ? half : ''} ${className ? className : ''}`}>
          { title }
          </button>
        </div>

}

FormButton.propTypes = {
 
}

export default FormButton;
