import {
  ServiceActionTypes,
  FETCH_SERVICE,
  END_SERVICE,
  VERIFY,
  UPDATE_ISSUE,
} from "types/redux-types";
import { IService } from "types/agent-types";

export interface IPasswordInfoPayload {}

export interface IServiceState {
  currentService?: IService;
  services: IService[];
  customer_verified?: boolean;
  currentIssue?: { issue_id?: string; issue_status: string };
}

const initialState: IServiceState | null = {
  currentService: undefined,
  services: [],
};

export default function Reducer(
  state = initialState,
  action: ServiceActionTypes
): IServiceState | null {
  switch (action.type) {
    case FETCH_SERVICE:
      return { ...state, currentService: action.payload,  services: [] };
    case END_SERVICE:
      return { ...state, currentService: undefined, services: [] };
    case VERIFY:
      return { ...state, services: [], customer_verified: true };
    case UPDATE_ISSUE:
      return {
        ...state,
        currentIssue : action.payload,
        services: [],
      };
    default:
      return state;
  }
}
