import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import style from "./menu.module.scss";
import { Link, useHistory } from "react-router-dom";
import { PAGE } from "paths";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { IActionState } from "types/async-types";
import { logout } from "utils/auth";
import WorkPanel from 'components/common/work-panel';
import { useAsyncAction } from "utils/async-action";
interface IIMenuItemProps {
  icon: string;
  link: string;
  text: string;
  onClick?: (a: any) => any;
}

const AdminMenu = (props: any) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState();
  const user = useSelector((state: RootState) => state.user);
  
  const [logoutState, execLogout]: readonly [
    IActionState<any>,
    typeof logout
  ] = useAsyncAction(logout);

  const logOut = () => {
    if (user.fetch_agents_timer) {
      clearTimeout(user.fetch_agents_timer);
    }
    execLogout();
  };

  const MenuItems: IIMenuItemProps[] = [
    {
      icon: "dashboard.svg",
      link: "/dashboard",
      text: "Widok agenta 360",
    },
    {
      icon: "calendar-check.svg",
      link: PAGE.TASKS,
      text: "Zadania",
    },
    {
      icon: "account-multiple.svg",
      link: PAGE.ADMIN_AGENTS,
      text: "Agenci i Zespoły",
    },
    {
      icon: "logout.svg",
      link: "",
      text: "Wyloguj",
      onClick: logOut,
    },
  ];

  const MenuItem = (props: IIMenuItemProps) => {
    if (props.onClick) {
      return (
        <div
          onClick={props.onClick}
          className={`${style["menu__item"]} flex-left ${
            history.location.pathname === props.link ? style.active : ""
          }`}
        >
          <img
            alt="tiles"
            className={`${style["menu__icon"]}`}
            src={`/assets/images/${props.icon}`}
          />
          <span className="md-t grow-1">{props.text}</span>
          <ChevronRightIcon className="light2" />
        </div>
      );
    }
    return (
      <Link
        to={props.link}
        className={`${style["menu__item"]} flex-left ${
          history.location.pathname === props.link ? style.active : ""
        }`}
      >
        <img
          alt="tiles"
          className={`${style["menu__icon"]}`}
          src={`/assets/images/${props.icon}`}
        />
        <span className="md-t grow-1">{props.text}</span>
        <ChevronRightIcon className="light2" />
      </Link>
    );
  };

  const renderMenuItems = () => {
    return MenuItems.map((item, index) => {
      return <MenuItem {...item} key={item.link + index} />;
    });
  };

  return (
    <div className={`${style["menu"]} panel no-padding flex-1 flex-column`}>
      <div className={`${style["menu__user-box"]} flex-left`}>
        <div className="agent-info flex-left grow-1">
          <div className={`user-image  mr-5`}>
            {user.session?.avatar_url ? <img src={user.session?.avatar_url} /> :  <img src="/assets/images/user.svg" /> }
          </div>
          <div>
            <div className="md-con-t">Zalogowany jako:</div>
            <div className="lg-t">{user.session?.name || "Manager"}</div>
          </div>
          <div className="work-time grow-1 t-right"></div>
        </div>
        <ArrowDropDownIcon />
      </div>
     <WorkPanel />
      {renderMenuItems()}
    </div>
  );
};

export default AdminMenu;
