import React, { useEffect, useState } from "react";
import { Cancel, CheckCircle, RadioButtonUncheckedOutlined } from "@material-ui/icons";

export function setTaskStatus(value: string) {
    switch (value) {
      case "terminated": return <Cancel color="error"/>;
      case "closed": return <CheckCircle htmlColor="green" />;
      case "open": return <img src="/assets/images/progress-check.svg" className="mr-10" />;
      case "draft": return <RadioButtonUncheckedOutlined />;
      default: return value;
    }
  }

export function setTaskColor(contact_date?: string, actial_contact_date?: string){
    const date_now = Date.now();
    const date_today_end_of_day = new Date();
    date_today_end_of_day.setUTCHours(23,59,59,9999);
    
    const contact_date_time = (contact_date ? Date.parse(contact_date || '') : date_now);
    const actual_contact_date_time = (actial_contact_date ? Date.parse(actial_contact_date || '') : date_today_end_of_day);
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate()-1);
    yesterdayDate.setUTCHours(0,0,0,0);

    /* 
      actual_contact_date_time > contact_date -- red
      (actual_contact_date_time || now) && yesterday <= contact_date <= begginning_of_today -- orange
      actial_contact_date < contact_date -- green
    */

    if (actual_contact_date_time <= contact_date_time){
     return 'green';
    } else {
      if (yesterdayDate.getTime() <= actual_contact_date_time && actual_contact_date_time <= date_today_end_of_day.getTime() ){
        return 'orange';
      }
      else {
        return 'red';
      }
    }
  }
