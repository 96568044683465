import React, { useState, useEffect } from "react";
import Header from "components/header";
import LeftPanel from "components/left-panel/left-panel";
import ServicePanel from "components/service-panel/service-panel";
import Button from "components/buttons/button";
import CallModal from "components/modal/incoming-call-modal";
import { withAuthSync } from "utils/auth";
import ServiceDetails from "components/service/service-details";
import ServiceMessage from "components/header/Service-message";
import Spinner from "./common/spinner";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import Settlements from "components/customer-data/settlements";
import { IReduxActionState } from "types/async-types";
import { useReduxAction } from "utils/async-action";
import { fetchCustomer } from "actions/customer";
import { init, IInitConfig } from "utils/webphone-service";
import { IWebphone, WebphoneStatus } from "types/webphone-types";

export const WebphoneContext = React.createContext<{webphone : IWebphone | undefined; state : WebphoneStatus | undefined} | null>(null);

// type PreviewSectionType = "invoices" | "history" | undefined;

const Dashboard = () => {
  const service = useSelector((state: RootState) => state.service);
  const async = useSelector((state: RootState) => state.async);
  const [incomingCallModalVisible, setIncomingCallModalVisible] = useState(
    false
  );
  const [webphoneState, setWebphoneState ] = useState<WebphoneStatus>();

  const [webphone, setWebPhone] = useState<IWebphone>();
  const customer = useSelector((state: RootState) => state.customer);
  const config = useSelector((state: RootState) => state.user.session?.config);
  const credentials = useSelector(
    (state: RootState) => state.user.session?.webphone
  );


  useEffect(() => {
    if (config && credentials && !webphone) {
      const initConfig: IInitConfig = {
        config,
        credentials,
        setWebphoneState
      };

      const webphone = init(initConfig);
      webphone.setAutoAnswer(false);
      setWebPhone(webphone);
    }
  }, [config]);

  return (
    <WebphoneContext.Provider value={{webphone, state : webphoneState}}>
       
      <div className="layout container">
        <div className="row gx-0">
          <div className="col-2 service-panel-col">
            <ServicePanel />
          </div>
          <div className="col-10 service-details-col">
            {/* {(async.isLoading && async.property === 'customer-service') &&
          <Spinner />
        } */}
            {/* {(async.isLoading && (async.property === 'customer-service' || async.property === 'customer' || async.property == 'issues')) &&
                    <Spinner />
        }  */}
            {service?.currentService && <ServiceDetails />}
          </div>
        </div>
      </div>
      </WebphoneContext.Provider>
  );
};
// export default Dashboard;
export default withAuthSync(Dashboard);
