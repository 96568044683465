import React from "react";
import {Link} from 'react-router-dom';



export type BtnColor = "black-color" | "grey-color" | 'transparent' | 'blue-color' | 'green-button';
interface IProps {
  testId?: string;
  link?: string;
  color: BtnColor;
  title: string;
  half?: string;
  className?: string;
  onClick?: () => any;
  inline?: boolean;
  Icon?: any;
  id?: string;
  width?: number;
}
const Button = (props: IProps) => {
  const {
    link,
    color,
    title,
    half,
    onClick,
    inline,
    Icon,
    className,
    testId,
    width,
  } = props;

  if (onClick) {
    return (
      <div
        className={`${color} button ${half} ${inline ? "inline" : ""} ${
          className || ""
        }`}
        style={width ? { width: width + "px" } : {}}
      >
        <a key={1} data-testid={testId} onClick={onClick} className="flex-center">
          {Icon && <Icon />}
          <span>{title} </span>
        </a>
        {/* {process.env.NODE_ENV !== "test" && (
          <style jsx global>
            {s1}
          </style>
        )} */}
      </div>
    );
  } else {
    return (
      <div
        className={`${color} button ${half} ${inline && "inline"} ${
          className || ""
        }`}
        style={width ? { width: width + "px" } : {}}
      >
        <Link data-testid={testId} key={1} to={link ? link : "#"}>
          {Icon && <Icon />}
          {title}
        </Link>

        {/* {process.env.NODE_ENV !== "test" && (
          <style jsx global>
            {s1}
          </style>
        )} */}
      </div>
    );
  }
};
export default Button;
