import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "styles/main.scss";
import Login from "components/login";
// import "react-confirm-alert/src/react-confirm-alert.css";
import Dashboard from "components/dashboard";
import Admin from "components/admin";
import { Switch, Route, Router } from "react-router-dom";
import History from "my-history";
import { ToastContainer } from "react-toastify";


function App() {



  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        
        // pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Router history={History}>
        <Switch>
          <Route path="/user/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/admin" component={Admin} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
