import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { ActionResponse } from "types/async-types";
import {
  IFetchCustomerIssue,
  FETCH_ISSUE,
  IFetchService,
  FETCH_SERVICE,
  IEndService,
  END_SERVICE,
  VERIFY,
  IVerify,
  IUpdateIssueId,
  UPDATE_ISSUE,
} from "types/redux-types";
import { IService } from "types/agent-types";

const SERVICES_URL = `${API_BASE_URL}/customer_services`;

// export const fetchService = (serviceId: string) => async (dispatch: any) => {
//   const options: AxiosRequestConfig = {
//     url: `${API_BASE_URL}/${serviceId}`,
//     method: "GET",
//     headers: requestHeaders().headers,
//   };
//   let res: ActionResponse<any> = await axios(options);
//   if (res) {
//     const action: IFetchService = {
//       type: FETCH_SERVICE,
//       payload: res.data,
//     };
//     dispatch(action);
//     return res;
//   }
// };

export const createService = async (values: any) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}`,
    method: "POST",
    headers: requestHeaders().headers,
    data: values,
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const result = {
      ...res,
      successMessage: "Dodano zadanie",
    };

    return result;
  }
};

export const updateService = async (values: any, serviceId: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${serviceId}`,
    method: "PATCH",
    headers: requestHeaders().headers,
    data: values,
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const result = {
      ...res,
      successMessage: "Zapisano zadanie",
    };

    return result;
  }
};

export const bulkAssignService = async (values: any) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/bulk_assign`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      ...values,
    },
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const result = {
      ...res,
      successMessage: "Dodano zadanie",
    };

    return result;
  }
};

export const fetchService = (serviceId: string) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${serviceId}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  // return res;
  if (res) {
    const action: IFetchService = {
      type: FETCH_SERVICE,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const fetchUndelegatedServices = async (page?: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/undelegated_services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const fetchTodayServices = async (page?: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/today_services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const fetchServices = async (page?: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const fetchClosedServices = async (page?: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/closed_services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const fetchMyClosedServices = async (page?: number) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/my_closed_services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const fetchOpenServices = async (
  tokenSource?: CancelTokenSource,
  page?: string
) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/open_services${page ? `?page=${page}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
    cancelToken: tokenSource ? tokenSource.token : undefined,
  };
  let res: ActionResponse<any> = await axios(options);

  return {
    ...res,
    cancelTokenSource: tokenSource || undefined,
  };
};

export const fetchIncomingService = async (call_uuid: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/by_contact`,
    method: "GET",
    params: {
      call_uuid,
    },
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return {
    ...res,
  };
};

export const assignClient = async (id: string, ext_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${id}/assign_client`,
    method: "POST",
    data: {
      ext_id,
    },
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return {
    ...res,
  };
};

export const startService = (serviceId: string, issueId?: string) => async (
  dispatch: any
) => {
  let url = `${SERVICES_URL}/${serviceId}/start_service`;
  if (issueId) {
    url = `${SERVICES_URL}/${serviceId}/start_issue_service`;
  }

  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    headers: requestHeaders().headers,
    data: { issue_id: issueId },
  };
  let res: ActionResponse<any> = await axios(options);

  // return res;
  if (res) {
    const action: IFetchService = {
      type: FETCH_SERVICE,
      payload: res.data,
    };
    dispatch(action);
    const result = {
      ...res,
      successMessage: "Otwarto nowy kanał (sprawę)",
    };
    return result;
  }
};

export const endService = (
  serviceId: string,
  shouldCancelSetState?: boolean
) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${serviceId}/end_service`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      message: "Zamknięcie sprawy",
    },
  };
  let res: ActionResponse<any> = await axios(options);

  // return res;
  if (res) {
    const action: IEndService = {
      type: END_SERVICE,
      payload: res.data,
    };
    dispatch(action);
    const result = {
      ...res,
      successMessage: "Zamknięto sprawę",
      shouldCancelSetState: shouldCancelSetState,
    };
    return result;
  }
};

export const verify = () => async (dispatch: any) => {
  const action: IVerify = {
    type: VERIFY,
    payload: "",
  };
  dispatch(action);
};

export const fetchServiceStats = async () => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/services_stats`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return {
    ...res,
  };
};

export const resumeServiceTimer = (serviceId: string) => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${serviceId}/resume_service`,
    method: "POST",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<IService> = await axios(options);

  // return res;
  if (res) {
    const action: IFetchService = {
      type: FETCH_SERVICE,
      payload: res.data,
    };

    const updateIssueAction: IUpdateIssueId = {
      type: UPDATE_ISSUE,
      payload: {
        issue_id: res.data.issue_id,
        issue_status: res.data.issue_status,
      },
    };

    dispatch(updateIssueAction);

    dispatch(action);
    const result = {
      ...res,
      successMessage: "Wznowiono sprawę",
    };
    return result;
  }
};

export const pauseServiceTimer = async (uuid: string) => {
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/${uuid}/pause_service`,
    method: "POST",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return {
    ...res,
  };
};

export const uploadCSV = async (values: { file: File; company_id: string }) => {
  const data = new FormData();
  data.append("file", values.file);
  data.append("company_id", values.company_id);
  const options: AxiosRequestConfig = {
    url: `${SERVICES_URL}/upload_csv`,
    method: "POST",
    data,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      "content-type": "multipart/form-data",
    },
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};


export const resetData = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/reset_data`,
    method: "DELETE",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};