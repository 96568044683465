import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { IReduxActionState, IActionState } from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { fetchInvoices } from "actions/customer";
import Spinner from "components/common/spinner";
import { Invoice } from "types/customer-types";

const Settlements = (props: any) => {
  const customer = useSelector((state: RootState) => state.customer);
  const async = useSelector((state: RootState) => state.async);
  const [invoicesState, execFetchInvoices]: readonly [
    IActionState<Invoice[]>,
    typeof fetchInvoices
  ] = useAsyncAction(fetchInvoices);

  useEffect(() => {
    if (customer?.customer_uuid) {
      execFetchInvoices(customer.customer_uuid, '2012-01-01', '2021-12-30');
    }
  }, [customer?.customer_uuid]);
  const renderInvoices = () => {
    const invoices = invoicesState.data;
    if (invoices) {
      return invoices.map((item, index) => {
        return (
          <tr>
            <td className="md-l-t">{item.number}</td>
            <td className="md-t black">{item.nettoValue}</td>
            <td className="md-t black">{item.drawDate}</td>
            <td className="md-t black">{item.paymentDate || <div className="t-center">-</div>}</td>
            <td className="md-t black">{item.settlement}</td>
          </tr>
        );
      });
    }
  };

  if (invoicesState.loading) {
    return (
      <div className="panel relative flex-1">
        <Spinner size="default" />
      </div>
    );
  }
  return (
    <div className="panel flex-1">
      <h3 className="mb-20">Bieżące rozliczenie</h3>
      <div className="table-wrapper">
      <table className="default-table">
        <thead>
          <tr className="md-t t-700 t-left">
            <th>Numer</th>
            <th>Kwota</th>
            <th>Data dokumentu</th>
            <th>Termin płatności</th>
            <th>Podstawa roszczenia</th>
          </tr>
        </thead>
        <tbody>{renderInvoices()}</tbody>
      </table>
      </div>
    </div>
  );
};

export default Settlements;
