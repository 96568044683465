import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IStepComponent,
  IVerificationFormComponent,
  StepActionType,
  IIssueStep,
  IIssue,
} from "types/issue-types";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SelectField from "components/form-fields/select-field";
import TextField from "components/form-fields/text-field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CaseButton from "components/buttons/case-button";
import { RootState } from "redux/reducers";
import { useFormikContext, FormikProps, Field } from "formik";
import { Invoice } from "types/customer-types";
import { IActionState, ActionResponse } from "types/async-types";
import { fetchInvoices } from "actions/customer";
import { useAsyncAction } from "utils/async-action";
import RadioButton from "components/form-fields/radio-button";

import SettingsVoiceIcon from "@material-ui/icons/Mic";
import { orderBy } from "lodash";
import _ from "lodash";
import { formatDateTime } from "utils/date";
import { setRecording } from "actions/issues";
import { required } from "validators";
import { StepAction } from "components/cases/issue-details";

interface IFormValues {
  installment_type: string;
  payment_type: "total" | "installments";
  installments_count: number;
  first_installment_date: string;
  payment_date: string;
  notes?: string;
  selected_invoice?: string;
  finished?: boolean;
}

const installmentOpts = [
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
];

interface IExtendedInvoice extends Invoice {
  test_id: string;
}

const RepaymentTerms = ({
  component,
  nextStep,
  stepAction,
  step,
  issue,
}: {
  component: IStepComponent<IFormValues>;
  nextStep: () => void;
  stepAction: StepAction;
  step: IIssueStep;
  issue: IIssue;
}) => {
  const customer = useSelector((state: RootState) => state.customer);

  const namePrefix = `${component.name}`;
  const compValues: IFormValues = component.value;
  const formikProps = useFormikContext<{ [key: string]: IFormValues }>();
  const values = formikProps.values[namePrefix];

  const [recordingState, execSetRecording]: readonly [
    IActionState<any>,
    typeof setRecording
  ] = useAsyncAction(setRecording);

  const name = (name: keyof IFormValues) => `${namePrefix}.${name}`;

  const installmentTypeName = `${namePrefix}.installment_type`;
  const paymentTypeName = `${namePrefix}.payment_type`;
  const installmentCountName = `${namePrefix}.installments_count`;
  const firstInstallmentDateName = `${namePrefix}.first_installment_date`;
  const paymentDateName = `${namePrefix}.payment_date`;
  const notesName = `${namePrefix}.notes`;
  const service = useSelector((state: RootState) => state.service);

  useEffect(() => {
    if (component.value) {
      formikProps.setFieldValue(
        installmentTypeName,
        compValues.installment_type
      );
      formikProps.setFieldValue(paymentTypeName, compValues.payment_type);
      formikProps.setFieldValue(
        installmentCountName,
        compValues.installments_count
      );

      formikProps.setFieldValue(
        firstInstallmentDateName,
        component.value.first_installment_date || ""
      );

      formikProps.setFieldValue(paymentDateName, component.value.payment_date);
      formikProps.setFieldValue(notesName, component.value.notes);
    } else {
      formikProps.setFieldValue(paymentTypeName, "total");
    }
  }, []);

  // const [dateTime, setDateTime] = useState<Date | undefined>();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [dateOrder, setDateOrder] = useState<"asc" | "desc">("desc");
  const [invoicesList, setInvoicesList] = useState<IExtendedInvoice[]>([]);
  const [totalPrice, setTotalPrice] = useState(4599);

  const compareByNumber = (a: Invoice, b: Invoice) => {
    if (a.number < b.number) {
      return -1;
    }
    if (a.number > b.number) {
      return 1;
    }
    return 0;
  };

  const compareByType = (a: Invoice, b: Invoice) => {
    if (a.paymentType < b.paymentType) {
      return -1;
    }
    if (a.paymentType > b.paymentType) {
      return 1;
    }
    return 0;
  };

  const orderBy = (
    prop: "number" | "paymentType" | "paymentDate" | "drawDate"
  ) => {
    let sortedList;
    switch (prop) {
      case "number":
        sortedList = invoicesList.sort(compareByNumber);
        break;
      case "paymentType":
        sortedList = invoicesList.sort(compareByType);
        break;
      case "drawDate":
        setDateOrder(dateOrder === "asc" ? "desc" : "asc");
        sortedList = _.orderBy(invoicesList, ["drawDate"], [dateOrder]);

        break;
      default:
        sortedList = invoicesList;
    }

    setInvoicesList(sortedList);
  };

  const [invoicesState, execFetchInvoices]: readonly [
    IActionState<{ list: Invoice[]; total: number }>,
    typeof fetchInvoices
  ] = useAsyncAction(fetchInvoices);

  useEffect(() => {
    console.log("invoicesState", invoicesState);
    if (invoicesState.data) {
      const testInvoices: IExtendedInvoice[] = invoicesState.data.list.map(
        (item, index) => {
          return { ...item, test_id: index + "_inv" };
        }
      );
      setInvoicesList(testInvoices);
    }
  }, [invoicesState.data]);

  useEffect(() => {
    if (customer) {
      execFetchInvoices(customer?.customer_uuid, "2020-08-31", "2021-09-01");
      // setTimeout(() => formikProps.validateForm());
    }
  }, [customer]);

  const paymentTypeVal = values?.payment_type;

  useEffect(() => {
    setTimeout(() => formikProps.validateForm());
  }, [paymentTypeVal]);

  //   useEffect(() => {
  //     if (dateTime) {
  //       formikProps.setFieldValue(component.name, dateTime);
  //     }
  //   }, [dateTime]);

  const renderInvoices = () => {
    if (selectedInvoice && component.value?.selected_invoice) {
      return (
        <tr className={"selected"} key={selectedInvoice.id + "_inv"}>
          {/* <td className="md-l-t">{selectedInvoice.number}</td>
          <td className="md-l-t">{selectedInvoice.paymentDueDate}</td> */}
          {/* <td className="md-t black">{selectedInvoice.paymentType}</td> */}

          {/* <td className="md-t black">
            {selectedInvoice.paymentDate ? (
              formatDateTime(selectedInvoice.paymentDate) 
            ) : (
              <div className="t-center">-</div> 
            )}
          </td> */}
          {/* <td className="md-t black">{selectedInvoice.bruttoValue}</td> */}
          {/* <td className="md-t black">
            {formatDateTime(selectedInvoice.drawDate)}
          </td> */}
          {/* <td className="table-actions">
            <a>
              {" "}
              <MailOutlineIcon />{" "}
            </a>
            <a>
              <CloudDownloadIcon />{" "}
            </a>
          </td> */}

            <td className="md-l-t">{selectedInvoice.number}</td>
            <td className="md-t black">{selectedInvoice.nettoValue}</td>
            <td className="md-t black">{selectedInvoice.drawDate}</td>
            <td className="md-t black">{selectedInvoice.paymentDate || <div className="t-center">-</div>}</td>
            <td className="md-t black">{selectedInvoice.settlement}</td>
        </tr>
      );
    }

    if (invoicesList) {
      return invoicesList.map((item, index) => {
        const selected = selectedInvoice === item;
        return (
          <tr
            onClick={() => selectInvoice(item)}
            className={`${selected ? "selected" : ""}`}
            key={item.id + "_inv"}
          >
            {/* <td className="md-l-t">{item.number}</td>
            <td className="md-l-t">{item.paymentDueDate}</td>
            <td className="md-t black">{item.bruttoValue} zł</td> */}
            {/* <td className="md-t black">{item.paymentType}</td> */}
            {/* <td className="md-t black">
              {item.paymentDate ? (
                formatDateTime(item.paymentDate)
              ) : (
                <div className="t-center">-</div>
              )}
            </td> */}
            {/* <td className="md-t black">
              {formatDateTime(item.drawDate)}
            </td> */}
            {/* <td className="table-actions">
              <a>
                {" "}
                <MailOutlineIcon />{" "}
              </a>
              <a>
                <CloudDownloadIcon />{" "}
              </a>
            </td> */}
            <td className="md-l-t">{item.number}</td>
            <td className="md-t black">{item.nettoValue}</td>
            <td className="md-t black">{item.drawDate}</td>
            <td className="md-t black">{item.paymentDate || <div className="t-center">-</div>}</td>
            <td className="md-t black">{item.settlement}</td>
          </tr>
        );
      });
    }
  };

  const selectInvoice = (item: Invoice) => {
    setSelectedInvoice(item);
    formikProps.setFieldValue(`${component.name}.selected_invoice`, item);
  };
  const installmentsCount = values?.installments_count;
  const totalDebt = invoicesState.data?.total || 0.0;

  const submitStep = async () => {
    if (values.payment_type === "total") {
      localStorage.setItem("payment_date", values.payment_date);
      localStorage.setItem("debt", totalDebt ? totalDebt.toString() : "");
      localStorage.removeItem("installments_counts");
    } else {
      localStorage.removeItem("payment_date");
     
      localStorage.setItem(
        "installments_counts",
        values.installments_count.toString()
      );
      localStorage.setItem("installments_type", values.installment_type);
      localStorage.setItem(
        "first_installment_date",
        values.first_installment_date
      );
      localStorage.setItem("debt", totalDebt ? totalDebt.toString() : "");
    }

    if (customer?.customer_uuid) {
      const res = await execSetRecording(
        customer.customer_uuid,
        issue.uuid,
        step.uuid,
        service?.currentService?.contact_id || '',
        "term_accept",
        "start_recording"
      );

      if (res.status === 200) {
        formikProps.setFieldValue(`${component.name}.finished`, true);
        stepAction("next_step", formikProps);
      }
    }
  };

  return (
    <div className="step-component mb-20">
      <h3>Bieżące zadłużenie</h3>

      {invoicesList && (
        <div className="invoice-table mb-20">
          {invoicesState.loading ? (
            <div className="spinner-item t-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
              <table className="default-table">
                <thead>
                  <tr className="md-t t-700 t-left">
                  <th>Numer</th>
                  <th>Kwota</th>
                  <th>Data dokumentu</th>
                  <th>Termin płatności</th>
                  <th>Podstawa roszczenia</th>
                    {/* <th onClick={() => orderBy("number")}>Numer</th>
                    <th onClick={() => orderBy("number")}>Termin spłaty</th> */}
                    {/* <th onClick={() => orderBy("paymentType")}>Typ</th> */}
                    {/* <th onClick={() => orderBy("paymentDate")}>
                      Data opłacenia
                    </th> */}
                    {/* <th>kwota</th> */}
                    {/* <th onClick={() => orderBy("drawDate")}>
                      Data wystawienia {dateOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    </th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderInvoices()}</tbody>
            </table>
          )}
        </div>
      )}
      {invoicesList?.length === 0 && (
        <div className="invoice-table mb-20"> Brak wyników </div>
      )}
      <div className="invoice-summary">
        <h2 className="grey">Należność wymagana</h2>{" "}
        <label className="price red">
          {totalDebt &&
            totalDebt
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " zł"}{" "}
        </label>
      </div>
      <h3 className="mb-20">Numer konta do wpłaty: <span className='green'>{customer?.account}</span></h3>
      <div className="mb-15">
        <h3>Warunki spłaty</h3>

        <RadioButton
          name={name("payment_type")}
          value="total"
          label="Spłata całkowita"
          id={`radio_total_${issue.uuid}`}
        />
        <RadioButton
         name={name("payment_type")}
          value="installments"
          label="Spłata ratalna"
          id={`radio_installments_${issue.uuid}`}
        />
      </div>
      {paymentTypeVal === "installments" && (
        <div className="row">
          <div className="col">
            <SelectField
              options={installmentOpts}
              label="Ilość rat"
              name={name("installments_count")}
              // validate={required}
            />
          </div>
          <div className="col">
            <SelectField
              name={name("installment_type")}
              // validate={required}
              options={[
                { value: "month", label: "Miesięczne" },
                { value: "other", label: "Inne" },
              ]}
              label="Rodzaj rat"
            />
          </div>
          <div className="col">
            <TextField
              label="Data pierwszej raty"
              name={name("first_installment_date")}
              type="date"
              validate={required}
            />
          </div>
          <div className="col installments-summary">
            {installmentsCount && values?.installment_type && totalDebt && (
              <div>
                <label className="form-label">Ustalone raty</label>
                <div className="dark mb-5">
                  {" "}
                  {installmentsCount} x{" "}
                  <label className="price">
                    {(totalDebt / installmentsCount).toFixed(2)}
                  </label>{" "}
                </div>
              </div>
            )}
          </div>
          <TextField
            name={name("notes")}
            label="Uwagi lub komentarz"
            type="textarea"
          />
        </div>
      )}
      {paymentTypeVal === "total" && (
        <TextField
          label="Deklarowana data spłaty"
          name={name("payment_date")}
          type="date"
          validate={required}
        />
      )}
      {!component.value && (
        <div className="row">
          <div className="col">
            <CaseButton
              disabled={!formikProps.isValid}
              onClick={() => {
                submitStep();
              }}
              //    onClick={() => setNextStep()}
              title="Przejdź do oświadczenia"
              type="positive"
              CustomIcon={<SettingsVoiceIcon className="red" />}
              inline={true}
            />
          </div>

          <div className="col">
            <CaseButton
              // onClick={() => nextStep()}
              onClick={async () => {
                formikProps.setFieldValue(name("finished"), true);

                stepAction("terminate", formikProps);
              }}
              title="Deklaracja dokonanej płatności"
              type="positive"
              inline={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RepaymentTerms;
