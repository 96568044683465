import React, { useState, useEffect, useRef } from "react";
import {
  IStepComponent,
  IIssue,
  IIssueStep,
} from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useFormikContext, FormikProps, Formik } from "formik";
import Button from "components/buttons/form-button";
import {
  IActionState,
  IReduxActionState,
  ActionResponse,
} from "types/async-types";
import { finishIssue, syncAttachedIssues } from "actions/issues";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { assignClient, fetchService } from "actions/services";
import { fetchCustomer, fetchCustomerById, fetchCustomers, fetchCustomersByNip } from "actions/customer";
import { IService } from "types/agent-types";
import { ICustomer } from "types/customer-types";
import { StepAction } from "components/cases/issue-details";
import SelectField from "components/form-fields/select-field-2";
import Spinner from "components/common/spinner";

interface IFormValues {
  [componentName: string]: {
    ext_id: string;
  };
}
const AssaignCustomerByNip = ({
  component,
  step,
  nextStep,
  stepAction,
  issue,
}: {
  component: IStepComponent;
  nextStep: () => void;
  step: IIssueStep;
  stepAction: StepAction;
  issue: IIssue;
}) => {
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  const formikProps = useFormikContext<IFormValues>();
  const values = formikProps.values[component.name];

  const extIdField = `${component.name}.ext_id`;

  const [assignState, execAssign]: readonly [
    IActionState<IService>,
    typeof assignClient
  ] = useAsyncAction(assignClient);

  const [fetchCustomerState, execFetchCustomerById]: readonly [
    IReduxActionState<any>,
    (...body: any[]) => Promise<any>
  ] = useReduxAction(fetchCustomerById, "customer");

  const [finishIssueState, execFinishIssue]: readonly [
    IActionState<any>,
    typeof finishIssue
  ] = useAsyncAction(finishIssue);

  const [syncIssueState, execSyncAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(syncAttachedIssues, "attached-issues");

  useEffect(() => {
    if (component.value) {
      formikProps.setFieldValue(extIdField, component.value["ext_id"]);
    }

    setTimeout(() => formikProps.validateForm());
  }, []);

  const [serviceState, execRefreshService]: readonly [
    IReduxActionState<any>,
    (...body: any[]) => Promise<any>
  ] = useReduxAction(fetchService, "customer");

  useEffect(() => {
    const func = async () => {
      if (assignState.data && service?.currentService) {
        const res: ActionResponse<ICustomer> = await execFetchCustomerById(
          assignState.data.customer_id
        ); // update customer in redux & assign to 'res' variable
        if (res.data.customer_uuid) {
          await stepAction("next_step", formikProps, {
            customer_id: res.data.customer_uuid,
          });
          //here we don't have updated customer redux state. We need to use res.data.customer_uuid
          await execFinishIssue(res.data.customer_uuid, issue.uuid);
          // await execSyncAttachedIssues(res.data.customer_uuid, issue.uuid);
          await execRefreshService(service?.currentService?.id || '')
        }
      }
    };

    func();
  }, [assignState.data]);

  const onCustomerAssign = async () => {
    debugger;
    if (customer && service?.currentService && values.ext_id) {
      execAssign(service.currentService.id, values.ext_id);
    }
  };

  interface ICustomersSearch {
    list: {
      id: string;
      name: string;
      ext_id: string;
    }[];
  }

  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const timoutRef = useRef();
  timoutRef.current = timeoutVar;

  const [customersState, execFetchCustomers]: readonly [
    IActionState<ICustomersSearch>,
    typeof fetchCustomersByNip
  ] = useAsyncAction(fetchCustomersByNip);

  const renderCustomerOpts = () => {
    if (customersState.data?.list) {
      return customersState.data?.list.map((item, index) => {
        return {
          label: item.name,
          value: item.ext_id,
        };
      });
    }
  };

  const handleClientChange = (e: string) => {
    // formikProps.setFieldValue("search_term", e.target.value);

    // if (e.target. === "Enter") {
    //   e.preventDefault();
    // }

    // if (e !== "" && e.length < 3) {
    //   return;
    // }

    clearTimeout(timoutRef.current);
    setTimeoutVar(setTimeout(() => execFetchCustomers(e), 500));
  };

  return (
    <div className="step-component mb-20">
      {/* <Formik
        initialValues={{ ext_id: "" }}
        isInitialValid={false}
        onSubmit={onCustomerAssign}
      >
        {(formikProps: FormikProps<IFormValues>) => { */}
        {customersState.loading && <Spinner />}
      <div>
        <h3>{component.title}</h3>
        <SelectField
          disabled={step.status === "completed"}
          name={extIdField}
          onChange={(e) => {
            handleClientChange(e);
          }}
          options={renderCustomerOpts() || []}
          label=""
        />
        {step.status !== "completed" && (
          <Button
            disabled={!formikProps.isValid}
            title="Przypisz klienta"
            color="blue-color"
            width={200}
            onClick={onCustomerAssign}
            className="mb-30"
          />
        )}
      </div>

      {/* }}
      </Formik> */}
    </div>
  );
};

export default AssaignCustomerByNip;
