import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IStepComponent,
  StepActionType,
  IIssue,
  IIssueStep,
} from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useFormikContext, FormikProps, Formik } from "formik";
import TextField from "components/form-fields/text-field";
import CaseButton from "components/buttons/case-button";

import CallIcon from "@material-ui/icons/Call";
import style from "components/modal/incoming-call-modal.module.scss";
import Button from "components/buttons/form-button";
import { IActionState, IReduxActionState } from "types/async-types";
import { createMonit } from "actions/issues";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { required } from "validators";

const MonitCreator = ({
  component,
  step,
  nextStep,
  stepAction,
  issue,
}: {
  component: IStepComponent;
  nextStep: () => void;
  step: IIssueStep
  stepAction: (
    actionType: StepActionType,
    formikProps: FormikProps<any>
  ) => any;
  issue: IIssue;
}) => {
  const [dateTime, setDateTime] = useState<Date | undefined>();
  const [isCalling, setIsCalling] = useState(false);
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  const formikProps = useFormikContext<any>();

  const monitDateField = `${component.name}.monit_date`;

  const [monitState, execCreateMonit]: readonly [
    IReduxActionState<any>,
    (...a: any) => any
  ] = useReduxAction(createMonit, 'create-monit');


  useEffect(() => {

    if (component.value) {
      formikProps.setFieldValue('date', component.value["monit_date"])
      
    }

    setTimeout(() => formikProps.validateForm());
  }, []);

  useEffect(() => {
    if (monitState.data) {
      // formikProps.setFieldV
      formikProps.setFieldValue(monitDateField, formikProps.values["date"]);
    }
  }, [monitState.data])

  const onMonitCreate = (values : any) => {
    if (customer && service?.currentService) {
      const monitData = {
        customer_service_id: service?.currentService?.id,
        date: values["date"],
      };
      execCreateMonit(customer?.customer_uuid, issue.uuid, monitData);
    }
  };
  return (
    <div className="step-component mb-20">
      <Formik initialValues={{date : ""}} onSubmit={onMonitCreate}>
        {(formikprops : FormikProps<{date? : string}>) => {
          return <div>

            <h3>{component.title}</h3>
            <TextField type="date" validate={required} disabled={step.status === 'completed'} label="Data następnego kontaktu" name="date" />
            {step.status !== 'completed' && (
              <Button
                disabled={!formikprops.isValid}
                title="zaplanuj kontakt"
                color="blue-color"
                width={200}
                onClick={formikprops.submitForm}
                className="mb-30"
              />
            )}

          </div>


        }}

      </Formik>
    </div>
  );
};

export default MonitCreator;
