import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import TextField from "components/form-fields/text-field";
import FormButton from "components/buttons/form-button";

import Spinner from "components/common/spinner";
import * as Yup from "yup";
import axios from 'axios';
import { yupRequired } from "validators";
import { Link } from "react-router-dom";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { standardSignIn } from "actions/user";
import { IActionState, IReduxActionState } from "types/async-types";
import { PAGE } from "paths";
import { Switch, Route } from "react-router-dom";
// import Index from './home';
import { withAuthSync, login } from "utils/auth";
import styles from './login.module.scss'
import { useDispatch } from "react-redux";

interface IStandardLoginFormValues {
  login: string;
  password: string;
}

function LoginForm() {
  // props & hooks //
  const dispatch = useDispatch();
  // async actions
  const [signInState, execStandardSignIn]: readonly [
    IReduxActionState<any>,
    any,
  ] = useReduxAction(standardSignIn, "user");

  const logIn = async (values: IStandardLoginFormValues) => {
    execStandardSignIn({login: values.login, password: values.password});
  
  };

  // useEffect(() => {
  //   if(signInState.data){
  //     login(signInState.data.api_token);
  //   }
  // }, [signInState, execStandardSignIn]) 




  // form fields data
  const fields = {
    login: {
      name: "login",
      type: "text",
      label: "login",
      required: true,
    },
    email: {
      name: "email",
      type: "email",
      label: "Adres email",
      required: true,
    },
    password: {
      name: "password",
      type: "password",
      label: "Hasło",
      required: true,
    } as React.ComponentProps<typeof TextField>,
  };

  // Formik setup props
  const initialValues: IStandardLoginFormValues = {
    login: "",
    password: "",
  };
  let validationSchema = Yup.object().shape({
    login: Yup.string().concat(yupRequired),
    password: Yup.string().concat(yupRequired),
  });

  return (
    <div>
      <Formik
        className="login-form"
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={initialValues}
        onSubmit={logIn}
        // onSubmit={codeSent ? logIn : getCode}
      >
        {({ values, submitForm, isValid, errors }) => {
          return (
            <div data-testid="standard-form" className={`${styles['login-form']}`}>
              <div >
                
                  {/* {signInState.loading && <Spinner />} */}
                  <div className="mb-10">
                  <TextField  testId={"email"} {...fields.login} type="text" />
                  </div>
                  <TextField
                    testId={"password"}
                    {...fields.password}
                    type="password"
                  />

                  <FormButton
                    dataTest="submit-btn"
                    disabled={!isValid}
                    className="mt-16 mb-30"
                    onClick={submitForm}
                    title="Zaloguj"
                    color="black-color"
                  />
                
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default LoginForm;
