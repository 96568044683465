import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IActionState } from "types/async-types";
import { IIssueTemplate } from "types/issue-types";
import { useAsyncAction } from "utils/async-action";
import { fetchIssueTemplates } from "actions/issues";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
  show: boolean;
  onHide: () => void;
  addIssue: (a: string) => void;
}

const IssueModal = (props: IProps) => {
  const [templatesState, execFetchTemplates]: readonly [
    IActionState<{templates :IIssueTemplate[]}>,
    () => any
  ] = useAsyncAction(fetchIssueTemplates);

  const renderLinks = () => {
    if (templatesState.data) {
      return templatesState.data.templates.map((item, index) => {
        return (
          <li key={item.uuid + index} className="pointer mt-2" onClick={() => props.addIssue(item.uuid)}>
            {item.title}
          </li>
        );
      });
    }
  };
  useEffect(() => {
    
    execFetchTemplates();
  }, []);

  return (
    <Modal animation={false} centered show={props.show} onHide={() => props.onHide()}>
      <Modal.Header>
        <h3  className="mb-0">Nowa sprawa</h3>
        <CloseIcon className="close" onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body className={``}>
        <div className="sm-l-t mb-10 dark">Katalog spraw:</div>
        <ul className="md-t">{renderLinks()}</ul>
      </Modal.Body>
    </Modal>
  );
};

export default IssueModal;
