


export function isLoading({isLoading, stateProperty, errorCode} : any) {
    return function (dispatch : any) {
      if (isLoading) {
        dispatch({
          type: "SET_LOADING",
          payload: { isLoading, property: stateProperty },
        });
      } else {
        dispatch({
          type: "SET_LOADING",
          payload: { isLoading: false, property: null, errorCode, succeeded : stateProperty },
        });
      }
    };
  }
  

