import { IWebphoneCredentials } from "types/session-types";
import { WebphoneStatus } from "types/webphone-types";

interface  IWebPhoneConfig  {
  server: string,
  autoanswer: string,
  janusServer: string,
  port: string,
  localAudioStreamId: string,
  remoteAudioStreamId: string,
  localVideoStreamId: string,
  remoteVideoStreamId: string,
  ringingSoundPath: string,
  logCatcher: boolean,
  agentDescription: string,

  //iceServers: [STUN_2,STUN_3,STUN_4]
  iceServers: {
    urls: string,
    credential: string,
    username: string
  }[]
}


let webphone : any = null;

declare global {
    interface Window {
      altar : any;
      answer: any;
      integralnet: any;
      find_user : (number : any) => void;
    }
  }

  window.find_user = function(number: any) {
    // $.ajax({
    //   url: '/find_user?number='+number,
    //   dataType: 'script'
    // } )
  }





  export interface IInitConfig {
    config :IWebPhoneConfig,
    setCallerModalVisible? : (a: boolean) => any,
    setWebphoneState : (a : WebphoneStatus) => any,
    credentials : IWebphoneCredentials | undefined
    
  } 


export const init = (initConfig : IInitConfig ) => {

  var changeListener = function(state :any, currentCall : any) {
    console.log('[DEMO HTML] changeListener: state=' + state);
    initConfig.setWebphoneState(state);

    if(state === 'calling'){
      console.log('[DEMO HTML] changeListener: currentCall: ', currentCall);
      
    }
    
    // console.log('init()');
    //document.getElementById("stateText").innerHTML = state;
  
    switch (state) {
        case 'uninitialized':
            // console.log('[DEMO HTML] uninitialized');
            break;
        case 'initializing':
            // console.log('[DEMO HTML] initializing');
            
            break;
        case 'initialized':
            // console.log('[DEMO HTML] initialized');
            
            if(initConfig.credentials){
              register(initConfig.credentials);  
            }
            
            break;
        case 'registering':
            // console.log('[DEMO HTML] registering');
            
            break;
        case 'registered':
            // console.log('[DEMO HTML] registered');
            
            break;
        case 'register-failed':
            // console.log('[DEMO HTML] register-failed');
            
  
            break;
        case 'incoming':
            // console.log('[DEMO HTML] incoming');
            getCaller();
            
            // window.postMessage("openPhone", "*")
            
            if(initConfig.setCallerModalVisible){
              initConfig.setCallerModalVisible(true);
            }
            
            // showIncoming();
            //webphone.answer();
            break;
        case 'call':
            // console.log('[DEMO HTML] call');
            
            break;
        case 'error':
            // console.log('[DEMO HTML] error');
            
            break;
        case 'ready':
            // console.log('[DEMO HTML] ready');
            
            
            break;
        default:
            // console.log('[DEMO HTML] not supported state!');
    }
  }

  

    // webphone = window.integralnet.webaphone.JanusWebphone(initConfig.config, changeListener);
    webphone = new window.altar.webaphone.JanusWebphone(initConfig.config, changeListener);
    
    return webphone;
  }

  function register(credentials : IWebphoneCredentials) {
    // console.log('proba rejestracji')
    webphone.register(credentials.username, credentials.password);
  }

  function getCaller() {
    webphone.getCallerNumber();
    let caller = webphone.getCallerNumber();
    // console.log(caller);
    debugger
    console.log('client @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@' + caller)  
    // alert(caller);

    // window.find_user(caller.replace("sip:", "").replace("@46.242.131.202", "").replaceAll("\"", ""));
  }

//   function showIncoming(){
//     // $('.left-pan').addClass('active');
//     // $('.contacts').fadeOut(100);
//     // $('.calling').fadeIn(800);
//     // $('.call-answer').show();
// }

 



  // init();
  
  export default webphone;