import { DATA_SUBMITTING,DATA_FETCHING} from 'types/redux-types'

export default function(state = {}, action: any){

  switch(action.type){
      case DATA_SUBMITTING:
        return {...state, data_submitting : action.payload.fetching};
      case DATA_FETCHING:
        return {...state, data_fetching : action.payload.fetching};
      default:
        return state;
  }
}
