import React, {useState, useEffect} from 'react'
import { ITextComponent } from 'types/issue-types'


 const Text = (props : ITextComponent) => {

    return (<div className="step-component mb-20">
        <h3>{props.title}</h3>
        <div className="lg-con-t italic">
           
            {props.text}
        </div>
        </div>
    )
 }


 export default Text;