import {
  FETCH_CUSTOMER,
  FETCH_INVOICES,
  CustomerActionTypes,
  FETCH_CONTACTS,
  FETCH_ISSUES,
  FETCH_ISSUE,
  ATTACH_ISSUE,
  DESTROY_ISSUE,
  SYNC_ATTACHED_ISSUE,
  FETCH_ATTACHED_ISSUES,
  CLEAR_ATTACHED_ISSUES,
  START_CALL,
} from "types/redux-types";
import { ICustomer } from "types/customer-types";

export interface IPasswordInfoPayload {}

export interface ICustomerState {
  attached_issues: {};
}

const initialState: ICustomer | null = null;

export default function Reducer(
  state = initialState,
  action: CustomerActionTypes
): ICustomer | null {
  switch (action.type) {
    case FETCH_CUSTOMER:
      return { ...state, ...action.payload };
    case FETCH_INVOICES:
      if (state) {
        return { ...state, invoices: action.payload };
      } else {
        return null;
      }
    case FETCH_CONTACTS:
      if (state) {
        return { ...state, contacts: action.payload };
      } else {
        return null;
      }
    case FETCH_ISSUES:
      if (state) {
        return { ...state, issues: action.payload };
      } else {
        return null;
      }
    case FETCH_ISSUE:
      if (state) {
        return { ...state, issue: action.payload };
      } else {
        return null;
      }
    case ATTACH_ISSUE:
      if (state) {
        return {
          ...state,
          attached_issues: state.attached_issues
            ? {
                ...state.attached_issues,
                [action.payload.uuid]: action.payload,
              }
            : { [action.payload.uuid]: action.payload },
        };
      } else {
        return null;
      }

    //to update in future
    case CLEAR_ATTACHED_ISSUES:
      if (state) {
        return {
          ...state,
          attached_issues: {},
        };
      } else {
        return null;
      }

    //to update in future
    case FETCH_ATTACHED_ISSUES:
      if (state) {
        return {
          ...state,
          attached_issues: state.attached_issues
            ? {
                ...state.attached_issues,
                [action.payload.uuid]: action.payload,
              }
            : { [action.payload.uuid]: action.payload },
        };
      } else {
        return null;
      }
    case SYNC_ATTACHED_ISSUE:
      if (state) {
        return {
          ...state,
          attached_issues: {
            ...state.attached_issues,
            [action.payload.uuid]: action.payload,
          },
          // attached_issues: {[action.payload.uuid]: action.payload },
        };
      } else {
        return null;
      }
    //only case so far when attached issue is removed from tabs
    case DESTROY_ISSUE:
      if (state) {
        const { [action.payload]: value, ...rest } = state.attached_issues;
        return {
          ...state,
          attached_issues: rest,
        };
      } else {
        return null;
      }
    case START_CALL:
      if (!state) {
        return null;
      }
      return {
        ...state,
        contact_uuid: action.payload.contact_id,
        call_issue_id : action.payload.call_issue_id
      };
    default:
      return state;
  }
}
