import React, { useState, useEffect } from "react";
import style from "./service-panel.module.scss";
import { useStopwatch } from "react-timer-hook";
import WorkPanel from 'components/common/work-panel'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import {useDetectClickOutside} from 'react-detect-click-outside';
import { PAGE } from "paths";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export interface Iprops {
  logout : () => any
}

const UserDropdown = (props: Iprops) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);



  // const ref = useDetectClickOutside({ onTriggered: () => setMenuOpen(false) });
  // const userStopWatch = useStopwatch({
  //   autoStart: true,
  // });

  // const { seconds, minutes, hours, start, pause, reset } = userStopWatch;

  const UserBox = () => (
    <div
      className={`${style["user-dropdown__user-box"]} flex-left`}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <div className="agent-info flex-left grow-1">
        <div className={`user-image mr-5`}>
          {user.session?.avatar_url ? <img src={user.session?.avatar_url} /> :  <img src="/assets/images/user.svg" /> }
        </div>
        <div>
          <div className="md-con-t">Zalogowany jako:</div>
          <div className="lg-t">{user.session?.name || "Manager"}</div> 
        </div>
        <div className="work-time grow-1 t-right"></div>
      </div>
      <ArrowDropDownIcon />
    </div>
  );

  return (
    <div   className={`${style["user-dropdown"]}`}>
      {menuOpen && (
        <div
          onClick={() => setMenuOpen(!menuOpen)}
          className="modal-cover"
        ></div>
      )}

      {UserBox()}
      {menuOpen && (
        <div  className={`${style["user-dropdown__menu"]}`}>
          {UserBox()}
          <WorkPanel />
          <Link to={PAGE.ADMIN} className={`${style["user-dropdown__item"]} flex-left`}>
            <img
              alt="tiles"
              className={`${style["user-dropdown__icon"]}`}
              src="/assets/images/dashboard.svg"
            />
            <span className="md-t grow-1">Dashboard</span>
            <ChevronRightIcon className="light2" />
          </Link>
          <Link to={PAGE.TASKS} className={`${style["user-dropdown__item"]} flex-left`}>
            <img
              alt="tiles"
              className={`${style["user-dropdown__icon"]}`}
              src="/assets/images/dashboard.svg"
            />
            <span className="md-t grow-1">Moje zadania</span>
            <ChevronRightIcon className="light2" />
          </Link>
          <div className={`${style["user-dropdown__item"]} flex-left`}>
            <img
              alt="tiles"
              className={`${style["user-dropdown__icon"]}`}
              src="/assets/images/cog.svg"
            />
            <span className="md-t grow-1 grey">Ustawienia konta</span>
            <ChevronRightIcon className="light2" />
          </div>
          <div onClick={() => props.logout()} className={`${style["user-dropdown__item"]} flex-left`}>
            <img
              alt="tiles"
              className={`${style["user-dropdown__icon"]}`}
              src="/assets/images/logout.svg"
            />
            <span className="md-t grow-1">Wyloguj</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
