import { IStepComponent, IIssueStep } from "types/issue-types";

export function getNested(theObject: any, path: string, separator?: string) {
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, theObject);
  } catch (err) {
    return undefined;
  }
}


export const renderOpts = (items?: { name: string; id: string }[]) => {

  if (!items) {
    return []
  }


  return items?.map((item: any, index: number) => {
    return {
      label: item.name,
      value: item.id,
    };
  });


}

export function compareComponents(a: IStepComponent, b: IStepComponent) {
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
}

export function compareSteps(a: IIssueStep, b: IIssueStep) {
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
}