import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { ICustomerAgreement } from "types/customer-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchAgreements } from "actions/customer";
import { useAsyncAction } from "utils/async-action";
import { IActionState } from "types/async-types";
import Spinner from "./spinner";
import Table from "@material-ui/core/Table";
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

interface IProps {
  show: boolean;
  onHide: () => void;

}

export const AgreementsModal = (props: IProps) => {
  const { show, onHide } = props;

  const [agreementsState, execFetchAgreements]: readonly [
    IActionState<ICustomerAgreement[]>,
    typeof fetchAgreements
  ] = useAsyncAction(fetchAgreements);

  const customer = useSelector((state: RootState) => state.customer);
 
  useEffect(() => {
    if(show){ //&& agreementsState.data == null){
      execFetchAgreements(customer?.customer_uuid || "");
    }
  }, [show]); 
  

  const renderTable = (rows: ICustomerAgreement[]) => {
    return(
      <TableContainer component={Paper}>
      <Table className={``} aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell align="right">Umowa</TableCell>
            <TableCell align="left">Data</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.sort((a,b) => a.position - b.position).map((row) => (
            <TableRow key={row.name}>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
  }

  return (
    <Modal animation={false} centered show={props.show} onHide={() => props.onHide()}>
      <Modal.Header>
        <h3 className="mb-0">Umowy</h3>
        <CloseIcon className="close" onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body className={``}>
        {agreementsState.loading && <Spinner/> }
        {renderTable(agreementsState.data || [])}
      </Modal.Body>
    </Modal>
  );
};

