import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";

import CaseButton from "components/buttons/case-button";
import {
  IFormButtonsComponent,
} from "types/issue-types";
import { useFormikContext, FormikProps } from "formik";

const VerificationForm = ({
  component,
  nextStep,
  stepAction
}: {
  component: IFormButtonsComponent;
  nextStep?: () => void;
  stepAction: (
    actionType: "next_step" | "finish" | "save" | "terminate",
    formikProps: FormikProps<any>
  ) => any;
}) => {
  const formikProps = useFormikContext();

  return ( 
    <div className="row mb-10">
      {component.buttons.success_btn && (
        <div className="col">
          <CaseButton
            onClick={() => stepAction(component.buttons.success_btn.action, formikProps )}
            
            title={component.buttons.success_btn.label}
            type="positive"
            inline={true}
          />
        </div>
      )}
      {component.buttons.save_btn && (
        <div className="col">
          <CaseButton
        
            onClick={() => stepAction(component.buttons.save_btn.action, formikProps )}
            title={component.buttons.save_btn.label}
            icon="save"
            type="neutral"
            inline={true}
          />
        </div>
      )}
      {component.buttons.terminate_btn && (
        <div className="col">
          <CaseButton
            // onClick={() => nextStep()}
            onClick={() => stepAction(component.buttons.terminate_btn.action, formikProps )}
            title={component.buttons.terminate_btn.label}
            type="negative"
            inline={true}
          />
        </div>
      )}
    </div>
  );
};

export default VerificationForm;
