import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Spinner from 'components/common/spinner';
import { IActionState, IReduxActionState } from "types/async-types";
import { IIssueTemplate, IIssueStep, IIssue } from "types/issue-types";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { fetchIssueTemplates, createMonit } from "actions/issues";
import CloseIcon from "@material-ui/icons/Close";
import Button from 'components/buttons/button'
import { TreeItem } from "@material-ui/lab";
import FormButton from 'components/buttons/form-button';
import TextField from "components/form-fields/text-field";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Formik, FormikProps } from "formik";
import { required } from "validators";
interface IMonitFormValues {
  date? : string
}
interface IProps {
  show: boolean;
  onHide: () => void;
  terminateProps?: {
    terminateFn: () => any;
    syncIssueFn : () => any;
    submitFn: (terminateValue : string) => any;
    step: IIssueStep;
    setIssueStatus : (a: "terminated" | "closed") => any
  };
  activeStep : number;
  setActiveStep : any;
  setReachedStep : any;
  issue : IIssue;

}

const IssueModal = (props: IProps) => {
    const {terminateProps, setActiveStep, setReachedStep, activeStep, issue } = props;
    
    const [terminateValue, setTerminateValue] = useState<string>()

    const customer = useSelector((state: RootState) => state.customer);
    const service = useSelector((state: RootState) => state.service)

    const [monitState, execCreateMonit]: readonly [
      IReduxActionState<any>,
       (...a: any) => any
    ] = useReduxAction(createMonit, 'create-monit'); 


  useEffect(() => {
    // execFetchTemplates();
  }, []);

  const onMonitCreate = (values : IMonitFormValues) => {
    
    if (customer && service?.currentService) {
      const monitData = {
        customer_service_id: service?.currentService?.id,
        date: values.date,
      };
      execCreateMonit(customer?.customer_uuid, issue.uuid, monitData);
    }
  };

  const terminate = async () => {
      const confirmed = window.confirm('Proszę potwierdzić zakończenie sprawy')
      if(confirmed) {
        if(terminateProps && terminateValue){
          await terminateProps.submitFn(terminateValue);
          if(terminateValue){
             await terminateProps.terminateFn();
             props.onHide();
             setActiveStep(50);
             terminateProps.setIssueStatus('terminated');
             await terminateProps.syncIssueFn();
             
            //  setReachedStep(activeStep)
          }
  
        }
      }
   

  }

  const renderReasons = () => {
      if(terminateProps){
        return terminateProps.step.terminate_reasons.map((item, index) => {
            return <div className="form-check "><input className="form-check-input" type="radio" id={`terminate_val_${index}`} value={item} onChange={(e) => setTerminateValue(e.target.value)} name="terminate_value" />
                            <label className="form-check-label" htmlFor={`terminate_val_${index}`}>{item}</label>
            </div>
        })
      }

  }
  if(!terminateProps){
      return <div></div>
  }

  return <Formik initialValues={{date : ""}} onSubmit={onMonitCreate} >
    {(formikprops : FormikProps<IMonitFormValues>) => {
          return (
            <Modal centered show={props.show} onHide={() => props.onHide()}>
              {monitState.loading && <Spinner />}
              <Modal.Header>
                  <h3 className="mb-0">Planowanie kontaktu</h3>
                  <CloseIcon className="close" onClick={() => props.onHide()} />
                </Modal.Header>
              <Modal.Body className={``}>
                <TextField validate={required} type="date" label="Data następnego kontaktu" name="date" />
                <Button
                  title="Zaplanuj kontakt"
                  color="blue-color"
                  onClick={formikprops.submitForm}
                  className="mb-30"
                />
              </Modal.Body>

              <Modal.Header>
                <h3 className="mb-0">Zakończenie sprawy</h3>
              </Modal.Header>
              <Modal.Body className={``}>
                <div className="sm-l-t mb-10 dark">Powód zakończenia</div>
                <div className="mb-20">
                  {renderReasons()}
                  <div className="form-group">
                      <label className="form-label">Inny powód</label>
                  <textarea className="form-control" name="terminate_text" onChange={(e) => setTerminateValue(e.target.value)}/>
                  </div>
                </div>
                <FormButton disabled={!terminateValue} color="black-color" title="Zakończ" onClick={() => terminate()}/>
                </Modal.Body>
            </Modal>
          );
    }}



  </Formik>

};

export default IssueModal;
