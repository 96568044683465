import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "components/buttons/button";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import Contacts from './contacts';
import Issues from './issues';
import style from "./left-panel.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

const LeftPanel = (props: any) => {
  const [currentTab, setCurrentTab] = useState(1);
  const customer = useSelector((state: RootState) => state.customer);
  
  // if(!customer){
  //   return <div></div>
  // }
  return (
    <div className={`${style["left-panel"]}`}>
      {/* <p className="condensed dark md-t mb-10">Kontakty i sprawy</p> */}
      <div className="panel no-padding flex-1">
        <Tabs selectedIndex={currentTab} onSelect={(e) => setCurrentTab(e)}>
          <TabList className={`tablist ${style["tabs"]}`}>
            <Tab>
              <h2>Kontakty</h2>
              {/* <span className="md-t dark condensed">Ostatnie 31 dni: 3</span> */}
            </Tab>
            <Tab>
              <h2>Sprawy</h2>{" "}
              {/* <span className="md-t dark condensed">W trakcie: 0</span> */}
            </Tab>
            {/* <div
            className={`${style.tabs_right} ${tabIndex !== 0 && style.hidden}`}
          >
            <div {...year2020}>2020</div>
            <div {...year2019}>2019</div>
            <div {...year2018}>2018</div>
          </div> */}
          </TabList>

          <TabPanel>
            <Contacts />
          </TabPanel>
          <TabPanel><Issues /></TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default LeftPanel;
