import React, { useState, useEffect } from "react";
import EmailIcon from '@material-ui/icons/Email';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import style from "./customer-icon.module.scss";
import { ServiceContactType } from "types/agent-types";

interface IProps {
  name: string;
  lastName?: string;
  color? :string; 
  channel? : ServiceContactType;
}

const CustomerIcon = (props: IProps) => {
  const { name, lastName ,color, channel} = props;
  const [bgColor, setBgColor] = useState<string>();
  const nameArray = name.split(" ");

//   useEffect(() => {
//     if(!color){
//       const newColor = Math.floor(Math.random()*16777215).toString(16);
      
//       setBgColor(newColor);
//     }

// }, [])


  // useEffect(() => {
  //     if(!color){
  //       const newColor = Math.floor(Math.random()*16777215).toString(16);
        
  //       setBgColor(newColor);
  //     }else{
  //         setBgColor(color)
  //     }
 
  // }, [color])
  
  return (
    <div style={{backgroundColor : `${color}`}} className={`${style["customer-image"]}`}>
      {channel === 'mail' &&
        <EmailIcon className={`${style["contact-icon"]} blue`} />
      }
       {channel === 'phone' &&
        <PhoneForwardedIcon  className={`${style["contact-icon"]} green`} />
      }
           {channel === 'meeting' &&
        <EmailIcon className={`${style["contact-icon"]} grey`} />
      }
      {nameArray && nameArray[0] ? nameArray[0][0].toUpperCase() : ""} {(nameArray && nameArray[1]) ? nameArray[1][0].toUpperCase() : ""}
    </div>
  );
};

export default CustomerIcon;
