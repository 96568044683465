import React from "react";
//@ts-ignore
import "styles/common/spinner.scss";

interface IProps {
  bgColor?: string;
  fixed?: boolean;
  size?: "small" | "default" | "large";
  relative? : boolean;
  position? : 'relative' | 'fixed' | 'absolute';
}

const Spinner = (props: IProps) => {
  const { bgColor, fixed, relative, position } = props;
  return (
    <div title="spinner" className={`spin-container ${position || ""}`}>
      <div className={`overlay ${bgColor && "grey"}`}></div>
      <div className="spinner-item">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      </div>
      {/* {process.env.NODE_ENV !== 'test' &&
          // <style jsx global>{s1}</style>
          } */}
    </div>
  );
};

export default Spinner;
