import { ISessionData } from "./session-types";
import { Invoice } from "./customer-types";
import { IIssue, IStartCallRes } from "./issue-types";
import { IService } from "./agent-types";

// user types
export const AUTH_USER = "AUTH_USER";
export const FETCH_SESSION = "FETCH_SSSION";
export const SET_AGENTS_TIMER = "SET_AGENTS_TIMER";
export const SET_BREAK = "SET_BREAK";

// customer types
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_ISSUES = "FETCH_ISSUES";
export const FETCH_ISSUE = "FETCH_ISSUE";
export const ATTACH_ISSUE = "ATTACH_ISSUE";
export const DESTROY_ISSUE = "DESTROY_ISSUE";
export const FETCH_ATTACHED_ISSUES = "FETCH_ATTACHED_ISSUES";
export const SYNC_ATTACHED_ISSUE = "SYNC_ATTACHED_ISSUE";
export const CLEAR_ATTACHED_ISSUES = "CLEAR_ATTACHED_ISSUES";
export const START_CALL = "START_CALL";


//service types
export const FETCH_SERVICE = "FETCH_SERVICE";
export const END_SERVICE = "END_SERVICE";
export const VERIFY = "VERIFY";
export const UPDATE_ISSUE = "UPDATE_CURRENT_USSUE";



export interface ISignInAction {
  type: typeof AUTH_USER;
  payload: {
    user: { token: string };
  };
}

export interface IFetchSessionAction {
  type: typeof FETCH_SESSION;
  payload: ISessionData;
}

export interface ISetBreakAction {
  type: typeof SET_BREAK;
  payload: any;
}

export interface ISetAgentsTimer {
  type: typeof SET_AGENTS_TIMER;
  payload: any;
}

export type UserActionTypes = ISignInAction | IFetchSessionAction | ISetAgentsTimer;

export type WasteActionTypes = {
  type: "SET_TOGGLE_WASTE_EDIT";
  payload: {
    toggleWasteEdit: (id: string) => any;
  };
};

// customer actions creators

export interface IFetchCustomerAction {
  type: typeof FETCH_CUSTOMER;
  payload: any;
}

export interface IFetchCustomerInvoicesAction {
  type: typeof FETCH_INVOICES;
  payload: Invoice[];
}

export interface IFetchCustomerContacts {
  type: typeof FETCH_CONTACTS;
  payload: any;
}

export interface IFetchCustomerIssues {
  type: typeof FETCH_ISSUES;
  payload: any;
}

export interface IFetchCustomerIssue {
  type: typeof FETCH_ISSUE;
  payload: any;
}

export interface IDestroyIssue {
  type: typeof DESTROY_ISSUE;
  payload: string;
}

export interface IAttachIssue {
  type: typeof ATTACH_ISSUE;
  payload: IIssue;
}

export interface IFetchAttachedIssues {
  type: typeof FETCH_ATTACHED_ISSUES;
  payload: IIssue;
}


export interface ISyncAttachedIssue {
  type: typeof SYNC_ATTACHED_ISSUE;
  payload: IIssue;
}

export interface IClearAttachedIssues {
  type: typeof CLEAR_ATTACHED_ISSUES;
  payload: null
}

export interface IFetchService {
  type: typeof FETCH_SERVICE;
  payload: IService;
}

export interface IEndService {
  type: typeof END_SERVICE;
  payload: IService;
}

export interface IVerify {
  type: typeof VERIFY;
  payload: any;
}

export interface IUpdateIssueId {
  type: typeof UPDATE_ISSUE;
  payload: {issue_id? : string, issue_status : string};
}

// ** call actions **// 

export interface IStartCall {
  type: typeof START_CALL;
  payload: {contact_id : string; call_issue_id : string};
}


export type CustomerActionTypes =
  | IFetchCustomerAction
  | IFetchCustomerInvoicesAction
  | IFetchCustomerContacts
  | IFetchCustomerIssues
  | IFetchCustomerIssue
  | IAttachIssue
  | IDestroyIssue
  | ISyncAttachedIssue
  | IFetchAttachedIssues
  | IClearAttachedIssues
  | IStartCall

  export type ServiceActionTypes = IFetchService | IEndService | IVerify | IUpdateIssueId


//error types
export const SET_REQUEST_ERROR = "SET_REQUEST_ERROR";
export const DATA_SUBMITTING = "DATA_SUBMITTING";
export const DATA_FETCHING = "DATA_FETCHING";
