import React, { useState, useEffect } from "react";
import { ITextComponent, IStepComponent } from "types/issue-types";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useFormikContext } from "formik";
import pl from "date-fns/locale/pl";

const Text = ({ component }: { component: IStepComponent }) => {
  const [dateTime, setDateTime] = useState<Date | undefined>();
  const customer = useSelector((state: RootState) => state.customer);
  const formikprops = useFormikContext();

  useEffect(() => {
    if (component.value  ) {
      setDateTime(new Date(component.value));
    } else {
      setDateTime(new Date());
    }
  }, []);

  useEffect(() => {
    if (dateTime) {
      formikprops.setFieldValue(component.name, dateTime);
    }
  }, [dateTime]);
  return (
    <div className="step-component mb-20">
      <h3>{component.title}</h3>

      <div className="form-group mb-20">
        <label className="form-label">
          Uzgodniona z klientem data wizyty serwisanta
        </label>
        <DatePicker
          placeholderText="Podaj datę"
          className="form-control"
          selected={dateTime}
          dateFormat="dd.MM.yyyy"
          locale={pl}
          onChange={(date: any) => setDateTime(date)}
        />
      </div>
      <h3>Dane kontaktowe:</h3>
      {customer && (
        <>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">Osoba kontaktowa</label>
                <div className="md-t">
                  {customer?.name + ' ' + customer?.surname || "Brak danych"}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">Telefon</label>
                <div className="md-t">
                  {customer?.phone_number || "Brak danych"}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-20">
                <label className="form-label">Email</label>
                <div className="md-t">
                  {customer?.email || "Brak danych"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Text;
