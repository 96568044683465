import * as Yup from 'yup'
export const yupRequired = Yup.string().required("pole jest wymagane");

export const required = (value: any) => {
    
    return value ? undefined : "Pole jest wymagane";
}
  

  const validate = (values : any) => {
    let errors : any = {};
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Niepoprawny adres email';
    }
  }