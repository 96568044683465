import React, { useState, useEffect } from "react";
import style from "./customer-icon.module.scss";
import { ICustomer, IServiceTimer } from "types/customer-types";
import CustomerIcon from "components/common/customer-icon";
import CallIcon from "@material-ui/icons/Call";
import {
  IService,
  ServiceStatusType,
  serviceTypeNames,
} from "types/agent-types";
import { useStopwatch } from "react-timer-hook";
import styles from "./service-list.module.scss";
import {
  fetchService,
  startService,
  resumeServiceTimer,
  pauseServiceTimer,
} from "actions/services";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useDispatch } from "react-redux";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  IReduxActionState,
  IActionState,
  ActionResponse,
} from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { syncAttachedIssues, fetchAttachedIssues } from "actions/issues";
import { CLEAR_ATTACHED_ISSUES } from "types/redux-types";
import { fetchCustomerById } from "actions/customer";
import ClosedIssues from "./closed-issues-list";
import { WebphoneContext } from "components/dashboard";

interface IProps {
  customers: IService[];
  disablePanel?: any;
  execFetchService?: () => any;
  isIncoming?: boolean;
  setIncomingData?: (a: IService[] | null) => any;
}

const CustomerList = (props: IProps) => {
  const {
    customers,
    disablePanel,
    execFetchService,
    isIncoming,
    setIncomingData,
  } = props;
  const [pausedServiceId, setPausedServiceId] = useState<string>();
  // const [loadedServices, setLoadedServices] = useState(customers);

  const [fetchAttachedIssuesState, execFetchAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(fetchAttachedIssues, "attached-issues");

  const [pauseTimerState, execPauseTimer]: readonly [
    IActionState<IService>,
    typeof pauseServiceTimer
  ] = useAsyncAction(pauseServiceTimer);

  const [fetchCustomerState, execFetchCustomerById]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(fetchCustomerById, "attached-issues");

  const list = customers.map((item, index) => {
    return (
      <ServiceItem
        setIncomingData={setIncomingData}
        execFetchService={execFetchService}
        execFetchCustomerById={execFetchCustomerById}
        execFetchAttachedIssues={execFetchAttachedIssues}
        disablePanel={disablePanel}
        key={index + item.id}
        service={item}
        isIncoming={isIncoming}
        execPauseTimer={execPauseTimer}
        pauseTimerState={pauseTimerState}
        pausedServiceId={pausedServiceId}
        setPausedServiceId={setPausedServiceId}
        workTimeInSeconds={item.work_time_in_seconds}
      />
    );
  });
  return <ul>{list}</ul>;
};

export const ServiceItem = (props: {
  service: IService;
  disablePanel?: any;
  execFetchService: any;
  execFetchAttachedIssues: any;
  execFetchCustomerById: any;
  execPauseTimer: typeof pauseServiceTimer;
  pauseTimerState: IActionState<IService>;
  pausedServiceId?: string;
  isIncoming?: boolean;
  setIncomingData?: (a: IService[] | null) => any;
  setPausedServiceId: React.Dispatch<React.SetStateAction<string | undefined>>;
  workTimeInSeconds: number;
}) => {
  const {
    service,
    disablePanel,
    execFetchService,
    execFetchAttachedIssues,
    execFetchCustomerById,
    execPauseTimer,
    pauseTimerState,
    pausedServiceId,
    setPausedServiceId,
    workTimeInSeconds,
    setIncomingData,
    isIncoming,
  } = props;
  const dispatch = useDispatch();

  const webphoneContext = React.useContext(WebphoneContext);
  const webphone = webphoneContext?.webphone;
  const webphoneState = webphoneContext?.state;

  const [color, setColor] = useState<string>();

  const customerState = useSelector((state: RootState) => state.customer);
  const currentService = useSelector((state: RootState) => state.service?.currentService);

  const [resumeTimerState, execResumeTimer]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(resumeServiceTimer, "customer-service");

  const [startedServiceState, execStartService]: readonly [
    IReduxActionState<any>,
    (serviceId: string, issueId?: string) => any
  ] = useReduxAction(startService, "customer-service");

  // stopwatchOffset =  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 300);

  // const result = stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 300);

  const serviceStopWatch = useStopwatch({
    autoStart: false,
    // offsetTimestamp : serviceState?.currentService ? serviceState.currentService.work_time_in_seconds : undefined
  });
  const { seconds, minutes, hours, start, pause, reset } = serviceStopWatch;

  let date,
    time = null;
  if (service.contact_date_time) {
    date = new Date(service.contact_date_time);

    time = date.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const toggleService = async (
    serviceId: string,
    status: ServiceStatusType,
    issueId?: string
  ) => {
    disablePanel();

    let res: IReduxActionState<IService> | undefined = undefined;
    dispatch({ type: CLEAR_ATTACHED_ISSUES, payload: {} });

    switch (status) {
      case "pending":
        let pauseRes: ActionResponse<any> | undefined = undefined;
        let pauseOk: boolean = true;
        if (currentService) {
          pauseRes = await execPauseTimer(currentService.id);
          pauseOk = pauseRes.status === 200 ? true : false;
        }

        if (!pauseOk) {
          return;
        }

        if (isIncoming) {
          // if(webphoneState === 'incoming'){
            webphone?.answer();
          // }
          
          if (setIncomingData) {
            setIncomingData(null);
          }
        }

        res = await execStartService(serviceId);
        if (res && res.data) {
          await execFetchCustomerById(res?.data?.customer_id);
        }

        if (res && res.data) {
          res.data.issue_ids.forEach((item) => {
            execFetchAttachedIssues(res?.data?.customer_id, item);
          });
        }
        break;
      case "open":
        let pauseOpenRes: ActionResponse<any> | undefined = undefined;
        let pauseOpenOk: boolean = true;
        if (currentService) {
          pauseOpenRes = await execPauseTimer(currentService.id);
          pauseOpenOk = pauseOpenRes.status === 200 ? true : false;
        }

        if (!pauseOpenOk) {
          return;
        }
        res = await execResumeTimer(serviceId);
        // res = await execFetchService(serviceId);
        if (res && res.data) {
          await execFetchCustomerById(res?.data?.customer_id);
        }

        if (res && res.data) {
          res.data.issue_ids.forEach(async (item) => {
            await execFetchAttachedIssues(res?.data?.customer_id, item);
          });
        }
        break;
      case "closed":
        let pauseCloseRes: ActionResponse<any> | undefined = undefined;
        let pauseCloseOk: boolean = true;
        if (currentService) {
          pauseCloseRes = await execPauseTimer(currentService.id);
          pauseCloseOk = pauseCloseRes.status === 200 ? true : false;
        }

        if (!pauseCloseOk) {
          return;
        }
        res = await execStartService(serviceId, issueId);
        // res = await execFetchService(serviceId);
        if (res && res.data) {
          await execFetchCustomerById(res?.data?.customer_id);
        }

        if (res && res.data) {
          res.data.issue_ids.forEach(async (item) => {
            await execFetchAttachedIssues(res?.data?.customer_id, item);
          });
        }
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (resumeTimerState.data) {
  //
  //     reset(resumeTimerState.data.work_time_in_seconds)
  //   }
  // }, [resumeTimerState.data]);

  useEffect(() => {
    if (service.status === "open") {
      // start();
    }
    const color = Math.floor(Math.random() * 16777215).toString(16);
    setColor(color);
  }, []);

  useEffect(() => {
    if (
      currentService &&
      currentService.id === service.id
    ) {
      let stopwatchOffset = new Date();
      if (currentService.work_time_in_seconds >= 0) {
        //@ts-ignore
        const variable = new Date(
          stopwatchOffset.getTime() +
            currentService.work_time_in_seconds
        );
        //@ts-ignore
        reset(
          stopwatchOffset.setSeconds(
            stopwatchOffset.getSeconds() +
              currentService.work_time_in_seconds
          )
        );
      }
    } else {
      let stopwatchOffset = new Date();
      if (workTimeInSeconds) {
        //@ts-ignore
        const variable = new Date(
          stopwatchOffset.getTime() + workTimeInSeconds
        );
        //@ts-ignore
        reset(
          stopwatchOffset.setSeconds(
            stopwatchOffset.getSeconds() + workTimeInSeconds
          )
        );
        //@ts-ignore
        pause();
      }
    }
  }, [currentService]);

  const dateMinus1 = new Date();
  dateMinus1.setDate(dateMinus1.getDate() - 1);

  // if(service.status == 'pending'){
  //   console.log('data sprawy: ',service.contact_date_time);
  // console.log('dzisiaj - 1dzień: ', dateMinus1)
  // }

  return (
    <li
      onClick={
        service.status === "open" || service.status === "pending"
          ? (e) => toggleService(service.id, service.status)
          : undefined
      }
      className={`${styles["service"]} ${
        currentService && currentService.id === service.id
          ? styles["service--active"]
          : ""
      }`}
    >
      <div
        className={`${styles["service__marker"]}`}
        style={{ backgroundColor: `${service.color}` }}
      ></div>
      <div className="flex-left w-100 relative">
        {service.status === "closed" && currentService?.id !== service.id && (
          <div className={`${styles["service__overlay"]}`}></div>
        )}

        <CustomerIcon
          channel={service.contact_type}
          color={service.color}
          name={service.customer_name}
        />
        <div>
          <div className="md-t dark t-700">{service.customer_name}</div>
          {service.status !== "closed" && (
            <div className="md-t dark">{service.issue_number}</div>
          )}

          {service.status !== "closed" && (
            <>
              <span className="md-con-t mr-5">{service.title} </span>{" "}
              <span className="md-con-t mr-5">
                {serviceTypeNames[service.contact_type]}{" "}
              </span>
            </>
          )}
        </div>
        <div className="grow-1 t-right">
          {service.status === "open" && (
            <span className="md-con-t no-wrap">
              <span className={minutes >= 10 ? "red" : ""}>
                {minutes < 10 && "0"}
                {minutes}:{seconds < 10 && "0"}
                {seconds}{" "}
              </span>{" "}
              / 10:00
              {/* <div>
              <ErrorOutlineIcon className="red" />
            </div> */}
            </span>
          )}
          {/* {service.status === "incoming" && (
          <span className="md-con-t">
            {minutes < 10 && "0"}
            {minutes}:{seconds < 10 && "0"}
            {seconds}
          </span>
        )} */}
          {service.status === "pending" && (
            <div>
              {new Date(service.contact_date_time) < dateMinus1 && (
                <>
                  <div className="md-con-t red t-500">
                    Opóźnienie
                    {/* <span className="md-con-t red">
                    {new Date().getMinutes() -
                      new Date(service.contact_date_time).getMinutes()}{" "}
                    min.
                  </span>{" "} */}
                  </div>
                </>
              )}
              {isIncoming && <CallIcon className={styles["incoming-icon"]} />}
              {/* <span className="md-con-t">{time}</span> */}
            </div>
          )}
        </div>
      </div>

      {service.status === "closed" && (
        <div className="mt-20">
          <ClosedIssues
            toggleService={(issueId: string) =>
              toggleService(service.id, service.status, issueId)
            }
            serviceId={service.id}
            issues={service.issues}
          />
        </div>
      )}
    </li>
  );
};

export default CustomerList;
