import React, { useState, useEffect, useRef, useMemo } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import SelectField from "components/form-fields/select-field-2";
import TextField from "components/form-fields/text-field";
import Calendar from "react-calendar";
import { ITeam, ITeamItem, IAgentItem } from "types/agent-types";
import TeamIcon from "components/common/team-icon";
import AgentIcon from "components/common/agent-icon";
import FormButton from "components/buttons/button";

import style from "./task-form.module.scss";
import { IActionState, IListing } from "types/async-types";
import { IIssueTemplate } from "types/issue-types";
import { useAsyncAction } from "utils/async-action";
import { fetchIssueTemplates } from "actions/issues";
import { fetchCustomers } from "actions/customer";
import { ICustomer } from "types/customer-types";
import { fetchCompanyTeams, fetchCompanies } from "actions/company-teams";
import { fetchAgents } from "actions/agents";
import Spinner from "components/common/spinner";
import { createService } from "actions/services";
import { formatDateTime, formatSubmitDate } from "utils/date";
import { renderOpts } from "utils/helpers";

interface ICustomersSearch {
  list: {
    id: string;
    name: string;
    ext_id: string;
  }[];
}

const TaskForm = (props: {
  mode: "new" | "assign" | "edit";
  onSubmit: (a: any) => any;
  taskIds?: string[];
  preselectedTeamId?: string;
}) => {
  const { mode, preselectedTeamId } = props;
  const [value, onChange] = useState(new Date());
  const [selectedTeam, setSelectedTeam] = useState<string>();
  const [selectedAgent, setSelectedAgent] = useState<string>();

  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const timoutRef = useRef();
  timoutRef.current = timeoutVar;

  const formRef = useRef<FormikProps<any>>(null);

  const [templatesState, execFetchTemplates]: readonly [
    IActionState<{ templates: IIssueTemplate[] }>,
    () => any
  ] = useAsyncAction(fetchIssueTemplates);

  const [companiesState, execFetchCompanies]: readonly [
    IActionState<any>,
    typeof fetchCompanies
  ] = useAsyncAction(fetchCompanies);

  const [customersState, execFetchCustomers]: readonly [
    IActionState<ICustomersSearch>,
    typeof fetchCustomers
  ] = useAsyncAction(fetchCustomers);

  const [agentsState, execFetchAgents]: readonly [
    IActionState<IListing<IAgentItem>>,
    typeof fetchAgents
  ] = useAsyncAction(fetchAgents);

  const [teamsState, execFetchTeams]: readonly [
    IActionState<IListing<ITeamItem>>,
    typeof fetchCompanyTeams
  ] = useAsyncAction(fetchCompanyTeams);

  useEffect(() => {
    if (mode === "edit") {
      // fetch form data here, then initialize
    } else {
    }
    execFetchTemplates();
    execFetchTeams();
    execFetchCustomers('')
    execFetchCompanies()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamsState.data && preselectedTeamId) {
      formRef.current?.setFieldValue("team_id", preselectedTeamId);
      execFetchAgents(preselectedTeamId);
    }
  }, [teamsState.data]);

  const renderTemplateOpts = () => {
    const templates = templatesState.data?.templates;

    if (templates) {
      return templates.map((item, index) => {
        return {
          label: item.title,
          value: item.uuid,
        };
      });
    }
  };

  const renderAgents = () => {
    const agents = agentsState.data?.list;
    if (agents) {
      return agents.map((item, index) => {
        return (
          <AgentIcon
            key={item.name + index}
            onClick={() =>
              formRef.current?.setFieldValue("team_user_id", item.id)
            }
            selectedAgent={formRef.current?.values["team_user_id"]}
            {...item}
          />
        );
      });
    }
  };

  const handleClientChange = (e: string) => {
    // formikProps.setFieldValue("search_term", e.target.value);

    // if (e.target. === "Enter") {
    //   e.preventDefault();
    // }
    
    // if (e !== "" && e.length < 3) {
    //   return;
    // }

    clearTimeout(timoutRef.current);
    setTimeoutVar(setTimeout(() => execFetchCustomers(e), 500));
  };

  let header = "";
  switch (mode) {
    case "new":
      header = "Dodaj zadanie";
      break;
    case "edit":
      header = "Edytuj zadanie";
      break;
    case "assign":
      header = "Przydziel zadanie";
      break;
    default:
      break;
  }

  const renderCustomerOpts = () => {
    if (customersState.data?.list) {
      return customersState.data?.list.map((item, index) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
  };

  const renderCompanyOpts = () => {
    
      return companiesState.data?.map((item : any, index: number) => {
        return {
          label: item.name,
          value: item.uuid,
        };
      });
    
  };

  const pickDate = (teamId: string, date: string) => {
    execFetchAgents(teamId, date);
  };

  const CalendarMemo = () => {
    const formikProps = useFormikContext<any>();
    const date = formikProps.values.date;
    const teamId = formikProps.values.team_id;
    const calendarMemo = useMemo(() => {
      let d = new Date();
      d.setMonth(d.getMonth() + 1, 1);
      const dt = new Date(d);

      return (
        <div className="row">
          <div className="col-sm-12">
            <Calendar
              className="mb-20"
              onChange={(val: Date) => {
                if (teamId) {
                  pickDate(teamId, formatSubmitDate(val.toDateString()));
                }

                formRef.current?.setFieldValue("date", val);
              }}
              value={date}
              minDate={new Date()}
              showDoubleView={true}
            />
          </div>
        </div>
      );
    }, [date, teamId]);
    return calendarMemo;
  };

  const Effects = () => {
    const formProps = useFormikContext<any>();

    useEffect(() => {
      
      if (formProps.values.date && formProps.values.team_id) {
        execFetchAgents(formProps.values.team_id, formProps.values.date);
      }
    }, [formProps.values.date]);

    return null;
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{ date: new Date() }}
        onSubmit={props.onSubmit}
      >
        {(formikprops: FormikProps<any>) => {
          
          const selectedDate = formikprops.values.date;
          const pickTeam = (teamId: string) => {
            formikprops.setFieldValue("team_id", teamId);

            execFetchAgents(teamId, formatSubmitDate(selectedDate));
          };

          const renderTeams = () => {
            // console.log('formRef current: ',formRef.current);

            const teams = teamsState.data?.list;
            if (teams) {
              return teams.map((item, index) => {
                return (
                  <TeamIcon
                    name={item.name}
                    id={item.id}
                    key={item.name + index}
                    onClick={() => pickTeam(item.id)}
                    selectedTeam={formRef.current?.values["team_id"]}
                    hex={item.color}
                  />
                );
              });
            }
          };

          return (
            <>
              {/* <Effects /> */}
              {(teamsState.loading ||
                agentsState.loading ||
                customersState.loading) && <Spinner />}

              <div className="sider-body">
                <header className="sider-header">
                  <h2 className="primary mb-15">{header}</h2>
                </header>

                {mode === "new" && (
                  <>
                    <h3 className="mb-15">Szczegóły zadania</h3>
                    <div className="mb-25">
                    <SelectField
                        name="company_id"
                        options={renderOpts(companiesState.data)}
                        label="Zleceniodawca"
                      />
                      <SelectField
                        name="issue_template_id"
                        options={renderTemplateOpts() || []}
                        label="Szablon"
                      />
                      <SelectField
                        name="customer_id"
                        onChange={(e) => {
                          handleClientChange(e);
                        }}
                        options={renderCustomerOpts() || []}
                        label="Klient"
                      />
                   
                      <TextField
                        name="note"
                        type="textarea"
                        label="Notatka lub dodatkowy opis"
                      />
                    </div>
                  </>
                )}

                <h3 className="mb-5">Wybierz datę</h3>
                <CalendarMemo />
                <h3 className="mb-15">Wybierz agenta lub zespół</h3>
                <div className={`${style["teams"]}`}>
                  <div className="md-con-t mb-5">
                    Dostępne zespoły: {teamsState.data?.list.length}
                  </div>
                  <div>{renderTeams()}</div>
                </div>
                <div className={`${style["agents"]} mb-30`}>
                  <div className="md-con-t mb-5">
                    Dostępni agenci: {agentsState.data?.list.length}
                  </div>
                  {renderAgents()}
                </div>
              </div>
              <div className="sider-footer">
                <FormButton
                  inline={true}
                  title={mode === "new" ? "Dodaj zadanie" : "Przydziel zadanie"}
                  color="blue-color"
                  onClick={formikprops.submitForm}
                  className="f-right sm"
                />
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default TaskForm;
