import React, { useState, useEffect } from "react";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import Spinner from 'components/common/spinner';
import style from "./work-panel.module.scss";
import { IReduxActionState } from "types/async-types";
import { useReduxAction } from "utils/async-action";
import { setBreakTime } from "actions/user";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export default function WorkPanel() {
  const [startStatus, setstartStatus] = useState<"start" | "end">("end");
  const user = useSelector((state: RootState) => state.user);
  const status = user.session?.webphone_status;

  const [breakState, execSetBreakTime]: readonly [
    IReduxActionState<any>,
    (a : 'start' | 'end') => any
  ] = useReduxAction(setBreakTime, "user");

  const setStatus = (a : 'start' | 'end') => {

    execSetBreakTime(a);
    setstartStatus(a);
  }

  useEffect(() => {
    
    setstartStatus(status === 'On Break' ? 'start' : 'end')
  }, [status])

  return (
    <div className={`${style["work-status"]} flex-left`}>
      {breakState.loading &&
        <Spinner size="small" />
      }
      <div className="grow-1">
          {startStatus === 'end' && 
          <div className="lg-t green">Pracujesz...</div>
          }
            {startStatus === 'start' && 
          <div className="lg-t orange">Przerwa</div>
          }
        
        {/* <span className={`t-right black mr-5`}>
    {hours}:{minutes < 10 && "0"}
    {minutes}:{seconds < 10 && "0"}
    {seconds}
  </span> */}
      </div>
      <div className={`play-icons`}>
        {startStatus === "start" && (
          <>
            <PlayCircleFilledIcon
              onClick={() => setStatus("end")}
              className="green"
            />
            <PauseCircleOutlineIcon
              // onClick={() => setStatus("end")}
              className="grey"
            />
          </>
        )}
        {startStatus === "end" && (
          <>
            <PlayCircleOutlineIcon
              
              className="grey"
            />
            <PauseCircleFilledIcon
              onClick={() => setStatus("start")}
              className="orange"
            />
          </>
        )}
      </div>
    </div>
  );
}
