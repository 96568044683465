import { combineReducers } from 'redux';

import userReducer from 'redux/reducers/user-reducer';
import loaderReducer from 'redux/reducers/loader-reducer';
import customerReducer from 'redux/reducers/customer-reducer';
import serviceReducer from 'redux/reducers/service-reducer';

// import { reducer as form } from 'redux-form'

import asyncReducer from 'redux/reducers/async-reducer'

const rootReducer = combineReducers({
  
  // form: form,
  customer : customerReducer,
  user: userReducer,
  loader : loaderReducer,
  async: asyncReducer,
  service: serviceReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
