import React, { useState, useEffect } from "react";
import {
  syncAttachedIssues,
  setStepState,
  finishIssue,
  terminateIssue,
  submitStep,
} from "actions/issues";
import {
  IIssueStep,
  StepActionType,
  IStepComponent,
  IIssue,
} from "types/issue-types";
import { FormikProps, Formik } from "formik";
import DedicatedComponent from "components/issues/dedicated-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { compareComponents } from "utils/helpers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import style from "./cases.module.scss";
import { IStepParams } from "./issue-details";
import { ActionResponse } from "types/async-types";
import { RadioButtonUncheckedOutlined } from "@material-ui/icons";

const Step = (props: {
  issue: IIssue;
  isActive: boolean;
  step: IIssueStep;
  execSetStepState: typeof setStepState;
  execSubmitStep: typeof submitStep;
  execFinishIssue: typeof finishIssue;
  execTerminateIssue: typeof terminateIssue;
  execSyncAttachedIssues: (a: string, a2: string) => Promise<any>;
  reachedStep: number;
  index: number;
  activeStep: number;
  setReachedStep: any;
  setIssueStatus: React.Dispatch<
    React.SetStateAction<"terminated" | "closed" | "open" | "omitted" | undefined>
  >;
  setActiveStep: any;
  setTerminateModalVisible: any;
  setTerminateProps: any;
  nextStep: any;
  issueStatus: "terminated" | "closed" | "open" | "omitted" | undefined;
  openStep: any;
}) => {
  const {
    step,
    isActive,
    reachedStep,
    index,
    setIssueStatus,
    issue,
    execSubmitStep,
    execFinishIssue,
    execTerminateIssue,
    execSyncAttachedIssues,
    execSetStepState,
    setActiveStep,
    activeStep,
    setReachedStep,
    setTerminateModalVisible,
    setTerminateProps,
    nextStep,
    issueStatus,
    openStep,
  } = props;

  const [stepParams, setStepParams] = useState<IStepParams>();

  const customer = useSelector((state: RootState) => state.customer);

  if (index > reachedStep) {
    //steps after finished step are not rendered
    return <div key={index + "step"}></div>;
  }

  const stepAction = async (
    actionType: StepActionType,
    formikProps: FormikProps<any>,
    config?: {
      no_validation?: boolean;
      customer_id?: string;
    }
  ) => {
    const stepParams = {
      no_validation: config?.no_validation,
      customer_id: config?.customer_id || customer?.customer_uuid,
      actionType: actionType,
    };

    setStepParams(stepParams); //to have access to stepParams state in formik native submit
    setIssueStatus("open");

    //check if user want's to call submit function directly or via Formik (direct call ignores validation, so form relies only on disabling/enabling button)
    if (
      config?.no_validation ||
      actionType === "terminate" ||
      actionType === "save"
    ) {
      await submitStepForm(formikProps.values, stepParams);
    } else {
      //if form is invalid, function won't execute. Should be used for next_step and finish
      await formikProps.submitForm();
    }
    return "ok";
  };

  const prepareComponents = (values: any, components: IStepComponent[]) => {
    return components.map((item, index) => {
      let componentValue = values[item.name];
      return {
        ...item,
        value: componentValue,
      };
    });
  };

  const submitStepForm = async (values: any, params?: IStepParams) => {
    const stepId = step.uuid;
    const actionType = params?.actionType;
    const customerId = params?.customer_id;

    if (customerId) {
      //we have to set updated components by values submitted in step Formik instance
      const updatedComponents: IStepComponent[] = prepareComponents(
        values,
        step.components
      );

      switch (actionType) {
        case "save": {
          await execSubmitStep(
            updatedComponents,
            customerId,
            issue.uuid,
            stepId,
            null
          );

          await execSyncAttachedIssues(customerId, issue.uuid);
          break;
        }
        case "finish": {
          const accepted = window.confirm("Potwierdź zakończenie sprawy");
          if (accepted) {
            await execSubmitStep(
              updatedComponents,
              customerId,
              issue.uuid,
              stepId,
              "completed"
            );

            setActiveStep(50);
            setIssueStatus("closed");
            // setReachedStep(activeStep + 1);
            await execFinishIssue(customerId, issue.uuid);
            await execSyncAttachedIssues(customerId, issue.uuid);
          }

          break;
        }
        case "terminate": {
          setTerminateModalVisible(true);
          setTerminateProps({
            setIssueStatus: setIssueStatus,
            syncIssueFn: async () =>
              await execSyncAttachedIssues(customerId, issue.uuid),
            submitFn: async (terminateValue: string) =>
              await execSubmitStep(
                updatedComponents,
                customerId,
                issue.uuid,
                stepId,
                "completed",
                terminateValue
              ),
            terminateFn: async () =>
              await execTerminateIssue(customerId, issue.uuid),
            step,
          });

          break;
        }
        case "next_step": {
          const next = activeStep + 1;
          setActiveStep(next);
          setReachedStep(next);

          await execSubmitStep(
            updatedComponents,
            customerId,
            issue.uuid,
            stepId,
            "completed"
          );

          const nextStepItem = issue.steps.find((item) => {
            return item.position === step.position + 1;
          });

          if (nextStepItem) {
            await execSetStepState(
              "start",
              customerId,
              issue.uuid,
              nextStepItem.uuid
            );
          }
          await execSyncAttachedIssues(customerId, issue.uuid);
          break;
        }
        case "skip_step": {
          const accepted = window.confirm("Potwierdź pominięcie kroku");
          if (accepted) {
            const next = activeStep + 1;
            setActiveStep(next);
            setReachedStep(next);

            await execSubmitStep(
              updatedComponents,
              customerId,
              issue.uuid,
              stepId,
              "omitted"
            );

            const nextStepItem = issue.steps.find((item) => {
              return item.position === step.position + 1;
            });

            if (nextStepItem) {
              await execSetStepState(
                "start",
                customerId,
                issue.uuid,
                nextStepItem.uuid
              );
            }
            await execSyncAttachedIssues(customerId, issue.uuid);
          }
          break;
        }
      }
    }
  };

  return (
    <Formik
      key={step.uuid}
      enableReinitialize={true}
      isInitialValid={false}
      initialValues={{}}
      onSubmit={(values: any) => submitStepForm(values, stepParams)}
    >
      {(formikProps: FormikProps<any>) => {
        const renderComponents = (step: IIssueStep) => {
          const sortedComponents = step.components.sort(compareComponents);
          return sortedComponents.map((item, index) => {
            return (
              <DedicatedComponent
                key={item.content_key + "_dedicated_" + index}
                props={item}
                nextStep={() => nextStep(step.uuid)}
                stepAction={stepAction}
                issue={issue}
                step={step}
              />
            );
          });
        };

        return (
          <div key={issue.uuid + "_step"}>
            <div className={` ${style["case-steps"]}`}>
              <div
                className={`flex ${style["step"]}  ${
                  !props.isActive ? style["unactive"] : ""
                }`}
                onClick={!props.isActive ? () => openStep(index) : undefined}
              >
                <div className={`${style["count"]} flex-top-left`}>
                  <span className="mr-5"> {step.position}. </span>{" "}
                  {
                    step.status === "omitted" ? (
                      <RadioButtonUncheckedOutlined />
                    ) 
                    : issue.status === "closed" ||
                      issue.status === "terminated" ||
                      issueStatus === "closed" ||
                      issueStatus === "terminated" ||
                      index < reachedStep ? (
                        <CheckCircleOutlineIcon className="green" />
                      )
                    : 
                    (
                      <img src="/assets/images/progress-check.svg" alt="check" />
                    )
                  }
                </div>
                <div className={` ${style["content"]}`}>
                  <h3>{step.title}</h3>
                  <div className={` ${style["components"]}`}>
                    {renderComponents(step)}
                  </div>
                  {step.terminate_value && (
                    <div className={` ${style["terminate-reason"]}`}>
                      <div className="sm-l-t mb-5">
                        Powód zakończenia sprawy:{" "}
                      </div>
                      <p className="md-t t-500 black">{step.terminate_value}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Step;
