import React, { useState, useEffect } from "react";
import TextField from "components/form-fields/text-field";
import style from "./cases.module.scss";
import CaseButton from "components/buttons/case-button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Formik, FormikProps } from "formik";
import Step from './issue-step';
import {
  IIssueTemplate,
  IIssueSubmit,
  IIssueStep,
  IStepComponent,
  IIssue,
  StepActionType,
} from "types/issue-types";
import { IActionState, IReduxActionState, ActionResponse } from "types/async-types";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import {
  fetchIssueTemplates,
  addIssue,
  setStepState,
  submitStep,
  finishIssue,
  terminateIssue,
  syncAttachedIssues,
} from "actions/issues";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { string, number } from "yup/lib/locale";
import Spinner from "components/common/spinner";
import DedicatedComponent from "components/issues/dedicated-component";
import { exec } from "child_process";
import TerminateModal from "./terminate-modal";
import { compareSteps, compareComponents } from "utils/helpers";
import { useIssueHelper } from "./issueHelper";

export type StepAction = (actionType: StepActionType, formikProps: FormikProps<any>, config?: {
  no_validation?: boolean | undefined;
  customer_id?: string | undefined;
} | undefined) => Promise<any>

export type IStepParams = {
  no_validation? : boolean;
  customer_id? : string;
  actionType :  "next_step" | "finish" | "save" | "terminate" | "skip_step";
}






const CaseSteps = (props: { issue: IIssue }) => {
  const { issue } = props;

  // const issueHelper = useIssueHelper(issue);
  
  // const [steps, setStep] = useState(1);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [terminateModalVisible, setTerminateModalVisible] = useState(false);
  const [terminateProps, setTerminateProps] = useState<{
    setIssueStatus: (a: "terminated" | "closed") => any;
    syncIssueFn: () => any;
    terminateFn: () => any;
    submitFn: (terminateValue: string) => any;
    step: IIssueStep;
  }>();

  const [reachedStep, setReachedStep] = useState(0);
  const [issueStatus, setIssueStatus] = useState<
    "terminated" | "closed" | "open" | "omitted"
  >();



  const customer = useSelector((state: RootState) => state.customer);

  const [stepState, execSetStepState]: readonly [
    IActionState<any>,
    typeof setStepState
  ] = useAsyncAction(setStepState);

  const [submitStepState, execSubmitStep]: readonly [
    IActionState<any>,
    typeof submitStep
  ] = useAsyncAction(submitStep);

  const [finishIssueState, execFinishIssue]: readonly [
    IActionState<any>,
    typeof finishIssue
  ] = useAsyncAction(finishIssue);

  const [terminateState, execTerminateIssue]: readonly [
    IActionState<any>,
    typeof terminateIssue
  ] = useAsyncAction(terminateIssue);

  const [syncIssueState, execSyncAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(syncAttachedIssues, "attached-issues");

  useEffect(() => {
    const completedStep = issue.steps.find((item) => {
      return item.status === "completed";
    });
    const current_step = issue.current_step;
    if (
      current_step &&
      (issue.status === "closed" || issue.status === "terminated")
    ) {
      setReachedStep(current_step - 1);
      setActiveStep(current_step - 1);
    } else if (current_step && issue.status === "open") {
      setReachedStep(current_step - 1);
      setActiveStep(current_step - 1);
    }
  }, []);

  const nextStep = async (stepId: string) => {
    const next = activeStep + 1;
    // setStep(next);
    setActiveStep(next);
    setReachedStep(next);
    const currentStep = issue.steps.find((item) => {
      if (item.uuid === stepId) {
        return true;
      } else {
        return false;
      }
    });
    if (currentStep) {
      const nextStepItem = issue.steps.find((item) => {
        return item.position === currentStep?.position + 1;
      });
      if (customer) {
        await execSetStepState(
          "complete",
          customer?.customer_uuid,
          issue.uuid,
          stepId
        );
        if (nextStepItem) {
          await execSetStepState(
            "start",
            customer?.customer_uuid,
            issue.uuid,
            nextStepItem.uuid
          );
        }
      }
    }
  };

  const openStep = (stepIndex: number) => {
    // alert(`clicked index: ${stepIndex}`);
    setActiveStep(stepIndex);
  };

  const renderSteps = () => {
    let sorted = issue.steps.sort(compareSteps);
    let result = sorted.map((item, index) => {
      const props = {

        step: item,
        isActive: activeStep === index ? true : false,
        index,
        issue,
        execSetStepState,
        execSyncAttachedIssues,
        execSubmitStep,
        activeStep,
        reachedStep,
        execFinishIssue,
        execTerminateIssue,
        setActiveStep,
        setTerminateModalVisible,
        setIssueStatus,
        setTerminateProps,
        setReachedStep,
        nextStep,
        issueStatus,
        openStep,

      };

      
      // const [stepConfig, setStepConfig] = useState<StepConfig>()

      return <Step {...props}  />
    });

    return result.reverse();
  };

  return (
    <div>
      {(syncIssueState.loading ||
        submitStepState.loading ||
        stepState.loading ||
        finishIssueState.loading ||
        terminateState.loading) && <Spinner position="absolute" />}

      <TerminateModal
        terminateProps={terminateProps}
        show={terminateModalVisible}
        onHide={() => setTerminateModalVisible(false)}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setReachedStep={setReachedStep}
        issue={issue}
      />
      <div className={` ${style["case-breadcrumb"]}`}>
        <span> {issue && issue.title.replace(">", "  ›  ")} </span>
      </div>
      {renderSteps()}
    </div>
  );
};

export default CaseSteps;

// const Step = (props: {
//   number: number;
//   isActive: boolean;
//   step: IIssueStep;
// }) => {
//   const { step, isActive, number } = props;
//   if (number > activeStep) {
//     return <div></div>;
//   }
//   // if (props.isActive) {
//   return (
//     <div className={` ${style["case-steps"]}`}>
//       {issueState.loading && <Spinner size="default" />}
//       {props.isActive && (
//         <div className={` ${style["case-breadcrumb"]}`}>
//           <span> {template && template.title.replace(">", "  ›  ")} </span>
//           {/* <span>
//            {template?.title}
//         </span> */}
//         </div>
//       )}

//       <div
//         className={`flex ${style["step"]}  ${
//           !props.isActive ? style["unactive"] : ""
//         }`}
//       >
//         <div className={`${style["count"]} flex-top-left`}>
//           <span className="mr-5"> {props.number}. </span>{" "}
//           <img src="/assets/images/progress-check.svg" />
//         </div>
//         <div className={` ${style["content"]}`}>
//           <h3>{step.title}</h3>
//           {props.isActive && renderComponents(step.components, step.uuid)}
//         </div>
//       </div>
//     </div>
//   );
//   // }
//   // return (
//   //   <div className={` ${style["case-steps"]}`}>
//   //     <div className={`flex ${style["step"]} ${style["unactive"]}`}>
//   //       <div className={`${style["count"]} flex-top-left`}>
//   //         <span className="mr-5"> {props.number}. </span>{" "}
//   //         <CheckCircleOutlineIcon className="green" />
//   //       </div>
//   //       <div className={` ${style["content"]}`}>
//   //         <h3>Notatka</h3>
//   //         {/* <p className={` ${style["text-tip"]}`}>„ OK, widzę że nie ma na pana koncie żadnych zaległości. Czy miałby pan minutkę na przejście ze mną 3 kroków aby sprawidzic czy pana sprzed działa dobrze?”</p> */}
//   //       </div>
//   //     </div>
//   //   </div>
//   // );
// };
