import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, Column, usePagination } from "react-table";
import { IUnassignedTask } from "types/task-types";
import { IList, IListing } from "types/async-types";

interface IProps {
  data: IListing<any> | null;
  columns: Column<any>[];
  fetchData?: (...a: any) => any;
  hidePagination?: boolean;
}

function Table(props: IProps) {
  const { data, columns, fetchData, hidePagination } = props;
  const [pageCount, setPageCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState(0);

  

  useEffect(() => {
    if (data) {
      setPageCount(data.total_pages);
    }
  }, [data]);

  const tableData = useMemo(() => {
    return data?.list ? data.list : [{}];
  }, [data?.list]);

  // const initialState = useMemo(() => ({ pageIndex: 0 }), []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    pageOptions,
    gotoPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    previousPage,
    
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: pageCount,
      
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (fetchData && !hidePagination) {
      fetchData(currentPage + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  const renderPages = () => {
    console.log('page index: ',pageIndex);
    return Array(pageOptions.length)
      .fill(0)
      .map((_, i) => {
        return (
          <li
            onClick={() => setCurrentPage(i)}
            className={`page-item ${currentPage === i ? "active" : ""}`}
          >
            <div className="page-link">{i + 1}</div>
          </li>
        );
      });
  };

  return (
    <div>
      <table {...getTableProps()} className="admin-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props

                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            console.log("row render");
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: (cell.column as any).className,
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!hidePagination && (
        <div className="pagination-container">
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 0 ? true : false}
                className="page-link"
              >
                Poprzednia
              </button>
            </li>
            {renderPages()}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={(data && (currentPage === (data.total_pages - 1))) ? true : false}
                className="page-link"
              >
                Następna
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Strona{" "}
          <strong>
            {pageIndex + 1} z {pageOptions.length}
          </strong>{" "}
        </span> */}
    </div>
  );
}

// function App() {
// //   const columns = React.useMemo(
// //     () => [
// //       {
// //         Header: "First Name",
// //         accessor: "firstName",
// //       },
// //       {
// //         Header: "Last Name",
// //         accessor: "lastName",
// //       },
// //     ],
// //     []
// //   );

// //   const data = React.useMemo(() => makeData, []);

//   return <Table columns={columns} data={data} />;
// }

export default Table;
