import React from "react";
import style from './form-group.module.scss';

const ErrorMsg = (msg: string) => (
  <span data-testid="text-field-error" className="input-error">
    {msg}{" "}
  </span>
);

export interface IFormGroupProps {
   children : JSX.Element,
   className? : string
}

// function disabledDate(current: any) {
//   // Can not select days before today and today
//   return current && current < moment().date(1900);
// }

function TextField(props: IFormGroupProps) {
//   const {
//     disabled,
//     label,
//     size,
//     name,
//     search,
//     clearVisible,
//     clearSearch,
//     testId,
//     required,
//     validate,
//     placeholder,
//     rules,
//     formMethods
//   } = props;

  return (
    <div className={`${style['form-group']} row gx-0 ${props.className || ""}`}>
        <div className={`${style['form-group__label']} col-sm-3`}>{props.children.props.label}</div>
        <div className={`${style['form-group__input']} col-sm-9`}>{props.children}</div>
    </div>
  );
}

export default TextField;
