import React, { useEffect, useState } from "react";

import RoomIcon from "@material-ui/icons/Room";
import style from "./header.module.scss";
import { IActionState, IReduxActionState } from "types/async-types";
import { logout } from "utils/auth";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { fetchCustomer } from "actions/customer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/reducers";
import Spinner from "components/common/spinner";
import { useStopwatch } from "react-timer-hook";
import Button from "components/buttons/button";
// import { init, IInitConfig } from "utils/webphone-service";
import CustomerIcon from "components/common/customer-icon";
import { endService, verify } from "actions/services";
import { AddressModal, ContactModal } from "components/common/address-modal";
import { WebphoneContext } from "components/dashboard";
import { IStartCallRes } from "types/issue-types";
import { endCall, startCall } from "actions/issues";
import CallIcon from "@material-ui/icons/Call";
import { WebphoneStatus } from "types/webphone-types";

const Header = (props: { execEndService: () => any }) => {
  const { execEndService } = props;
  const { seconds, minutes, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const webphoneContext = React.useContext(WebphoneContext);
  const webphone = webphoneContext?.webphone;
  const webphoneStatus = webphoneContext?.state;
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const dispatch = useDispatch();

  // const [webphone, setWebPhone] = useState<any>(null);

  const [makeCallActive, setMakeCallActive] = useState(false);
  const [incomingCallModalVisible, setIncomingCallModalVisible] = useState(
    false
  );

  const [callEnded, setCallEnded] = useState(false);

  const [logoutState, execLogout]: readonly [
    IActionState<any>,
    typeof logout
  ] = useAsyncAction(logout);

  const [customerState, execFetchCustomer]: readonly [
    IReduxActionState<any>,
    (phone?: string) => any
  ] = useReduxAction(fetchCustomer, "customer");

  // const [endServiceState, execEndService]: readonly [
  //   IReduxActionState<any>,
  //   (serviceId: string) => any
  // ] = useReduxAction(endService, "customer-service");

  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  // const config = useSelector((state: RootState) => state.user.session?.config);
  // const user = useSelector((state: RootState) => state.user);
  
  // const credentials = useSelector(
  //   (state: RootState) => state.user.session?.webphone
  // );

  const renderTags = () => {
    if (customer?.customer_tags) {
      return customer.customer_tags.map((item, index) => {
        return <span className={`${style["badge"]}`}>{item}</span>;
      });
    }
  };

  useEffect(() => {
    
    if(webphoneStatus === 'ready'){
      reset(undefined, false);
    }
    if (webphoneStatus === "call") {
      start();
    }
  }, [webphoneStatus]);

  // useEffect(() => {
  //   if (customer?.call_issue_id && customer?.call_issue_id !== null) {
  //     start();
  //   }
  // }, [customer?.call_issue_id]);

  // const answerPhone = () => {
  //   setMakeCallActive(true);
  //   start();
  //   webphone.answer();
  //   setIncomingCallModalVisible(false);

  //   const caller = webphone.getCallerNumber();

  //   const phone = caller
  //     .replace("sip:", "")
  //     .replace("@46.242.131.202", "")
  //     .replaceAll('"', "");

  //   execFetchCustomer(phone);
  // };

  const dismissCall = () => {
    const states : WebphoneStatus[] = ['incoming', 'call', 'calling'];
    if (!states.includes(webphoneStatus || 'ready')) {
      return;
    }

    try {
      webphone?.hangup();
    } catch (e) {
      console.log("error z dismissa: ", webphone?.getState());
    }

    

    setIncomingCallModalVisible(false);
    reset(undefined, false);
    pause();
    if (customer) {
      execDismissCall(customer.customer_uuid, service?.currentService?.issue_id || '', service?.currentService?.contact_id);
    }
  };

  // const logOut = () => {
  //   if (user.fetch_agents_timer) {
  //     clearTimeout(user.fetch_agents_timer);
  //   }
  //   execLogout();
  // };

  useEffect(() => {
    // execFetchCustomer();
  }, []);

  // useEffect(() => {
  //   if (config && credentials) {
  //     const initConfig: IInitConfig = {
  //       config: config,
  //       credentials: credentials,
  //       setCallerModalVisible: setIncomingCallModalVisible,
  //     };

  //     const webphone = init(initConfig);
  //     webphone.setAutoAnswer(false);
  //     setWebPhone(webphone);
  //   }
  // }, [config]);

  // if((async.isLoading && async.property === "customer") ||
  // customerState.loading) {
  //   return <Spinner size="large" position="relative" />
  // }
  
  const [callState, execStartCall]: readonly [
    IReduxActionState<IStartCallRes>,
     (customer_id: string, issue_id: string, contact_id?: string) => any
  ] = useReduxAction(startCall, 'attached-issues');
  
  const [endCallState, execDismissCall]: readonly [
    IReduxActionState<IStartCallRes>,
     (customer_id: string, issue_id: string, contact_id?: string) => any
  ] = useReduxAction(endCall, 'attached-issues');

  const call = () => {
    if (customer) {
      execStartCall(customer.customer_uuid, service?.currentService?.issue_id || '', service?.currentService?.contact_id);
    }
  
  };

  const performCall = async () => {
    const states : WebphoneStatus[] = ['incoming', 'call', 'calling'];
    if (callState.data && webphone && !states.includes(webphoneStatus || 'ready')) {
      webphone.call(callState.data.call_param);
    }
  }

  useEffect(() => {
    performCall();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState.data]);

  function showSection(name: string): boolean {
    switch(name){
      case "close_service": {
        const states : WebphoneStatus[] = ['error', 'ready', 'registered'];
        return states.includes(webphoneStatus || 'ready');
      }
      case "calling_panel": {
        const states : WebphoneStatus[] = ['answering', 'calling'];
        return states.includes(webphoneStatus || 'ready');
      }
      case "call_panel": {
        const states : WebphoneStatus[] = ['call', 'answering', 'calling'];
        return states.includes(webphoneStatus || 'ready');
      }
      
      default: return false;
    }
  }

  return (
    <>
      <AddressModal
        show={addressModalOpen}
        onHide={() => setAddressModalOpen(false)}
      />

      <ContactModal
        show={contactModalOpen}
        onHide={() => setContactModalOpen(false)}
      />
      <div className="container px-0">
        <div className={`row gx-0  ${style["header"]}`}>
          {customer && (
            <div
              className={`col-md-4 px-0 ${style["user-info-wrapper"]} ${style["header-section"]}`}
            >
              <CustomerIcon
                color={service?.currentService?.color}
                name={customer.company_name || customer.name}
              />
              <div className={`${style["user-info"]} mr-5`}>
                {customer !== null ? (
                  <div className="mb-5">
                    {(customer.company_name || customer.name) && (
                      <>
                        <h2 className={`black inline`}>
                          {customer.company_name}
                        </h2>
                        <small>{customer.name + ' ' + customer.surname}</small>
                        {renderTags()}
                      </>
                    )}
                  </div>
                ) : (
                  <div>- - -</div>
                )}
                {customer !== null && (
                  <>
                    <div className="md-t flex-left condensed ">
                      {/* <ErrorOutlineIcon className="red mr-5" /> */}
                      <span>
                        <span className="mr-5 dark">Bieżące zadłużenie: </span>

                        <span className="red mr-5">
                          {" "}
                          {customer.ballance
                            // .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " zł"}
                        </span>

                        {/* <a className="link mr-5">Wznów windykację</a> */}
                        {/* <span className="dark">
                    <RoomIcon className="marker" /> {customer.addresses[0].city}
                    , {customer.addresses[0].voivodeship}
                  </span> */}
                      </span>
                    </div>
                    <div className="md-t condensed">
                      <span className=" dark">{customer?.notes} </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div
            className={`col-md-8 px-0 ${style["header-section"]} ${style["data-section"]}`}
          >
            <div className={`px-0 ${style["header-buttons"]} grow-1`}>
              <Button
                onClick={() => setAddressModalOpen(true)}
                width={170}
                color="transparent"
                title="Dane adresowe"
                className="mr-5 i-b"
              />
              <Button
                onClick={() => setContactModalOpen(true)}
                width={190}
                color="transparent"
                title="Dane kontaktowe"
                className="mr-5 i-b"
              />
            </div>
            {customer && (
              <div className={`flex-center`}>
                {service?.currentService?.contact_type === "phone" &&
                  showSection('call') && (
                    <div
                      className={`flex-center lg-con-t ${style["phone-call-status"]}`}
                      data-time="0"
                      id="phone_time"
                    >
                      <img
                        className={`${style["incoming"]} green`}
                        alt="phone arrow"
                        src="/assets/images/phone-outgoing.svg"
                      />
                      <div>
                        <div className="green t-500 roboto">
                          Trwa połączenie
                        </div>
                        <span className={`${style["counter"]} black`}>
                          {minutes < 10 && "0"}
                          {minutes}:{seconds < 10 && "0"}
                          {seconds}
                        </span>
                      </div>
                    </div>
                  )}
                {showSection('calling_panel') && (
                  <div
                    className={`flex-center lg-con-t ${style["phone-call-status"]}`}
                    data-time="0"
                    id="phone_time"
                  >
                    <img
                      className={`${style["incoming"]} green`}
                      alt="phone arrow"
                      src="/assets/images/phone-outgoing.svg"
                    />
                    <div>
                      <div className="green t-500 roboto">Trwa łączenie</div>
                      {/* <span className={`${style["counter"]} black`}>
                          {minutes < 10 && "0"}
                          {minutes}:{seconds < 10 && "0"}
                          {seconds}
                        </span> */}
                    </div>
                  </div>
                )}
                {service?.currentService?.contact_type === "phone" && (
                  <>
                  <div className={`${style["icons"]}`}>
                    {showSection('call_panel') && (
                      <>
                        <div>
                          <img
                            alt="phone-paused"
                            onClick={(e: any) => pause()}
                            src="/assets/images/phone-paused.svg"
                          />
                        </div>
                        <div>
                          <img
                            onClick={(e: any) => start()}
                            alt="phone-forward"
                            src="/assets/images/phone-forward.svg"
                          />
                        </div>
                        <div>
                          <img alt="dialpad" src="/assets/images/dialpad.svg" />
                        </div>
                        <div>
                          <img
                            alt="microphone-off"
                            src="/assets/images/microphone-off.svg"
                          />
                        </div>
                        <div className={``}>
                          <img
                            className={`${style["dismiss"]}`}
                            alt="dismiss-call"
                            onClick={dismissCall}
                            id="dismiss_call"
                            src="/assets/images/phone-hangup.svg"
                          />
                        </div>
                      </>
                    )}

                    {showSection('close_service') && (
                      <div style={ {width: '100%'} } className="h-auto">
                        <CallIcon onClick={() => call()} className={`call-icon inline mr-5`} />
                        <Button
                          onClick={execEndService}
                          title="Zakończ obsługę"
                          color="green-button"
                          className="inline"
                          Icon={() => (
                            <img
                              src="/assets/images/account-check.svg"
                              alt="account check"
                            />
                          )}
                          // className="close-btn"
                        />
                      </div>
                    )}
                  </div>
                  </>
                )}
              </div>
            )}
          </div>
          {/* <div
        className={`col-md-2 ${style["header-section"]} ${style["data-section"]}`}
      >
        <MailOutlineIcon className="mr-10 light2" />
        <div>
          {customer !== null ? (
            <>
              {" "}
              <div>
                {customer.addresses[0].street}{" "}
                {customer.addresses[0].building_number}{" "}
                {customer.addresses[0].locum_number &&
                  `/ ${customer.addresses[0].locum_number}`}{" "}
              </div>{" "}
              <div>
                {customer.addresses[0].postal_code} {customer.addresses[0].city}{" "}
              </div>{" "}
            </>
          ) : (
            <div>- - -</div>
          )}
        </div>{" "}
      </div>
      <div
        className={`col-md-2 ${style["header-section"]} ${style["data-section"]}`}
      >
        <HomeIcon className="mr-10 light2" />
        {customer !== null ? (
          <div>
            <div>
              {customer.addresses[1].street}{" "}
              {customer.addresses[1].building_number}{" "}
              {customer.addresses[1].locum_number &&
                `/ ${customer.addresses[1].locum_number}`}{" "}
            </div>{" "}
            <div>
              {customer.addresses[1].postal_code} {customer.addresses[1].city}{" "}
            </div>
          </div>
        ) : (
          <div>- - -</div>
        )}
      </div>
      <div
        className={`col-md-2 ${style["header-section"]} ${style["data-section"]}`}
      >
        {" "}
        <img src="assets/images/cellphone-message.svg" className="mr-5" />
        {customer !== null ? (
          <>
            <div>
              <div>{customer.contact.phone_number} </div>{" "}
              <div>{customer.contact.email} </div>
            </div>
          </>
        ) : (
          <div>- - -</div>
        )}
      </div> */}

          {/* <div
        className={`col-md-2  ${style["header-section"]} ${style["data-section"]}`}
      >
        <div className="t-right i-b" onClick={logOut}>
          <span className="link md-t">Wyloguj</span>
        </div>
      </div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
