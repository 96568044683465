import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IStepComponent,
  StepActionType,
  IIssueStep,
  IIssue,
} from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useFormikContext, FormikProps } from "formik";
import CaseButton from "components/buttons/case-button";

import CallIcon from "@material-ui/icons/Call";
import style from "components/modal/incoming-call-modal.module.scss";
import { IActionState } from "types/async-types";
import { setRecording, generatePDF } from "actions/issues";
import { useAsyncAction } from "utils/async-action";
import Button from "components/buttons/button";
import Spinner from "components/common/spinner";
import { formatDateTime } from "utils/date";

export interface IPDFFormValues {
  installments_counts: string;
  installments_type: string;
  installments_first_date: string;
  pdf_type : "statement_summary";
}

const InitializeCall = ({
  component,
  nextStep,
  stepAction,
  issue,
  step,
}: {
  component: IStepComponent;
  nextStep: () => void;
  stepAction: (
    actionType: StepActionType,
    formikProps: FormikProps<any>
  ) => any;
  step: IIssueStep;
  issue: IIssue;
}) => {
  const [dateTime, setDateTime] = useState<Date | undefined>();
  const [isCalling, setIsCalling] = useState(false);
  const customer = useSelector((state: RootState) => state.customer);
  const formikProps = useFormikContext<any>();
  const namePrefix = component.name;
  const service = useSelector((state: RootState) => state.service);

  const [recordingState, execSetRecording]: readonly [
    IActionState<any>,
    typeof setRecording
  ] = useAsyncAction(setRecording);

  const [pdfState, execGeneratePdf]: readonly [
    IActionState<{url : string}>,
    typeof generatePDF
  ] = useAsyncAction(generatePDF);

  const submitStep = async () => {
    if (customer?.customer_uuid) {
      const res = await execSetRecording(
        customer.customer_uuid,
        issue.uuid,
        step.uuid,
        service?.currentService?.contact_id || '',
        "term_accept",
        "end_recording"
      );

      if (res.status === 200) {
        formikProps.setFieldValue(`${component.name}.finished`, true);
        stepAction("next_step", formikProps);
      }
    }
  };

  useEffect(() => {
    const installmentTypeName = `${namePrefix}.installment_type`;
    const installmentCountName = `${namePrefix}.installments_count`;
    const firstInstallmentDateName = `${namePrefix}.first_installment_date`;

    formikProps.setFieldValue(
      installmentCountName,
      localStorage.getItem("payment_date")
    );

    formikProps.setFieldValue(
      installmentCountName,
      localStorage.getItem("installments_counts")
    );
    formikProps.setFieldValue(
      installmentTypeName,
      localStorage.getItem("installments_type")
    );
    formikProps.setFieldValue(
      firstInstallmentDateName,
      localStorage.getItem("first_installment_date")
    );
  }, []);


  let installmentsCount = "";
  let installmentsType = "";
  let firstInstalmentDate = "";
  console.log("formik values: ", formikProps.values);
  if (formikProps.values) {
    installmentsCount = formikProps.values[namePrefix]?.installments_count;
    installmentsType = formikProps.values[namePrefix]?.installment_type;
    firstInstalmentDate =
      formikProps.values[namePrefix]?.first_installment_date;
  }

  let debt = localStorage.getItem("debt");
  let paymentDate = localStorage.getItem("payment_date")
  if (debt == null) {
    debt = "0";
  }

  const generatePdf = () => {
    const values: IPDFFormValues = {
      installments_counts: installmentsCount,
      installments_type: installmentsType,
      installments_first_date: firstInstalmentDate, 
      pdf_type : "statement_summary"
    };
    if(customer){
      execGeneratePdf(customer?.customer_uuid, issue.uuid, values)
      }
    
  };

  return (
    <div className="step-component mb-20">
      {pdfState.loading && <Spinner />}
      <h3>{component.title}</h3>
      <div className="red md-con-t mb-10">
        <i className="status-dot red mr-5" />
        Trwa nagrywanie oświadczenia
      </div>

      <div className="lg-con-t dark italic">Zgodnie z ustaleniami:</div>
      {(paymentDate && paymentDate !== null)  &&
             <ul className="lg-con-t black italic mb-20">
             <li>
               Dokona pan(i) spłaty całości zadłużenia przed wyznaczonym terminem: {formatDateTime(paymentDate)}
          
             </li>
         
             <li>Całość zadłużenia wynosi {parseFloat(debt).toFixed(2)} zł</li>
           </ul>
      }
      {(!paymentDate &&  installmentsCount !== null) && (
        <ul className="lg-con-t black italic mb-20">
          <li>
            Dokona pan(i) spłaty {installmentsCount} rat po{" "}
            {(parseFloat(debt) / parseFloat(installmentsCount)).toFixed(2)} zł
            każda opłacane miesięcznie.
          </li>
          
          <li>
            Data pierwszej wpłaty w wysokości{" "}
            {(parseFloat(debt) / parseFloat(installmentsCount)).toFixed(2)} zł
            to {formatDateTime(firstInstalmentDate)}
          </li>
          <li>Całość zadłużenia wynosi {parseFloat(debt).toFixed(2)} zł</li>
        </ul>
      )}
      <Button
        title="Wygeneruj harmonogram"
        color="blue-color"
        width={300}
        onClick={generatePdf}
        className="mb-10"
      />
      {pdfState.data?.url &&
        <a rel="noreferrer"  target="_blank" href={pdfState.data.url} className="mb-15 i-b" >Pobierz</a>
      }
      
      <div className="lg-con-t dark italic">
        Czy akceptuje Pan(i) powyższe warunki ugody?
      </div>

      {(!component.value || !component.value["finished"]) && (
        <div className="row">
          <div className="col">
            <CaseButton
              onClick={() => {
                submitStep();
              }}
              //    onClick={() => setNextStep()}
              title="Zakończ zbieranie oświadczenia"
              type="positive"
              inline={true}
            />
          </div>

          <div className="col">
            <CaseButton
              // onClick={() => nextStep()}
              onClick={() => {
                formikProps.setFieldValue(`${component.name}.finished`, true);
                stepAction("terminate", formikProps);
              }}
              title="Przerwij sprawę"
              type="negative"
              inline={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InitializeCall;
