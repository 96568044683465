import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { requestHeaders } from "utils/auth";

const AGENTS_URL = `${API_BASE_URL}/agents`;

export const fetchAgentsStats = async () => {
  const options: AxiosRequestConfig = {
    url: `${AGENTS_URL}/agent_stats`,
    method: "get",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};


export const fetchAgents = async (teamId? :string, date? : string) => {
  const options: AxiosRequestConfig = {
    url: `${AGENTS_URL}/team_agents${teamId ? `?team_id=${teamId}` : ""}${date ? `&date=${date}` : ""}`,
    method: "get",
 
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};


