import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useFormikContext, Field, FormikProps } from "formik";
import style from './questionnaire-radio.module.scss'

import CaseButton from "components/buttons/case-button";
import {
  IInvoiceSearchFormComponent,
  IFormButtonsComponent,
  IStepComponent,
  IQuestionnaireRadioComponent,
  IRadioQuestion,
} from "types/issue-types";
import TextField from "components/form-fields/text-field";

export function compareItems(a: IRadioQuestion, b: IRadioQuestion) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

const InputComponent = ({
  component,
}: {
  component: IQuestionnaireRadioComponent;
}) => {
  const props = useFormikContext();

  useEffect(() => {
    // props.setFieldValue(component.name, "1234");
    if (component.value) {
      component.questions.map((item, index) => {
        
        props.setFieldValue(
          `${component.name}.${component.name}_${index}`,
          component.value[`${component.name}_${index}`]
        );
      });
    }
  }, []);



  const renderRadios = (question: IRadioQuestion, questionIndex: number) => {
    return question.answers.map((item, index) => {
      return (
        <div key={"radio_" + item} className="form-check">
          <Field name={`${component.name}.${component.name}_${questionIndex}`}>
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
                
              return (
                <>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={field.value == item}
                    id={`${question.question}${index}`}
                    value={item}
                    name={`${component.name}_${questionIndex}`}
                    onChange={(e) =>
                      props.setFieldValue(
                        `${component.name}.${component.name}_${questionIndex}`,
                        e.target.value
                      )
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${question.question}${index}`}
                  >
                    {item}
                  </label>
                </>
              );
            }}
          </Field>
        </div>
      );
    });
  };

  const renderQuestions = () => {
      const ordered = component.questions.sort(compareItems);
    return ordered.map((item, index) => {
      return (
        <div key={'question_' + item.question} className={`${style['radio-item']} flex-left`}>
          <div className={`${style['radio-label']}`}>{item.question}</div>
          {renderRadios(item, index)}
        </div>
      );
    });
  };

  return <div>{renderQuestions()}</div>;
};

export default InputComponent;
