import React, { useState, useEffect } from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import style from './global-message.module.scss';


export interface Iprops {

}



const Message = (props: Iprops) => {

    return <div className={`${style['message']}`}>
        <div className="md-con-t t-500 mb-5 red flex-left"><ErrorOutlineIcon className="mr-5"/> Ważny komunikat</div> 
        <div className="md-t t-700 mb-5">Zasuwaj szybciej</div>
        <div className="md-con-t">
            Dużo ludzi czeka w kolejce, proszę zwiększyć tempo.
        </div>
    </div>
}

export default Message;
