import React from 'react'
import { ITextComponent } from 'types/issue-types'

const LinkComponent = (props: ITextComponent) => {

    return (<div className="step-component mb-20">
        <a target={"_blank"} href={props.value}>
            <div className="lg-con-t italic">
                {props.text}
            </div>
        </a>
    </div>
    )
}

export default LinkComponent;