import React from "react";
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import { Field, FormikProps, FastField } from "formik";
// import DatePicker from "antd/lib/date-picker";
// import { PasswordInput } from "antd-password-input-strength";
// @ts-ignore
// import "styles/components/input.scss";
// import moment from "moment";
import { getNested } from "utils/helpers";
// const { RangePicker } = DatePicker;
// import { DatePicker } from "antd";

const ErrorMsg = (msg: string) => (
  <span data-testid="text-field-error" className="input-error">
    {msg}{" "}
  </span>
);

export interface ITextProps {
  disableErrorMessage?: boolean;
  disabled?: boolean;
  label: string;
  size?: string;
  name: string;
  search?: boolean;
  clearVisible?: boolean;
  clearSearch?: () => void;
  optFixedWidth?: boolean;
  placeholder?: string;
  onChange?: (a: any) => void;
  type?:
    | "text"
    | "password"
    | "new-password"
    | "tel"
    | "email"
    | "number"
    | "date"
    | "textarea"
    | "date-range";
  testId?: string;
  required?: boolean;
  suffix?: string | JSX.Element;
  max?: number;
  min?: number;
  maxlength?: number;
  validate?: any;
  className?: string;
  value?: any;
  id : string;
}

// function disabledDate(current: any) {
//   // Can not select days before today and today
//   return current && current < moment().date(1900);
// }

export default function RadioButton(componentProps: ITextProps) {
  const {
    disabled,
    label,
    size,
    name,
    search,
    clearVisible,
    clearSearch,
    value,
    onChange,
    id
  } = componentProps;

  let { testId, ...props } = componentProps;

  return (
    <div className="form-check radio-button">
      <Field name={name}>
        {({ field, form }: { field: any; form: FormikProps<any> }) => {
          return (
            <>
              <input
                className="form-check-input"
                type="radio"
                checked={field.value === value}
                id={id}
                value={value}
                name={name}
                onChange={(e) => {
                  form.setFieldValue(name, e.target.value);
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
              />
              <label className="form-check-label" htmlFor={id}>
                {label}
              </label>
            </>
          );
        }}
      </Field>
    </div>
  );
}
