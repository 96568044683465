import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { requestHeaders } from "utils/auth";

const TEAMS_URL = `${API_BASE_URL}/company_teams`;
const COMPANIES_URL = `${API_BASE_URL}/companies`;



export const fetchCompanyTeams = async () => {
  const options: AxiosRequestConfig = {
    url: `${TEAMS_URL}`,
    method: "get",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};

export const fetchCompanies = async () => {
  const options: AxiosRequestConfig = {
    url: `${COMPANIES_URL}`,
    method: "get",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};