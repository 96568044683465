import { UPDATE_ISSUE } from "types/redux-types";
import { useDispatch } from "react-redux";

export const useReduxActions = () => {
  const dispatch = useDispatch();

  const updateCurrentIssue = (payload: {
    issue_id: string;
    issue_status: string;
  }) => {
    dispatch({
      type: UPDATE_ISSUE,
      payload,
    });
  };

  return {updateCurrentIssue}
};
