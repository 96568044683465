import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IVerificationFormComponent,
  StepActionType,
  IIssueStep,
  IIssue,
} from "types/issue-types";
import CaseButton from "components/buttons/case-button";
import { useFormikContext } from "formik";
import _ from "lodash";
import { FormikProps } from "formik";
import TextField from "components/form-fields/text-field";
import { verify } from "actions/services";
import { useDispatch } from "react-redux";

const VerificationForm = ({
  component,
  nextStep,
  stepAction,
  step,
  issue,
}: {
  component: IVerificationFormComponent;
  nextStep: () => void;
  stepAction: (
    actionType: StepActionType,
    formikProps: FormikProps<any>
  ) => any;
  step: IIssueStep;
  issue: IIssue;
}) => {
  const [subset, setSubset] = useState<string[]>([]);
  const formikProps = useFormikContext<any>();

  const dispatch = useDispatch();
  const fieldNames = {
    notVerified: `${component.name}.not_verified`,
    verified: `${component.name}.verified`,
  };

  const renderQuestions = () => {
    return subset.map((item, index) => {
      return <li key={index + "_question"}>{item} </li>;
    });
  };

  useEffect(() => {
    pickRandom();
  }, []);

  const pickRandom = () => {
    const subset = _.sampleSize(component.questions, component.question_count);
    setSubset(subset);
  };

  const terminate = () => {
    formikProps.setFieldValue(fieldNames.notVerified, true);
    stepAction("terminate", formikProps);
  };
  console.log("verification form value: ", component.value);
  return (
    <div>
      <h3>{component.title}</h3>
      <ol className="md-con-t black mb-20" start={1}>
        {renderQuestions()}
      </ol>
      {/* { issue.status === 'open'  && */}
      {step.status !== "completed" && (
        <div className="row">
          {component.success_action &&
            component.success_action === "next_step" && (
              <div className="col">
                <CaseButton
                  onClick={() => {
                    dispatch(verify());
                    formikProps.setFieldValue(
                      `${component.name}.verified`,
                      true
                    );
                    stepAction("next_step", formikProps);
                  }}
                  //    onClick={() => setNextStep()}
                  title="Weryfikacja pozytywna"
                  type="positive"
                  inline={true}
                />
              </div>
            )}
          <div className="col">
            <CaseButton
              // onClick={() => nextStep()}
              onClick={() => pickRandom()}
              title="Ponowna weryfikacja"
              type="neutral"
              inline={true}
            />
          </div>
          {component.error_action === "terminate" && (
            <div className="col">
              <CaseButton
                // onClick={() => nextStep()}
                onClick={() => terminate()}
                title="Weryfikacja negatywna"
                type="negative"
                inline={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerificationForm;
