import React, { useState, useEffect } from 'react'
import { ITextComponent } from 'types/issue-types'


const TextMultiline = (props: ITextComponent) => {

    return (<div className="step-component mb-20">
        <h3>{props.title}</h3>
        <div className="lg-con-t">
            {props.text.split("#")?.map((item) =>
                <p>{item}</p>
            )}
        </div>
    </div>
    )
}


export default TextMultiline;