import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import style from "./case-button.module.scss";
import { Link } from "react-router-dom";

export type BtnColor = "black-color" | "grey-color" | "transparent";
interface IProps {
  testId?: string;
  link?: string;
  icon?: "save";
  title?: string;
  description?: string;
  half?: string;
  className?: string;
  onClick?: () => any;
  inline?: boolean;
  type: "positive" | "neutral" | "negative";
  width?: number;
  CustomIcon?: JSX.Element;
  disabled? : boolean;
}
const Button = (props: IProps) => {
  const {
    title,
    description,
    type,
    width,
    inline,
    onClick,
    icon,
    CustomIcon,
    disabled
  } = props;

  return (
    <button
    disabled={disabled}
      onClick={onClick}
      style={width ? { width: width + "px" } : { width: "100%" }}
      className={`${style["case-button"]} ${disabled ? style['case-button--disabled'] : ""} ${style[type]} ${
        inline ? "float" : ""
      }`}
    >
      {disabled && <div  className={`${style["disable-overlay"]}`}></div> }
      <div className={style["icon"]}>
        {(type === "positive" && !CustomIcon) && <CheckCircleOutlineIcon />}
        {(icon === "save" && !CustomIcon) && <SaveIcon />}
        {CustomIcon}
        {/* {type === "neutral" && <ReplayIcon />} */}
        {type === "negative" && <NotInterestedIcon />}
      </div>
      <div className={style["text"]}>
        <h3>{title}</h3>
        {description && <div className="mt-5">{description}</div>}
      </div>
    </button>
  );
};

export default Button;
