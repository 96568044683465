import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IStepComponent,
  StepActionType,
  IIssue,
  IStartCallRes,
} from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useFormikContext, FormikProps } from "formik";
import CaseButton from "components/buttons/case-button";
import { init, IInitConfig } from "utils/webphone-service";

import CallIcon from "@material-ui/icons/Call";
import style from "components/modal/incoming-call-modal.module.scss";
import { WebphoneContext } from "components/dashboard";
import { IActionState, IReduxActionState } from "types/async-types";
import { startCall } from "actions/issues";
import { useAsyncAction, useReduxAction } from "utils/async-action";

const InitializeCall = ({
  component,
  nextStep,
  stepAction,
  issue,
}: {
  component: IStepComponent;
  nextStep?: () => void;
  stepAction: (
    actionType: StepActionType,
    formikProps: FormikProps<any>
  ) => any;
  issue: IIssue;
}) => {
  const [dateTime, setDateTime] = useState<Date | undefined>();
  const [isCalling, setIsCalling] = useState(false);

  // const [webphone, setWebPhone] = useState<any>(null);
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  const config = useSelector((state: RootState) => state.user.session?.config);
  const credentials = useSelector(
    (state: RootState) => state.user.session?.webphone
  );
  const formikProps = useFormikContext<any>();
  const webphone = React.useContext(WebphoneContext)?.webphone;
  console.log("WEBPHONE context: ", webphone);

  const [callState, execStartCall]: readonly [
    IReduxActionState<IStartCallRes>,
     (customer_id: string, issue_id: string, contact_id?: string) => any
  ] = useReduxAction(startCall, 'attached-issues');

  const call = () => {
    if (customer) {
      execStartCall(customer.customer_uuid, issue.uuid, service?.currentService?.contact_id);
    }
  
  };

  const performCall = async () => {
      
    if (callState.data) {
      setIsCalling(true);
      if (webphone) {
        webphone.call(callState.data.call_param);
      }
    }
  }

  useEffect(() => {
    performCall();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState.data]);

  // useEffect(() => { 
  //   alert('response received');
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [webphone?.getState()]);


  //componend first render
  useEffect(() => {
    formikProps.setValues({
      picked_up: component.value?.picked_up,
    });
  }, []);





  return (
    <div className="step-component mb-20">
      <h3>{component.title}</h3>
      {formikProps.values["picked_up"] === true && (
        <h2 className=" green flex-left ">
          <CheckCircleOutlineIcon className="mr-5" /> Połączenie zostało
          odebrane
        </h2>
      )}
      {formikProps.values["picked_up"] === false && (
        <h2 className=" red flex-left ">
          <ErrorOutlineIcon className="mr-5" /> Połączenie nie zostało odebrane
        </h2>
      )}
      {!isCalling && formikProps.values["picked_up"] === undefined && (
        <div className="t-center">
          <div className={style["make-connection"]}>
            <div className="w-100 mb-10 lg-t t-500">Wykonaj połączenie </div>
            <CallIcon onClick={() => call()} className={`call-icon`} />
          </div>
        </div>
      )}

      {isCalling && (
        <div className="row">
          {/* {component.success_action && component.success_action === "next_step" && ( */}
          <div className="col">
            <CaseButton
              onClick={() => {
                formikProps.setFieldValue(`${component.name}.picked_up`, true);
                stepAction("next_step", formikProps);
              }}
              //    onClick={() => setNextStep()}
              title="Klient odebrał"
              type="positive"
              inline={true}
            />
          </div>
          {/*     )} */}

          {/* {component.error_action === "terminate" && ( */}
          <div className="col">
            <CaseButton
              // onClick={() => nextStep()}
              onClick={() => {
                formikProps.setFieldValue(`${component.name}.picked_up`, false);
                stepAction("terminate", formikProps);
              }}
              title="Klient nie odebrał"
              type="negative"
              inline={true}
            />
          </div>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default InitializeCall;
