import React from "react";

const TeamIcon = (props: {
  name: string;
  id : string;
  hex: string;
  onClick?: () => any;
  selectedTeam?: string;
}) => {
  const { name, hex, onClick, selectedTeam, id } = props;

  if(!name){
    return <> </>
  }

  return (
    <div     onClick={onClick} className={`icon-selectable ${selectedTeam === id ? 'icon-selectable--selected' : ""}`}>
      <div
    
        className="team-icon"
        style={{ color: hex, borderColor: hex }}
      >
        <span>
          {name && name[0]}
          {name && name[1]}
        </span>
      </div>
      <div className="md-con-t icon-selectable__name">{name}</div>
    </div>
  );
};

export default TeamIcon;
