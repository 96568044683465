import React, { useState } from "react";
import { useEffect } from "react";
import Router from "my-history";
// import nextCookie from "next-cookies";
import cookie from "js-cookie";
import { connect, useSelector } from "react-redux";

import { fetchSessionData } from "actions/user";
import { PAGE } from "paths";
import { useReduxAction, useAsyncAction } from "./async-action";
import { IReduxActionState, ActionResponse } from "types/async-types";
import Spinner from "components/common/spinner";
import { RootState } from "redux/reducers";
import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ISessionData } from "types/session-types";
// import { NextPageContext } from "next";
// import Cookies from 'cookies'

export const loginRedirect = () => {
  const path =
    window.location.pathname === "/"
      ? "/user/login"
      : `/user/login?redirect=${window.location.pathname}${window.location.search}`;
  Router.push(path);
};

export const login = async (token: any, redirectUrl?: string | null) => {
  cookie.remove("token");

  cookie.set("token", token);

  // const res = await getWorkshopToken();

  if (redirectUrl) {
    Router.push(`${redirectUrl}`);
  } else {
    // Router.push("/");
    Router.push("/dashboard");
  }
};

export const logout = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/logout`,
    method: "DELETE",
    headers: requestHeaders().headers,
  };
  let res: any = await axios(options);
  if (res) {
    cookie.remove("token");
    // to support logging out from all windows
    window.localStorage.setItem("logout", Date.now().toString());
  }
  
  Router.push("/user/login");
  const result = {...res, successMessage : "Poprawnie wylogowano użytkownika"}
  return result;
};

export const withAuthSync = (WrappedComponent: any) => {
  const Wrapper = (props: any) => {
    
    const [authDone, setAuthDone] = useState(false);
    // const user = useSelector((state: RootState) => state.user);
    
    const [session, execFetchSession]: readonly [
      IReduxActionState<ISessionData>,
      () => any
    ] = useReduxAction(fetchSessionData, "user");

    useEffect(() => {
      auth();
    }, []);


    useEffect(() => {
      if(session.data?.name === 'Manager'){
        Router.push("/admin/tasks");
      }
    }, [session.data]);



    const auth = async (ctx: any = {}) => {
      const urlParams = new URLSearchParams(window.location.search);
      let SID = urlParams.get("sid");
      let token: string | undefined = undefined;

      token = cookie.get("token");
      if (!token) {
        
        loginRedirect();
      } else {
        
        await execFetchSession();
      }
    };

    if (props.error_code === "401") {
      Router.push("/user/login");
    } 
    
    if (!session.data || session.loading || session.error === 401) {
      return <Spinner size="large" position="fixed" />;
    }
    
    return <WrappedComponent {...props} />;
  };

  // Wrapper.getInitialProps = async (ctx: any) => {
  //   if (ctx) {
  //     const token = await auth(ctx);
  //     if (token) {
  //       await ctx.store.dispatch(fetchSessionData(ctx));
  //       const componentProps =
  //         WrappedComponent.getInitialProps &&
  //         (await WrappedComponent.getInitialProps(ctx));
  //       // return { ...componentProps, token };
  //       return { ...componentProps };
  //     }
  //   }
  // };

  // const mapStateToProps = (state: any) => {
  //   return {
  //     error_code: state.error.error_code,
  //   };
  // };

  return connect(null, null)(Wrapper);
};

export const requestHeaders = (ctx?: any) => {
  const token = cookie.get("token");

  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {};
  }
};

export const workshopRequestHeaders = () => {
  const token = cookie.get("war_token");
  if (token) {
    return `Token ${token}`;
  } else {
    return {};
  }
};
