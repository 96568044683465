import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import style from "./incoming-call-modal.module.scss";
import { IActionState } from "types/async-types";
import { IIssue, IRecording } from "types/issue-types";
import { fetchRecordings } from "actions/issues";
import { useAsyncAction } from "utils/async-action";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ReactAudioPlayer from "react-audio-player";

interface IProps {
  isVisible: boolean;
  setVisible: (a: boolean) => any;
  contactId?: string;
  issueId?: string;
}

const CallModal = (props: IProps) => {
  const { isVisible, setVisible, contactId, issueId } = props;
  const [player, setPlayer] = useState<ReactAudioPlayer | null>(null);
  const customer = useSelector((state: RootState) => state.customer);
  const audioRef = useRef<ReactAudioPlayer>();

  const [recordingState, execFetchRecording]: readonly [
    IActionState<IRecording>,
    typeof fetchRecordings
  ] = useAsyncAction(fetchRecordings);
  useEffect(() => {
    if (isVisible && customer && contactId && issueId) {
      execFetchRecording(customer.customer_uuid, issueId, contactId);
    }
  }, [isVisible]);

  const setTag = (startTime: number) => {
    //   if(player){
    //       player.audioEl.current.
    //   }
    if (player?.audioEl.current) {
      player.audioEl.current.currentTime = startTime;
    }
  };

  const renderTags = () => {
    return recordingState.data?.tags.map((item, index) => {
      return (
        <li className="mb-10" onClick={() => setTag(item.start_time)}>
          {item.label}
        </li>
      );
    });
  };

  return (
    <Modal
      centered
      //   backdrop="static"
      show={isVisible}
      onHide={() => setVisible(false)}
    >
      <Modal.Header>
        <h3  className="mb-0">Lista nagrań</h3>
      </Modal.Header>
      <Modal.Body>
        <ul className="mb-20"> {renderTags()} </ul>
        <ReactAudioPlayer
          autoPlay={true}
          ref={(element) => {
            setPlayer(element);
          }}
          src={recordingState.data?.audio_url}
          controls={true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CallModal;
