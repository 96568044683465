import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import {
  IAgent,
  agentStatusLabels,
  IAgentsList,
  IAgentItem,
  AgentStatusType,
  ITeamItem,
} from "types/agent-types";
import Table from "components/common/table";
import { IActionState, IListing, IReduxActionState } from "types/async-types";
import { fetchAgents } from "actions/agents";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { fetchCompanyTeams } from "actions/company-teams";
import { setBreakTime } from "actions/user";
import Spinner from "components/common/spinner";
import TeamIcon from "components/common/team-icon";

const Agents = () => {
  const [activeTeam, setActiveTeam] = useState<string>();
  const [agentsState, execFetchAgents]: readonly [
    IActionState<IListing<IAgentItem>>,
    typeof fetchAgents
  ] = useAsyncAction(fetchAgents);

  const [teamsState, execFetchTeams]: readonly [
    IActionState<IListing<ITeamItem>>,
    typeof fetchCompanyTeams
  ] = useAsyncAction(fetchCompanyTeams);

  useEffect(() => {
    // execFetchAgents();
    execFetchTeams();
  }, []);
  // const assigned_teams = [
  //   {
  //     name: "Buldożery",
  //     hex: "#B768B4",
  //   },
  //   {
  //     name: "Windykatorzy",
  //     hex: "#43D22E",
  //   },
  // ]; 

  const agentsCols : Column<IAgent>[] = React.useMemo(
    () => [ 
      {
        Header: "Nazwa agenta",
        accessor: "name",
        Cell: (props) => (
          <div>
            <div className="admin-table__avatars">
              <div className={`user-image  mr-5`}>
        { props.row.original.avatar_url ?  <img alt="user symbol" src={props.row.original.avatar_url} /> : <img alt="user symbol" src="/assets/images/user.svg" />   }
              </div>
            </div>
            <div className="admin-table__agent-name">{props.value}</div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "cc_status",
        Cell: (props: { value: AgentStatusType }) => (
          <div
            className={`${props.value === "Available" ? "green" : props.value === "On Break" ? "orange" : 'red'}`}
          >
            {props.value && agentStatusLabels[props.value]}
          </div>
        ),
      },
      {
        Header: "Ilość zadań",
        accessor: "task_count",
        
      },
      {
        Header: "Przydzielone zespoły",
        accessor: "teams",
        Cell: (props) => (
          <div className="admin-table__avatars">
            {props.value.map((item, index) => {
              return (
                <div
                  className="team-icon"
                  style={{ color: item.color, borderColor: item.color }}
                >
                  <span>
                    {item.name[0]}
                    {item.name[1]}
                  </span>
                </div>
              );
            })}
          </div>
        ),
      },
    ],
    []
  );

  const pickTeam = (teamId: string) => {
    execFetchAgents(teamId);
    setActiveTeam(teamId);
  };

  const renderTeams = () => {
    const teams = teamsState.data?.list;
    if (teams) {
      return teams.map((item) => {
        return (
          <TeamIcon
            name={item.name}
            id={item.id}
            hex={item.color}
            selectedTeam={activeTeam}
            onClick={() => pickTeam(item.id)}
          />
        );
      });
    }
  }; 

  return (
    <div>
      {(agentsState.loading || teamsState.loading) && <Spinner />}
      <h1 className="mb-20">Agenci i Zespoły</h1>
      <div className="panel mb-20 i-b">{renderTeams()}</div>
      <div className="panel no-padding">
        {!agentsState.data && (
          <div className="panel-p">
            <p className="lg-t grey">Wybierz team aby wyświetlić agentów</p>
          </div>
        )}
        {agentsState.data && (
          <Table
            hidePagination={true}
            fetchData={execFetchAgents}
            data={agentsState.data}
            columns={agentsCols}
          />
        )}
      </div>
    </div>
  );
};

export default Agents;
