import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { requestHeaders } from "utils/auth";
import {
  IIssueTemplate,
  IIssueSubmit,
  StepComponentType,
  IStepComponent,
  IIssue,
  RecordType,
  RecordActionType,
  IStartCallRes,
} from "types/issue-types";
import {
  FETCH_ISSUE,
  IFetchCustomerIssue,
  IFetchCustomerIssues,
  FETCH_ISSUES,
  IAttachIssue,
  ATTACH_ISSUE,
  DESTROY_ISSUE,
  IDestroyIssue,
  ISyncAttachedIssue,
  SYNC_ATTACHED_ISSUE,
  FETCH_ATTACHED_ISSUES,
  IFetchAttachedIssues,
  IStartCall,
  START_CALL,
  UPDATE_ISSUE,
  IUpdateIssueId,
} from "types/redux-types";
import { IPDFFormValues } from "components/issues/dedicated-components/statement-summary";

const ISSUES_URL = `${API_BASE_URL}/issue_templates`;

export const fetchIssueTemplate = async (uuid: string) => {
  const options: AxiosRequestConfig = {
    url: `${ISSUES_URL}/${uuid}`,
    method: "GET",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};

export const fetchIssueTemplates = async (uuid: string) => {
  const options: AxiosRequestConfig = {
    url: `${ISSUES_URL}`,
    method: "GET",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<IIssueTemplate[]> = await axios(options);
  return res;
};

export const addIssue = async (issue: IIssueSubmit) => {
  const options: AxiosRequestConfig = {
    url: `${ISSUES_URL}`,
    method: "POST",
    data: issue,

    headers: requestHeaders().headers,
  };
  let res: any = await axios(options);
  const result = {
    ...res,
    successMessage: "Dodano sprawę",
  };
  return result;
};

export const createNewIssue = async (
  customerId: string,
  contact_id: string,
  templateId: string
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/new`,
    method: "POST",
    data: { template_uuid: templateId, contact_uuid: contact_id },

    headers: requestHeaders().headers,
  };
  let res: any = await axios(options);

  const result = {
    ...res,
    successMessage: "Rozpoczęto nową sprawę",
  };
  return result;
};

export const setStepState = async (
  type: "start" | "omit" | "complete",
  customerId: string,
  issue_id: string,
  id: string
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/issue_steps/${id}/${type}`,
    method: "POST",
    headers: requestHeaders().headers,
  };
  let res: any = await axios(options);
  if (type === "complete") {
    const result = {
      ...res,
      successMessage: "Krok zakończony",
    };
    return result;
  }

  return res;
};

export const showIssue = async (customerId: string, id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${id}`,
    method: "GET",

    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  return res;
};

export const submitStep = async (
  components: IStepComponent[],
  customerId: string,
  issue_id: string,
  id: string,
  status: "completed" | "omitted" | null,
  terminate_value?: string
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/issue_steps/${id}`,
    method: "PATCH",
    headers: requestHeaders().headers,
    data: {
      status: status || undefined,
      components: components,
      terminate_value: terminate_value,
    },
  };
  let res: any = await axios(options);
  const result = {
    ...res,
    successMessage: "Zapisano krok",
  };
  return result;

  // return result;
};

export const finishIssue = async (customerId: string, issue_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/finish`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {},
  };
  let res: any = await axios(options);
  const result = {
    ...res,
    successMessage: "Zamknięto sprawę",
  };
  return result;

  // return result;
};

export const terminateIssue = async (customerId: string, issue_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/terminate`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {},
  };
  let res: any = await axios(options);


  const result = {
    ...res,
    successMessage: "Zamknięto sprawę",
  };
  return result;

  // return result;
};

export const fetchIssue = (customerId: string, issue_id: string) => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  // if (res) {
  const action: IFetchCustomerIssue = {
    type: FETCH_ISSUE,
    payload: res.data,
  };
  dispatch(action);
  return res;
  // }
};

//to update in future
export const fetchAttachedIssues = (
  customerId: string,
  issue_id: string
) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  // if (res) {
  const action: IFetchAttachedIssues = {
    type: FETCH_ATTACHED_ISSUES,
    payload: res.data,
  };
  dispatch(action);
  return res;
  // }
};

export const syncAttachedIssues = (
  customerId: string,
  issue_id: string
) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<IIssue> = await axios(options);
  
  // if (res) {
  const action: ISyncAttachedIssue = {
    type: SYNC_ATTACHED_ISSUE,
    payload: res.data,
  };



  const updateIssueAction: IUpdateIssueId = {
    type: UPDATE_ISSUE,
    payload: {issue_id : res.data.uuid, issue_status : res.data.status},
  };
  dispatch(action);
  dispatch(updateIssueAction);
  return res;
  // }
};

export const destroyIssue = (customerId: string, issue_id: string) => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}`,
    method: "DELETE",
    headers: requestHeaders().headers,
    data: {},
  };
  let res: ActionResponse<any> = await axios(options);
  const action: IDestroyIssue = {
    type: DESTROY_ISSUE,
    payload: issue_id,
  };
  dispatch(action);
  return res;
  // return result;
};

export const addIssueToContact = async (
  customerId: string,
  issue_id: string,
  contactId: string
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/attach_to_contact`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      contact_uuid: contactId,
    },
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const attachIssue = (issue: IIssue) => async (dispatch: any) => {
  const action: IAttachIssue = {
    type: ATTACH_ISSUE,
    payload: issue,
  };
  dispatch(action);
};

export const createMonit = (
  customerId: string,
  issue_id: string,
  monitData: {
    customer_service_id: string;
    date: string;
  }
) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}/create_monit`,
    method: "POST",
    headers: requestHeaders().headers,
    data: monitData,
  };

  let res: ActionResponse<any> = await axios(options);

  res = {
    ...res,
    successMessage: "Zaplanowano kontakt",
  };

  dispatch({ type: "SET_SUCCESS", payload: "create-monit" });
  return res;
};

export const setRecording = async (
  customer_id: string,
  issue_id: string,
  step_id: string,
  contact_id : string,
  recordType: RecordType,
  action: RecordActionType
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customer_id}/issues/${issue_id}/recording`,

    method: "POST",
    headers: requestHeaders().headers,
    data: {
      contact_id,
      step_id,
      record_type: recordType,
      action_type: action,
    },
  };
  let res: ActionResponse<any> = await axios(options);

  return res;
};

export const generatePDF = async (
  customer_id: string,
  issue_id: string,
  formValues: IPDFFormValues
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customer_id}/issues/${issue_id}/generate_pdf`,

    method: "POST",
    headers: requestHeaders().headers,
    data: formValues,
  };
  let res: ActionResponse<any> = await axios(options);
  

  return res;
};

// ** Webphone actions  *//

// export const test = (customerId: string, issue_id: string) => async (
//   dispatch: any
// ) => {
//   const options: AxiosRequestConfig = {
//     url: `${API_BASE_URL}/customers/${customerId}/issues/${issue_id}`,
//     method: "DELETE",
//     headers: requestHeaders().headers,
//     data: {},
//   };
//   let res: ActionResponse<any> = await axios(options);
//   const action: IDestroyIssue = {
//     type: DESTROY_ISSUE,
//     payload: issue_id,
//   };
//   dispatch(action);
//   return res;
//   // return result;
// };



export const startCall =  (customer_id: string, issue_id: string, contact_id?: string) => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customer_id}/issues/${issue_id}/call`,
    method: "POST",
    headers: requestHeaders().headers,
    data: { contact_id: contact_id }
  };
  let res: ActionResponse<any> = await axios(options);

  const action: IStartCall = {
    type: START_CALL,
    payload: {
      contact_id : res.data.contact_id,
      call_issue_id : issue_id
    }
  };
  dispatch(action);
  

  return res;
};


export const endCall =  (customer_id: string, issue_id: string, contact_id?: string) => async (
  dispatch: any
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customer_id}/issues/${issue_id}/dismiss_call`,
    method: "POST",
    headers: requestHeaders().headers,
    data: { contact_id: contact_id }
  };
  let res: ActionResponse<any> = await axios(options);

  const action: IStartCall = {
    type: START_CALL,
    payload: {
      contact_id : res.data.contact_id,
      call_issue_id : issue_id
    }
  };
  dispatch(action);
  

  return res;
};




export const fetchRecordings = async (customer_id: string, issue_id: string, contact_id : string) =>  {
  const options: AxiosRequestConfig = {
    
    url: `${API_BASE_URL}/customers/${customer_id}/issues/${issue_id}/recordings`,
    method: "GET",
    headers: requestHeaders().headers,
    params : {
      contact_id
    }
  };
  let res: ActionResponse<any> = await axios(options);
  
  return res;
};