import React, { useEffect } from "react";
import { useFormikContext, Field, FormikProps } from "formik";
import style from './questionnaire-radio.module.scss'

import {
  IQuestionnaireRadioComponent,
  IRadioQuestion,
} from "types/issue-types";

export function compareItems(a: IRadioQuestion, b: IRadioQuestion) {
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
}

const QuestionnarieCheckbox = ({
  component,
}: {
  component: IQuestionnaireRadioComponent;
}) => {
  const props = useFormikContext();

  useEffect(() => {
    // props.setFieldValue(component.name, "1234");
    if (component.value) {
      component.questions.map((item, index) => {

        props.setFieldValue(
          `${component.name}.${component.name}_${index}`,
          component.value[`${component.name}_${index}`]
        );
      });
    }
  }, []);


  const renderRadios = (item: IRadioQuestion, questionIndex: number) => {
    return (
      <div key={"checkbox_" + questionIndex} className="form-check">
        <Field name={`${component.name}.${component.name}_${questionIndex}`}>
          {({ field, form }: { field: any; form: FormikProps<any> }) => {

            return (
              <>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={field.value == item.id}
                  id={`${item.id || `check_${item.position}`}${questionIndex}`}
                  value={item.id || ''}
                  name={`${component.name}_${questionIndex}`}
                  onChange={(e) =>
                    props.setFieldValue(
                      `${component.name}.${component.name}_${questionIndex}`,
                      e.target.checked ? e.target.value : null
                    )
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={`${item.id || `check_${item.position}`}${questionIndex}`}
                >
                  {item.question}
                </label>
              </>
            );
          }}
        </Field>
      </div>
    );
  };

  const renderQuestions = () => {
    const ordered = component.questions.sort(compareItems);
    return ordered.map((item, index) => {
      return (
        <div key={'question_' + item.question} className={`${style['radio-item']} flex-left`}>
          {renderRadios(item, index)}
        </div>
      );
    });
  };

  return <div>{renderQuestions()}</div>;
};

export default QuestionnarieCheckbox;
