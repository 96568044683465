import React from "react";
import ErrorMessage from 'components/form-fields/error-message';
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import { Field, FormikProps, FastField } from "formik";
// import DatePicker from "antd/lib/date-picker";
// import { PasswordInput } from "antd-password-input-strength";
// @ts-ignore
// import "styles/components/input.scss";
// import moment from "moment";
import { getNested } from "utils/helpers";
// const { RangePicker } = DatePicker;
// import { DatePicker } from "antd";

const ErrorMsg = (msg: string) => (
  <span data-testid="text-field-error" className="input-error">
    {msg}{" "}
  </span>
);

export interface ITextProps {
  disableErrorMessage?: boolean;
  disabled?: boolean;
  label: string;
  size?: string;
  name:  string;
  search?: boolean;
  clearVisible?: boolean;
  clearSearch?: () => void;
  optFixedWidth?: boolean;
  placeholder?: string;
  onChange?: (a: any) => void;
  type?:
    | "text"
    | "password"
    | "new-password"
    | "tel"
    | "email"
    | "number"
    | "date"
    | "textarea"
    | "date-range";
  testId?: string;
  required?: boolean;
  suffix?: string | JSX.Element;
  max?: number;
  min?: number;
  maxlength?: number;
  validate?: any;
  className?: string;
  value?: any;
}

// function disabledDate(current: any) {
//   // Can not select days before today and today
//   return current && current < moment().date(1900);
// }

function TextField(componentProps: ITextProps) {
  const {
    disabled,
    label,
    size,
    name,
    search,
    clearVisible,
    clearSearch,
    
    required,
    validate,
    placeholder,
    disableErrorMessage,
  } = componentProps;

  let {testId, ...props} = componentProps;
  
  
  




  return (
    <div className={`${search && "search-field"}`}>
      <div className="form-group">
        <label className="form-label">{label}</label>
        {/* {search && (
          <SearchIcon data-test="search-icon" className="search-glass" />
        )}
        {clearVisible && (
          <CloseIcon
            data-test="clear-icon"
            onClick={clearSearch}
            className="clear-search"
          />
        )} */}
        <Field
          autoComplete="off"
          className="text"
          {...props}
          disabled={disabled}
          validate={validate || undefined}
        >
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const touched = getNested(form.touched, name);
            const error : string = getNested(form.errors, name);

            let input: any = null;
            if (props.type === "password") {
              input = (
                <input
                  className="form-control"
                  data-testid={testId}
                  {...field}
                  {...props}
                />
              );
            } else if (props.type === "textarea") {
              input = (
                <textarea
                
                  className="form-control"
                  data-testid={testId}
                  {...field}
                  {...props}
                  // value={props.value === null ? "" : props.value}
                  
                />
              );
            } else {
              let myProps = props;
              delete myProps.value;
              
              input = (
                <input
                  className="form-control"
                  autoComplete="off"
                  data-testid={testId}
                  {...field}
                  {...myProps}
                />
              );
            }
            return (
              <>
                {input}
                {!disableErrorMessage && touched && error && (
                 <ErrorMessage message={error}  />
                )}
              </>
            );
          }}
        </Field>
        {/* <ErrorMessage name={name} render={ErrorMsg} /> */}
      </div>
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </div>
  );
}

export default TextField;
