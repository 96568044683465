import React, { useState, useEffect, useMemo } from "react";
import Button from "components/buttons/button";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Spinner from "components/common/spinner";
import style from "./left-panel.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchContacts } from "actions/customer";
import { IReduxActionState, IListing } from "types/async-types";
import { useReduxAction } from "utils/async-action";
import { IContact } from "types/customer-types";
import { formatDateTime, getTime } from "utils/date";
import {Pagination} from "@material-ui/lab"; 
import { IIssue, IIssueLog, IssueLogType } from "types/issue-types";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import MicIcon from "@material-ui/icons/Mic";
import LinkIcon from "@material-ui/icons/Link";
import RecordingModal from "./recording-modal";
import { PhoneCallbackOutlined } from "@material-ui/icons";
const renderLog = (contact: IContact) => {
  const date = new Date(contact.created_at);
  const year = date.toLocaleDateString(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const time = date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  function renderIssues(issues: IIssue[]) {
    if (issues) {
      return issues.map((item, index) => {
        return <SimpleIssueItem key={item.uuid + "_issue"} issue={item} />;
      });
    }
  }
  
  return (
    <div className={`${style["log"]}`}>
      <ul className={`mb-20`}>
        {/* <li key={"log_item_1"} className="flex-left">
          <div className="md-con-t mr-5"> {year}</div>
          <div className="md-con-t"> {time}</div>
          <div className={`${style["action-icon"]}`}>
            <PhoneDisabledIcon className="grey" />
          </div>
          <div className="md-con-t grow-1">Rozpoczęcie kontaktu</div>
        </li> */}
        {/* <li className="flex-left">
          <div className="md-con-t">12:06</div>
          <div className={`${style["action-icon"]}`}>
            <PhoneDisabledIcon className="grey" />
          </div>
          <div className="md-con-t grow-1">Zakończenie kontaktu</div>
        </li>
        <li className="flex-left">
          <div className="md-con-t">12:06</div>
          <div className={`${style["action-icon"]}`}></div>
          <div className="md-con-t grow-1">Zamówienie/przełożenie kontaktu</div>
        </li>
        <li className="flex-left">
          <div className="md-con-t">12:06</div>
          <div className={`${style["action-icon"]}`}></div>
          <div className="md-con-t black grow-1">
            Nowa sprawa "Sprzedaż {">"} Internet 600Mps"
          </div>
          <div className="md-con-t">
            <a href="#">Podgląd sprawy</a>
          </div>
        </li> */}

        { renderIssues(contact.issues) }
        
      </ul>
      {/* <div className={`md-con-t ${style["links"]}`}>
        <a href="#">Nagraj</a> <a href="#">Notatka</a> <a href="#">Załącznik</a>{" "}
        <a href="#">Zdjęcie</a>
      </div> */}
    </div>
  );
};

const renderIssueLog = (log: IIssueLog[], issue: IIssue) => {
  

  const result = log.map((item, index) => {
    const renderIcon = (type: IssueLogType) => {
      switch (type) {
        case "INFO":
          return <InfoIcon />;
        case "WARNING":
          return <WarningIcon />;
        case "RECORD":
          return <MicIcon />;
        case "SUCCESS":
          return <CheckCircleOutlineIcon />;
        case "LINK":
          return <LinkIcon />;
        case "CALL":
          return <PhoneCallbackOutlined />;
      }
    };

    const renderLink = (logItem: IIssueLog) => {
      switch (logItem.log_type) {
        case "RECORD":
          return (
            <a
              target="_blank"
              rel="noreferrer"
            >
              {item.content}
            </a>
          );

        case "LINK":
          if (logItem.url) {
            return (
              <a target="_blank" href={logItem.url} rel="noreferrer">
                {item.content}
              </a>
            );
          }
          break;
        default:
          return item.content;
      }
    };

    return (
      <div key={item.date_time + "_log"} className={`${style["log"]}`}>
        <ul className={`mb-10`}>
          <li className="flex-left">
            <div className="md-con-t mr-5">
              {" "}
              {formatDateTime(item.date_time)}
            </div>
            <div className={`md-con-t ${style["time"]}`}>
              {" "}
              {getTime(item.date_time)}
            </div>

            <div className={`${style["action-icon"]}`}>
              {renderIcon(item.log_type)}
              {/* <PhoneDisabledIcon className="grey" /> */}
            </div>
            <div
              className={`md-con-t grow-1 ${style["log-details"]} ${item.log_type}`}
            >
              {renderLink(item)}
            </div>
          </li>
        </ul>
      </div>
    );
  });

  return <div className="mt-10">{result}</div>;
};

const SimpleIssueItem = (props: { issue: IIssue }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [attached, setAttached] = useState<boolean>(false);

  const { issue } = props;

  useEffect(() => {
    // const currentIssueId = service?.currentService?.issue_id;
    // if (attachedIssues && issue.uuid === currentIssueId) {
    //   setExpanded(true);
    // }
    // if (attachedIssues && attachedIssues[issue.uuid]) {
    //   setAttached(true);
    // }
  }, []);

  return (
    <div
      className={` mb-10 mt-10 ${
        attached ? style["expanded"] : ""
      } flex`}
    >
      {attached && (
        <div
          className="item-marker"
          style={{ backgroundColor: `${issue.color}` }}
        ></div>
      )}
      {issue.status === "closed" && (
        <CheckCircleOutlineIcon className=" green" />
      )}
      {issue.status === "open" && (
        <img src="/assets/images/progress-check.svg" className="mr-10" />
      )}
      {issue.status === "terminated" && (
        <HighlightOffIcon className="red mr-10" />
      )}

      <div className="grow-1">
        <div
          onClick={() => setExpanded(!expanded)}
          className={`flex-center ${style["overview"]}`}
        >
          <div className="grow-1">
            <div className="md-con-t">
              {/* <span className="md-con-t mr-5">
                {formatDateTime(contact.created_at)}
              </span> */}
              {<div className="md-t dark">{issue.number}</div>}
              {issue.title}

              {/* <span className="green condensed">(trwająca…)</span> */}
            </div>
            <div className="md-t">{issue.title.split(">").slice(-1)[0] || issue.title}</div>
          </div>

          {/* <div className={`${style["case-count"]} md-con-t`}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div> */}
        </div>
        {expanded && renderIssueLog(issue.log, issue)}
        {expanded && (
          <div className={`md-con-t ${style["links"]}`}>
            {/* {(!attachedIssues ||
              (attachedIssues && !attachedIssues[issue.uuid])) && (
              <a onClick={() => toggleIssue()} href="#">
                Przypnij do trwającego kontaktu
              </a>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

const ContactItem = (props: { contact: IContact }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const service = useSelector((state: RootState) => state.service);
  const { contact } = props;
  const date = new Date(contact.created_at);
  const year = date.toLocaleDateString(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const time = date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className={`${style["call-item"]}`}>
      
        
      <div className="flex">
      <PhoneCallbackIcon className="grey mr-10" />
      <div className="grow-1">
        <div
          onClick={() => setExpanded(!expanded)}
          className={`flex-center ${style["overview"]}`}
        >
          <div className="grow-1">
            <div className="md-t">
              <span className="md-con-t mr-5">
                {`${time}`}
              </span>
              Rozmowa telefoniczna{" "}
              {/* <span className="green condensed">(trwająca…)</span> */}
            </div>
          </div>

          <div className={`${style["case-count"]} md-con-t`}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
        
      </div>
      </div>
      
      {expanded && renderLog(contact)}
    </div>
  );
};

const Contacts = () => {
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  console.log("render contacts");


  const contacts = customer?.contacts?.list; 

  const [constactsState, execFetchContacts]: readonly [
    IReduxActionState<IListing<IContact>>,
    (uuid?: string, page?: number) => any
  ] = useReduxAction(fetchContacts, "contacts");

  useEffect(() => {
    console.log("mount contacts");
    if (service?.currentService) {
      // execFetchContacts(service.currentService?.customer_id);
    }
  }, []);

  useEffect(() => {
    console.log("contacts state update");
    if (service?.currentService) {
      execFetchContacts(service.currentService?.customer_id);
    }
    return function cleanUp() {
      console.log("contacts cleanup");
    };
  }, [service?.currentService]);

  //before customer_service
  // useEffect(() => {
  //   if (customer) {
  //     execFetchContacts(customer.customer_uuid);
  //   }
  // }, []);

  const renderContacts = (contacts: IContact[]) => {
    return contacts.map((item, index) => {
      return <ContactItem key={item.uuid + "_contact"} contact={item} />;
    });
  };

  const renderDateSections = () => {
    console.log("render contact date sections loop");
    if (contacts) {
      return Object.keys(contacts).map((dateKey : string, index) => {
        return (
          <div key={dateKey + "_item_date"}>
            {/* {(constactsState.loading ||  async.isLoading) &&
              <Spinner />
            } */}
            <div key={dateKey + "date"} className={`mb-15`}>
              <div className={`${style["date-separator"]}`}>
                <div className={`md-con-t ${style["date"]}`}>
                  {formatDateTime(dateKey)}
                </div>
              </div>
              <div className={`${style["contact-dropdown"]}`}>
                {contacts &&
                  renderContacts(contacts[dateKey])
                }
                
              </div>
            </div>
          </div>
        );
      });
    }
  };
  const PaginationMemo = () => {
    const memo =  <div className="pagination-wrapper">
       <Pagination
         
         shape="rounded"
         onChange={(e, page) => {
           console.log("page: ", page);
           execFetchContacts(service?.currentService?.customer_id, page);
         }}
         page={constactsState.data?.current_page}
         
         count={constactsState.data?.total_pages}
       />
     </div>


     return memo;
  }

  console.log('current_page contacts', constactsState.data?.current_page);
  return (
    <>
      {/* <div className={`panel-p ${style["filters"]}`}>
        <div>
          <Button title="Wszystkie" color="black-color" inline={true} />
          <Button title="Telefon" color="grey-color" inline={true} />
          <Button title="Email" color="grey-color" inline={true} />
          <Button title="SMS" color="grey-color" inline={true} />
          <Button title="Messenger" color="grey-color" inline={true} />
          <Button title="Zendesk" color="grey-color" inline={true} />
        </div>
      </div> */}
      <div className={`date-sections`}>
        {constactsState.loading && <Spinner position="relative" />}
        
        <PaginationMemo/>
        
        {renderDateSections()}
      </div>
    </>
  );
};

export default Contacts;
