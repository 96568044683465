import React, { Component } from "react";
import { toast } from "react-toastify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import CloseIcon from "@material-ui/icons/Close";

export const successMessage = (messageText: string) =>
  toast.success(
    <span className="toast-content">
      {" "}
      <CheckCircleIcon />
      {messageText}
    </span>,
    {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );

export const errorMessage = (messageText: string) =>
  toast.error(<span className="toast-content">{messageText}</span>, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export interface IMessageProps {
  type?: "positive" | "error" | "warning" | "info";
  showIcon?: boolean;
  icon?: {
    color: string;
    image: new (props: any) => Component<any, any, any>;
  };
  autoClose?: number;
  position?: any;
  title: string;
  description?: string | JSX.Element;
  closeButton?: boolean;
}

export const message = (props: IMessageProps) => {
  const { type, title, description, autoClose, closeButton } = props;

  toast.info(
    <span className="toast-content">
      {" "}
      {(!type || type === "info") && <InfoOutlinedIcon />}
      {type === "error" && <NotInterestedOutlinedIcon className="red" />}
      {type === "warning" && <WarningOutlinedIcon />}
      {type === "positive" && <CheckCircleIcon className="green" />}
      <div>
        <div className="toast-title md-t">{title} </div>
        {description ? <p className="md-t">{description}</p> : ""}
      </div>
      {/* <style jsx global>
         {s1}
       </style> */}
    </span>,
    {
      autoClose: 3000,
      // closeButton: closeButton || <CloseIcon className="toast-close" />,

      pauseOnHover: true,
      position: props.position || "top-center",
      className: "custom-toaster",
    }
  );
};
