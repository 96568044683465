import React from 'react';
import { Field, ErrorMessage, FastField } from "formik";

import Select, { Styles, GroupTypeBase } from "react-select";

// @ts-ignore

import { getNested } from "utils/helpers";

export interface ISelectProps {
  mode?: "multiple" | "tags" ;
  optFixedWidth?: boolean;
  defaultValue?: string;
  label: string;
  size?: string;
  customOptsRender?: any;
  name: string;
  onChange?: (e: any) => void;
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
  disabled?: boolean;
  testId? : string;
  required? : boolean;
  type? : "select";
  value? : string;
  selectedValue? : string | boolean 
}

export default function SelectFormField(props: ISelectProps) {
  //   const { field, label, size } = props;

  const customStyles : Partial<Styles<any, false, GroupTypeBase<any>>> = {
    option: (provided :any , state :any) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
      fontSize: 12
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      fontSize : 12,
      width: 200,
    }),
    singleValue: (provided : any, state :any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      
  
      return { ...provided, opacity, transition };
    }
  }
  
  const {
    mode,
    optFixedWidth,
    defaultValue,
    onChange,
    label,
    size,
    customOptsRender,
    required,
    disabled,
    testId,
    value,
    selectedValue
  } = props;
  const name: string = props.name;
  const options: Array<{ label: string; value: string }> = props.options;
  return (
    <>
      
        <Field  name={props.name}>
          {({ field, form }: any) => {

                 
            const touched = getNested(form.touched, name);
            const error = getNested(form.errors, name)

            
            
            return (
                <div className="form-group">
                <label className="form-label">{label}</label>
              <Select
              isClearable={true}
                showSearch={true}
                data-testid={testId}
                aria-disabled={disabled || false}
                mode={mode}
                className="custom-react-select"
                classNamePrefix="react-select"
                // value={field.value}
                placeholder="Wybierz"
                // defaultValue={field.value || undefined }
                // name={props.name}
                disabled={disabled}
                
                onChange={(e) => {
                  
                  form.setFieldValue(name, e?.value);
                  if (onChange) {
                    onChange(e);
                  }
                }}

                onInputChange={(e) => {
                  if(e != ""){
                    form.setFieldValue(name, e);
                    if (onChange) {
                      onChange(e);
                    }
                  }
                
                }}
            
           
                id={name}
                options={options}
              />
        
              {(touched && error ) &&
                  <span data-testid="text-field-error" className="input-error">{error} </span>
                }
            </div>
              
            );
          }}
        </Field>
      
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </>
  );
}
