import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TextField from 'components/form-fields/text-field';
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from 'components/form-fields/form-group';
import FormButton from "components/buttons/form-button";
import { Formik, FormikProps } from "formik";
import { IAddress } from "types/customer-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { fetchCustomer, fetchCustomerById, updateCustomer } from "actions/customer";
import { IActionState, IReduxActionState } from "types/async-types";
import Spinner from "./spinner";

interface IProps {
  show: boolean;
  onHide: () => void;

}

export const AddressModal = (props: IProps) => {
  const { show, onHide } = props;

  const customer = useSelector((state: RootState) => state.customer);
 
  useEffect(() => {
    // execFetchTemplates();
  }, []);

  return (
    <Modal animation={false} centered show={props.show} onHide={() => props.onHide()}>
      <Modal.Header>
        <h3 className="mb-0">Dane Adresowe</h3>
        <CloseIcon className="close" onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body className={``}>

        <Formik initialValues={{ ...customer?.address }} onSubmit={() => { }}>
          {(formikProps: FormikProps<any>) => {
            return (
              <div>
                <FormGroup ><TextField placeholder="Brak danych" name="street" label="Ulica" type="text" /></FormGroup>
                <FormGroup ><TextField placeholder="Brak danych" name="building_number" label="Numer budynku" type="text" /></FormGroup>
                <FormGroup ><TextField placeholder="Brak danych" name="postal_code" label="Kod pocztowy" type="text" /></FormGroup>
                <FormGroup ><TextField name="post_office" label="Miejscowość" placeholder="Brak danych" type="text" /></FormGroup>
                <FormGroup ><TextField name="voivodeship" label="Województwo" type="text" placeholder="Brak danych" /></FormGroup>
                <FormGroup className="mb-10" ><TextField name="country" label="Kraj" type="text" placeholder="Brak danych" /></FormGroup>
                
                {/* <FormButton
                  width={200}
                  color="black-color"
                  title="Zapisz"
                  onClick={() => { }}
                /> */}
              </div>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};




export const ContactModal = (props: IProps) => {
  const { show, onHide } = props;

  const customer = useSelector((state: RootState) => state.customer);

  const [fetchCustomerState, execFetchCustomerById]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(fetchCustomerById, "attached-issues");
  
  const [updateState, execUpdateCustomer]: readonly [
    IActionState<any>,
    (...a: any) => any
  ] = useAsyncAction(updateCustomer);

  useEffect(() => {
    // execFetchTemplates();
    if(updateState.data){
      execFetchCustomerById(customer?.customer_uuid);
    }
  }, [updateState.data]);

  return (
    <Modal animation={false} centered show={props.show} onHide={() => props.onHide()}>
      <Modal.Header>
        <h3 className="mb-0">Dane kontaktowe</h3>
        <CloseIcon className="close" onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body className={``}>

        <Formik initialValues={{
          ...customer
        }} onSubmit={(values) => { execUpdateCustomer(customer?.customer_uuid, values.phone_number) }}>
          {(formikProps: FormikProps<any>) => {
            return (
              <>
              {updateState.loading && <Spinner />}
              <div>
                <FormGroup ><TextField placeholder="Brak danych" name="legal_form" label="NIP" type="text" /></FormGroup>
                <FormGroup ><TextField placeholder="Brak danych" name="company_name" label="Nazwa firmy" type="text" /></FormGroup>
                <FormGroup ><TextField placeholder="Brak danych" name="name" label="Imię" type="text" /></FormGroup>
                <FormGroup ><TextField placeholder="Brak danych" name="surname" label="Nazwisko" type="text" /></FormGroup>
                {/* <FormGroup ><TextField placeholder="Brak danych" name="surname" label="Nazwisko" type="text" /></FormGroup> */}
                <FormGroup ><TextField name="phone_number" label="Nr telefonu" type="text" placeholder="Brak danych" /></FormGroup>
                <FormGroup className="mb-20" ><TextField name="email" label="Email" type="email" placeholder="Brak danych" /></FormGroup>
                <FormButton
                  width={200}
                  color="black-color"
                  title="Zapisz"
                  className="m-auto"
                  onClick={() => { formikProps.submitForm() }}
                />
              </div>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};


