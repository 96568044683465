import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IStepComponent,
  StepActionType,
  IIssue,
  IIssueStep,
} from "types/issue-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useFormikContext, FormikProps, Formik } from "formik";
import TextField from "components/form-fields/text-field";
import CaseButton from "components/buttons/case-button";

import CallIcon from "@material-ui/icons/Call";
import style from "components/modal/incoming-call-modal.module.scss";
import Button from "components/buttons/form-button";
import {
  IActionState,
  IReduxActionState,
  ActionResponse,
} from "types/async-types";
import { createMonit, finishIssue, syncAttachedIssues } from "actions/issues";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { required } from "validators";
import { assignClient } from "actions/services";
import { fetchCustomer, fetchCustomerById } from "actions/customer";
import { IService } from "types/agent-types";
import { ICustomer } from "types/customer-types";
import { StepAction } from "components/cases/issue-details";

interface IFormValues {
  [componentName: string]: {
    ext_id: string;
  };
}
const MonitCreator = ({
  component,
  step,
  nextStep,
  stepAction,
  issue,
}: {
  component: IStepComponent;
  nextStep: () => void;
  step: IIssueStep;
  stepAction: StepAction;
  issue: IIssue;
}) => {
  const [dateTime, setDateTime] = useState<Date | undefined>();
  const [isCalling, setIsCalling] = useState(false);
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  const formikProps = useFormikContext<IFormValues>();
  const values = formikProps.values[component.name];

  const extIdField = `${component.name}.ext_id`;

  // const [monitState, execCreateMonit]: readonly [
  //   IReduxActionState<any>,
  //   (...a: any) => any
  // ] = useReduxAction(createMonit, 'create-monit');

  const [assignState, execAssign]: readonly [
    IActionState<IService>,
    typeof assignClient
  ] = useAsyncAction(assignClient);

  const [fetchCustomerState, execFetchCustomerById]: readonly [
    IReduxActionState<any>,
    (...body: any[]) => Promise<any>
  ] = useReduxAction(fetchCustomerById, "attached-issues");

  const [finishIssueState, execFinishIssue]: readonly [
    IActionState<any>,
    typeof finishIssue
  ] = useAsyncAction(finishIssue);

  const [syncIssueState, execSyncAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(syncAttachedIssues, "attached-issues");

  useEffect(() => {
    if (component.value) {
      formikProps.setFieldValue(extIdField, component.value["ext_id"]);
    }

    setTimeout(() => formikProps.validateForm());
  }, []);

  useEffect(() => {
    const func = async () => {
      if (assignState.data && service?.currentService) {
        const res: ActionResponse<ICustomer> = await execFetchCustomerById(
          assignState.data.customer_id
        ); // update customer in redux & assign to 'res' variable
        if (res.data.customer_uuid) {
          await stepAction("next_step", formikProps, {
            customer_id: res.data.customer_uuid,
          });
          //here we don't have updated customer redux state. We need to use res.data.customer_uuid
          await execFinishIssue(res.data.customer_uuid, issue.uuid);
          await execSyncAttachedIssues(res.data.customer_uuid, issue.uuid);
        }
      }
    };

    func();
  }, [assignState.data]);

  const onCustomerAssign = async () => {
    debugger;
    if (customer && service?.currentService && values.ext_id) {
      execAssign(service.currentService.id, values.ext_id);
    }
  };

  return (
    <div className="step-component mb-20">
      {/* <Formik
        initialValues={{ ext_id: "" }}
        isInitialValid={false}
        onSubmit={onCustomerAssign}
      >
        {(formikProps: FormikProps<IFormValues>) => { */}

      <div>
        <h3>{component.title}</h3>
        <TextField
          validate={required}
          disabled={step.status === "completed"}
          label=""
          name={extIdField}
        />
        {step.status !== "completed" && (
          <Button
            disabled={!formikProps.isValid}
            title="Zapisz"
            color="blue-color"
            width={200}
            onClick={onCustomerAssign}
            className="mb-30"
          />
        )}
      </div>

      {/* }}
      </Formik> */}
    </div>
  );
};

export default MonitCreator;
