import {
  AUTH_USER,
  FETCH_SESSION,
  UserActionTypes,
  SET_AGENTS_TIMER,
} from "types/redux-types";
import { ISessionData } from "types/session-types";

export interface IPasswordInfoPayload {}

export interface IUserState {
  token?: string | null;
  message?: string | null;
  debug_token_preview?: string | null;
  cart?: {
    translationIds: string[];
  };
  session?: ISessionData | null;
  fetch_agents_timer? : any;
  
}

const initialState: IUserState = {
  token: null,
  message: null,
  debug_token_preview: null,
  cart: {
    translationIds: [],
  },
  session: null,
};

export default function Reducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case AUTH_USER:
      return action.payload.user;
    case FETCH_SESSION:
      return { ...state, session: action.payload };

    case SET_AGENTS_TIMER:
      return { ...state, fetch_agents_timer: action.payload };
    default:
      return state;
  }
}
